import commonStyles from "../../common/css/Common.module.css";
import {MdBody, MdFooter, MdHeader, ModalBox} from "../common/ModalTags";
import challengeStyles from "../../common/css/Challenge.module.css";
import myPageChallengeStyles from "./MyPageChallenge.module.css";
import EnterItem from "../common/EnterItem";
import Enter from "../common/Enter";
import {useEffect, useState} from "react";
import {useDialog} from "../../common/hook/DialogContext";
import _ from "lodash";
import useKeepDoubleClick from "../../common/hook/useKeepDoubleClick";
import {useNavigate} from "react-router-dom";
import Url from "../../common/Url";
import { useTranslation } from "react-i18next";

const SubmitMd = ({ open, closedMd, challengeName, challengeUid, teamUid, refresh, setRefresh }) => {

    const [submitList, setSubmitList] = useState([]);
    const [files, setFiles] = useState([]);
    const [commitMsg, setCommitMsg] = useState("");
    const {addAlert, addConfirm} = useDialog();
    const [clicked, preventDoubleClick] = useKeepDoubleClick();
    const navigate = useNavigate();
    const {t} = useTranslation();

    const close = () => {
        setFiles([]);
        setCommitMsg("");
        setSubmitList([]);
        closedMd();
    }

    const fileRegister = (e) => {
        if (e.target.files.length === 0) {
            return;
        }

        let validFileType = ["csv"];
        let fileVal = e.target.files[0].name.toString();
        let fileLength = fileVal.length;
        let fileDot = fileVal.lastIndexOf(".");

        let fileType = fileVal.substring(fileDot + 1, fileLength).toLowerCase();
        if (e.target.files.length === 0) {
            return;
        }
        if (!validFileType.includes(fileType)) {
            alert(t("msg.valid.limitExt"));
            return;
        }

        setFiles([e.target.files[0]]);
    };

    const getSubmitList = () => {
        fetch(`/v1/challenge-team/${teamUid}/submit`)
            .then((response) => {
                if (response.ok) {
                    return response.json();
                } else if (response.status === 401) {
                    addAlert(
                        t("msg.error.login"),
                        () => navigate(Url.home)
                    );
                } else {
                    return response.json().then(json => {
                        addAlert(json.message);
                    })
                }
            })
            .then((json) => {
                if (json === undefined) return;
                setSubmitList(json);
            });
    }

    useEffect(() => {
        if (open) {
            getSubmitList();
        }
    },[open]);

    const reset = () => {
        setFiles([]);
        setCommitMsg("");
        getSubmitList();
    }

    const submit = () => {
        addConfirm(
            t("msg.info.requestAnswer"),
            () => {
                var formData = new FormData();
                formData.append("challengeUid", encodeURIComponent(challengeUid));
                formData.append("teamUid", encodeURIComponent(teamUid));
                formData.append("commitMsg", encodeURIComponent(commitMsg));
                formData.append("file", files[0]);

                fetch("/v1/challenge-team/submit", {
                    method: "post",
                    credentials: "include",
                    redirect: "follow",
                    body: formData,
                }).then((response) => {
                    clicked.current = false;
                    if (response.ok) {
                        addAlert(t("msg.info.finishAnswer"), reset);
                    } else if (response.status === 400) {
                        response.json().then(json => {
                            addAlert(json.message, reset);
                        })
                    } else if (response.status === 401) {
                        addAlert(
                            t("msg.error.login"),
                            () => navigate(Url.login)
                        );
                    } else {
                        throw new Error(t("msg.error.failAnswer"));
                    }
                    setRefresh(!refresh);
                }).catch(e => {
                    addAlert(e.message);
                });
            },
            () => {
                clicked.current = false;
            }
        )
    };

    const validation = () => {
        if (commitMsg.trim() === "") {
            addAlert(t("msg.valid.emptyCommit"));
            return;
        }

        if (_.isEmpty(files)) {
            addAlert(t("msg.valid.emptyAnswer"))
            return;
        }

        return true;
    };

    const downloadSubmitFile = (submitUid, filename) => {
        const downloadUrl = `/v1/challenge-team/${teamUid}/submit/${submitUid}/download/?filename=${filename}`;
        window.location.href = downloadUrl;
    }

    return (
        <ModalBox addClass={commonStyles.default_md} open={open} close={close}>
            <div>
                <div>
                    <MdHeader>{challengeName}</MdHeader>
                    <MdBody>
                        <div className={commonStyles.con}>
                            <h1>{t("challenge.menu.submission.sendAnswer")}</h1>
                            <div className={`${commonStyles.enter_wrap} ${challengeStyles.enter_wrap}`}>
                                <EnterItem addClass={`${challengeStyles.enter_item} ${commonStyles.file}`}>
                                    <Enter
                                        addClass={`${challengeStyles.enter} ${commonStyles.flex}`}
                                        id={"upload_file"}
                                        type={"text"}
                                        title={t("challenge.menu.submission.fileUpload")}
                                        placeholder={t("challenge.menu.submission.fileUpload")}
                                        readOnly={true}
                                        label={"답안지"}
                                        value={files[0]?.name || ""}
                                    >
                                        <input
                                            type="file"
                                            title={t("challenge.menu.submission.fileUpload")}
                                            placeholder={t("challenge.menu.submission.fileUpload")}
                                            multiple={false}
                                            onChange={fileRegister}
                                            accept=".csv"
                                        />
                                        <button
                                            type="button"
                                            className={`${commonStyles.cc_btn} ${challengeStyles.cc_btn} ${myPageChallengeStyles.cc_btn} ${commonStyles.bg_gray}`}
                                            title={t("challenge.menu.submission.findFile")}
                                        >
                                            <span>{t("challenge.menu.submission.findFile")}</span>
                                        </button>
                                    </Enter>
                                </EnterItem>
                                <EnterItem addClass={challengeStyles.enter_item}>
                                    <Enter
                                        addClass={challengeStyles.enter}
                                        id={"enter_msg"}
                                        label={"메시지 입력"}
                                        type={"text"}
                                        title={t("challenge.menu.submission.insertMsg")}
                                        placeholder={t("challenge.menu.submission.insertMsg")}
                                        value={commitMsg}
                                        inputChange={(e) => setCommitMsg(e.target.value)}
                                    />
                                </EnterItem>
                            </div>
                            <div className={`${commonStyles.bottom_btn_wrap} ${challengeStyles.bottom_btn_wrap}`}>
                                <button
                                    type="button"
                                    className={`${commonStyles.cc_btn} ${challengeStyles.cc_btn}`}
                                    style={{ width: "130px", fontSize: "16px", fontWeight: "400" }}
                                    onClick={
                                        () => preventDoubleClick(submit, validation)
                                    }
                                >
                                    {t("challenge.menu.submission.send")}
                                </button>
                            </div>
                        </div>
                        <div className={commonStyles.con}>
                            <h1>{t("challenge.menu.submission.sendLog")}</h1>
                            <div className={commonStyles.cc_table}>
                                <table>
                                    <caption><strong>제출 이력 테이블 입니다.</strong>파일명, 메시지, 점수, 제출일이 명시되어 있습니다.</caption>
                                    <colgroup>
                                        <col style={{ width: "170px" }}/>
                                        <col />
                                        <col style={{ width: "80px" }} />
                                        <col style={{ width: "110px" }} />
                                    </colgroup>
                                    <thead>
                                    <tr>
                                        <th scope="col" className={`${commonStyles.ta_left} ${challengeStyles.ta_left}`}>
                                            <div>{t("challenge.menu.submission.file")}</div>
                                        </th>
                                        <th scope="col" className={`${commonStyles.ta_left} ${challengeStyles.ta_left}`}>
                                            <div>{t("challenge.menu.submission.msg")}</div>
                                        </th>
                                        <th scope="col">
                                            <div>{t("challenge.menu.submission.score")}</div>
                                        </th>
                                        <th scope="col">
                                            <div>{t("challenge.menu.submission.sendDate")}</div>
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        _.isEmpty(submitList) ?
                                            <tr>
                                                <td colSpan="4" className={commonStyles.no_con}>
                                                    <div>{t("common.noboard")}</div>
                                                </td>
                                            </tr>
                                            :
                                            submitList.map(submit => (
                                                <tr>
                                                    <td className={`${commonStyles.ta_left} ${challengeStyles.ta_left}`} data-th="파일명">
                                                        <a
                                                            href="#"
                                                            className={commonStyles.underline_item}
                                                            onClick={() => downloadSubmitFile(submit.submitUid, submit.filename)}
                                                        >
                                                            <span className={commonStyles.line}>{submit.filename}</span>
                                                        </a>
                                                    </td>
                                                    <td className={`${commonStyles.ta_left} ${challengeStyles.ta_left}`} data-th="메시지">
                                                        {submit.commitMsg}
                                                    </td>
                                                    <td data-th="점 수">{submit.score}</td>
                                                    <td data-th="제출일">{submit.createdDate}</td>
                                                </tr>
                                            ))
                                    }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </MdBody>
                    <MdFooter></MdFooter>
                </div>
            </div>
        </ModalBox>
    );
};

export default SubmitMd;