import commonStyles from "../../common/css/Common.module.css";
import {forwardRef} from "react";


/**
 * 모달 팝업창 추가 시 사용
 * @param id 필요하다면 입력, 기본값 ""
 * @param children MdHeader, MdBody, MdFooter 입력
 * @param addClass 추가 클래스 있으면 입력
 * @param open 모달 팝업창 open 여부
 * @param close 모달 팝업창 close function
 * @param ref
 * @param withHeader
 */
export const ModalBox = forwardRef(
    (
        { id = "", children, addClass = "", open, close, withHeader = true },
        ref
    ) => {
        return (
            <div
                ref={ref}
                id={id}
                className={`${commonStyles.cc_modal_wrap} ${addClass}`}
                style={!open ? { display: "none" } : {}}
            >
                <div>
                    <div>{children}</div>
                    {withHeader && (
                        <button
                            type="button"
                            className={commonStyles.md_close_btn}
                            onClick={close}
                        >
                            <span className="sr_only">팝업 닫기</span>
                        </button>
                    )}
                </div>
            </div>
        );
    }
);

/**
 * 모달 팝업창 제목 영역 (닫기버튼 존재)
 * @param children 제목
 */
export const MdHeader = ({ children }) => {
    return (
        <div className={commonStyles.md_header}>
            <div className={`${commonStyles.md_title}`}>
                {children}
            </div>
        </div>
    )
}

/**
 * 모달 팝업창 컨텐츠 영역
 * @param children 모달에 들어갈 컨텐츠 컴포넌트 작성하여 삽입
 */
export const MdBody = forwardRef(({ children, addClass = "" }, ref) => {
    return (
        <div
            ref={ref}
            className={`${commonStyles.md_body} ${addClass}`}
        >
            <div>
                <div>{children}</div>
            </div>
        </div>
    );
});

export const MdFooter = ({ children }) => {
    return (
        <div className={commonStyles.md_footer}>
            {children}
        </div>
    );
};
