import challengeStyles from "../../../common/css/Challenge.module.css";

const TeamBox = ({children}) => {
    return (
        <div className={challengeStyles.team_box}>
            {children}
        </div>
    );
};

export default TeamBox;