import myPageBoardStyles from "./MyPageBoard.module.css";
import BbsWrap from "../challenge/BbsWrap";
import challengeStyles from "../../common/css/Challenge.module.css";
import {useEffect, useState} from "react";
import Paging from "../common/Paging";
import BoardList from "../challenge/board/BoardList";
import {useDialog} from "../../common/hook/DialogContext";
import Url from "../../common/Url";
import {useNavigate} from "react-router-dom";
import {useAuth} from "../../common/hook/AuthContext";
import {Loading} from "../../routes/basic/Unknown";
import { useTranslation } from "react-i18next";

const MyPageBoard = () => {
    const [myChallengeBoards, setMyChallengeBoards] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPage, setTotalPage] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const { isLogin, loginCheck, setIsLogin } = useAuth();
    const { addAlert, addConfirm } = useDialog();
    const navigate = useNavigate();
    const {t} = useTranslation();

    const getMyChallengeBoards = () => {
        setIsLoading(true);
        fetch(`/v1/challenge-boards/my-boards?page=${currentPage - 1}&size=10`)
            .then((response) => {
                if (response.ok) {
                    setIsLoading(false);
                    return response.json();
                } else if (response.status === 401) {
                    return response.json().then(json => {
                        setIsLogin(false)
                        addAlert(json.message, () => navigate(Url.login));
                    })
                } else {
                    return response.json().then(json => {
                        addAlert(json.message);
                    })
                }

            })
            .then((json) => {
                if (json === undefined) return;
                setMyChallengeBoards(json.data);
                setTotalPage(json.totalPages);
            });
    }

    useEffect(() => {
        if (isLogin === false) {
            setIsLoading(true);
            addAlert(
                t("msg.error.login"),
                () => navigate(Url.login)
            );
        } else {
            getMyChallengeBoards();
        }
    },[currentPage]);


    return (
        <>
            {!isLoading ?
                <>
                    <BbsWrap addClass={myPageBoardStyles.bbs_wrap}>
                        <div className={`${challengeStyles.top} ${challengeStyles.flex}`}>
                            <div className={challengeStyles.table_title}>{t("mypage.board.board")}</div>
                        </div>
                        <BoardList boardList={myChallengeBoards} prevPage={currentPage} isMyPage={true} />
                    </BbsWrap>
                    <Paging
                        blockSize={10}
                        totalPageCount={totalPage}
                        currentPageCount={currentPage}
                        onPageClick={setCurrentPage}
                    />
                </>
                :
                <Loading />
            }
        </>
    );
};

export default MyPageBoard;