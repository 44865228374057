import {createContext, useContext, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useDialog} from "./DialogContext";
import { useTranslation } from "react-i18next";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [isLogin, setIsLogin] = useState(null);
    const [userType, setUserType] = useState("");
    const [userUid, setUserUid] = useState("");
    const [userId, setUserId] = useState("");
    const [userName, setUserName] = useState("");
    const {addAlert, addConfirm} = useDialog();
    const navigate = useNavigate();
    const {t} = useTranslation();

    // 로그인 함수
    const login = (validate, id, pw, selectRef) => {
        if(!validate()) {
            return;
        }

        fetch("/v1/loginproc", {
            method: "post",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
            credentials: "include",
            redirect: "follow",
            body: new URLSearchParams({
                username: id,
                password: pw,
                usertype: "NORMAL"
            }),
        })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                if (data.status === 400) {
                    addAlert(data.message);
                } else if (data.status === 200) {
                    if (selectRef.current.checked) {
                        localStorage.setItem("rememberedId", id);
                    } else {
                        if (localStorage.getItem("rememberedId") !== null) {
                            localStorage.removeItem("rememberedId");
                        }
                    }
                    navigate(data.redirect, { replace: true });
                    setIsLogin(true);
                } else {
                    addAlert(t("msg.error.failLogin"));
                }
            });
    };

    // 로그아웃 함수
    const logout = (openLogoutAlert = () => {}) => {
        fetch("/v1/logout", {
            method: "post",
            credentials: "include",
        }).then((response) => {
            if (response.status === 200) {
                setIsLogin(false);
                setUserType("");
                setUserUid("");
                setUserId("");
                setUserName("");
                openLogoutAlert();
            }
        });
    };

    const loginCheck = () => {
        fetch("/v1/authenticated-user", {
            method: "get",
            credentials: "include",
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error("세션이 만료되었습니다.");
                }
            })
            .then((data) => {
                if (data.status === "200") {
                    setIsLogin(true);
                    setUserType(data.userType);
                    setUserUid(data.uid);
                    setUserId(data.userId);
                    setUserName(data.userName);
                } else if (data.status === "401") {
                    setIsLogin(false);
                    setUserType("");
                    setUserUid("");
                    setUserId("");
                    setUserName("");
                }
            })
            .catch((e) => {
                console.log(e.message);
            });
    };

    return (
        <AuthContext.Provider value={{ isLogin, setIsLogin, userType, userUid, userId, userName, login, logout, loginCheck }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    return useContext(AuthContext);
};