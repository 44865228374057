import Container from "../../component/common/Container";
import Content from "../../component/common/Content";
import SrOnly from "../../component/common/SrOnly";
import ChallengeWrap from "../../component/challenge/ChallengeWrap";
import ChallengeTop from "../../component/challenge/ChallengeTop";
import ChallengeBottom from "../../component/challenge/ChallengeBottom";
import {useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import _ from "lodash";
import {Loading} from "../basic/Unknown";
import {useDialog} from "../../common/hook/DialogContext";
import {useAuth} from "../../common/hook/AuthContext";
import Url from "../../common/Url";
import { useTranslation } from "react-i18next";

const Challenge = ({tabMenu, innerMenu}) => {
    const param = useParams();
    const [uid, setUId] = useState(param.challengeUid);
    const [challenge, setChallenge] = useState({});
    const [challengeRefresh, setChallengeRefresh] = useState(false);
    const [teamUid, setTeamUid] = useState(0);
    const {isLogin} = useAuth();
    const navigate = useNavigate();
    const {addAlert, addConfirm} = useDialog();
    const {t} = useTranslation();

    const getChallengeInfo = () => {
        fetch(`/v1/challenge/${uid}`)
            .then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    return response.json().then(json => {
                        addAlert(json.message);
                    })
                }
            })
            .then((json) => {
                setChallenge(json);
            });
    }

    useEffect(() => {
        getChallengeInfo();
    }, [challengeRefresh])

    const getTeamUid = () => {
        fetch(`/v1/challenge-team/${uid}/team`)
            .then((response) => {
                if (response.ok) {
                    return response.json();
                } else if (response.status === 400) {
                    setTeamUid(0);
                } else if (response.status === 401) {
                    addAlert(
                        t("msg.error.login"),
                        () => navigate(Url.login)
                    );
                } else {
                    return response.json().then(json => {
                        addAlert(json.message);
                    })
                }
            })
            .then((json) => {
                if (json === undefined) return;
                setTeamUid(json.teamUid);
            });
    }

    useEffect(() => {
        if (isLogin) {
            getTeamUid();
        }
    },[isLogin, challengeRefresh]);

    return (
        !_.isEmpty(challenge) ?
            <Container>
                <Content>

                    <SrOnly tag={"h2"}>대회 안내 - 개요 페이지</SrOnly>

                    <ChallengeWrap>
                        <ChallengeTop
                            tabMenu={tabMenu}
                            uid={uid}
                            challenge={challenge}
                            teamUid={teamUid}
                        />
                        <ChallengeBottom
                            tabMenu={tabMenu}
                            innerMenu={innerMenu}
                            uid={uid}
                            challenge={challenge}
                            teamUid={teamUid}
                            challengeRefresh={challengeRefresh}
                            setChallengeRefresh={setChallengeRefresh}
                        />
                    </ChallengeWrap>
                </Content>
            </Container>
            :
            <Loading />
    );
};

export default Challenge;