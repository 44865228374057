import React, {useRef} from "react";
import Header from "../../routes/basic/Header";
import Footer from "../../routes/basic/Footer";
import ScrollTop from "../../routes/basic/ScrollTop";
import ScrollBottom from "../../routes/basic/ScrollBottom";
import CustomConfirm from "./CustomConfirm";
import CustomAlert from "./CustomAlert";

const Wrapper = ({children}) => {
    const footerRef = useRef(null);

    return (
        <>
            <a href="#container" className="skipnavigation"><span>본문 영역으로 바로가기</span></a>
            <a href="#footer" className="skipnavigation"><span>하단 메뉴로 바로가기</span></a>

            <div id="wrapper">
                <Header />
                {children}
                <Footer footerRef={footerRef} />
                <ScrollBottom footerRef={footerRef} />
                <ScrollTop />
                <CustomConfirm />
                <CustomAlert />
            </div>
        </>
    );
};

export default Wrapper;