import $ from "jquery";
export const AMKeycode=(function(){
	// "use strict";

	// 참고: http://aquamir.tistory.com/m/709
	// keyCode: 대소문자를 구분하지 않고, keydown에서 주로 사용된다.
	// charCode: 대소문자를 구분한다.
	// 브라우저마다 keyCode가 다를 수 있고, 이 부분은 아래 소스에 반영되어 있다.

	// 자바스크립트 키코드 함수

	// 키코드그룹 (비트 연산 | 사용)
	const KEYGROUP_CTRL           = 0x00000001; // 제어키
	const KEYGROUP_EDIT           = 0x00000002; // 편집키
	const KEYGROUP_WINDOWS        = 0x00000004; // 윈도우키
	const KEYGROUP_SYSTEM         = 0x00000008; // 시스템키
	const KEYGROUP_NAVIGATION     = 0x00000010; // 방향키
	const KEYGROUP_NUMERIC        = 0x00000020; // 숫자키
	const KEYGROUP_ALPHA          = 0x00000040; // 영문자키
	const KEYGROUP_NUMPAD_NUMERIC = 0x00000080; // 숫자패드 숫자키
	const KEYGROUP_NUMPAD_CHARS   = 0x00000100; // 숫자패드 문자키
	const KEYGROUP_FUNCTION       = 0x00000200; // 기능 키
	const KEYGROUP_CHARS_ETC      = 0x00000400; // 일반 문자키

	// 제어키 KEYGROUP_CTRL
	const KEYCODE_SHIFT			= 16;
	const KEYCODE_CTRL			= 17;
	const KEYCODE_ALT			= 18;

	// 편집키 KEYGROUP_EDIT
	const KEYCODE_ESC			= 27;
	const KEYCODE_BACKSPACE		= 8;
	const KEYCODE_TAB			= 9;
	const KEYCODE_ENTER			= 13;
	const KEYCODE_INSERT		= 45;
	const KEYCODE_DELETE		= 46;

	// 윈도우키 KEYGROUP_WINDOWS
	const KEYCODE_WINDOWS_LEFT	= 91;
	const KEYCODE_WINDOWS_RIGHT	= 92;

	// 시스템키 KEYGROUP_SYSTEM
	const KEYCODE_PAUSEBREAK	= 19;
	const KEYCODE_CAPSLOOK		= 20;
	const KEYCODE_NUMLOCK		= 144;
	const KEYCODE_SCROLLLOCK	= 145;

	const KEYCODE_HAN_ENG_1		= 21;
	const KEYCODE_HAN_ENG_2		= 229;
	const KEYCODE_HANJA			= 25;
	const KEYCODE_FUNCTION		= 93; // MENU

	// 방향키 KEYGROUP_NAVIGATION
	const KEYCODE_PAGEUP		= 33;
	const KEYCODE_PAGEDN		= 34;
	const KEYCODE_END			= 35;
	const KEYCODE_HOME			= 36;
	const KEYCODE_ARROW_LEFT	= 37;
	const KEYCODE_ARROW_UP		= 38;
	const KEYCODE_ARROW_RIGHT	= 39;
	const KEYCODE_ARROW_DOWN	= 40;

	// 숫자키 KEYGROUP_NUMERIC
	const KEYCODE_0 = 48;
	const KEYCODE_1 = 49;
	const KEYCODE_2 = 50;
	const KEYCODE_3 = 51;
	const KEYCODE_4 = 52;
	const KEYCODE_5 = 53;
	const KEYCODE_6 = 54;
	const KEYCODE_7 = 55;
	const KEYCODE_8 = 56;
	const KEYCODE_9 = 57;

	// 영문자키 KEYGROUP_ALPHA
	const KEYCODE_A = 65;
	const KEYCODE_B = 66;
	const KEYCODE_C = 67;
	const KEYCODE_D = 68;
	const KEYCODE_E = 69;
	const KEYCODE_F = 70;
	const KEYCODE_G = 71;
	const KEYCODE_H = 72;
	const KEYCODE_I = 73;
	const KEYCODE_J = 74;
	const KEYCODE_K = 75;
	const KEYCODE_L = 76;
	const KEYCODE_M = 77;
	const KEYCODE_N = 78;
	const KEYCODE_O = 79;
	const KEYCODE_P = 80;
	const KEYCODE_Q = 81;
	const KEYCODE_R = 82;
	const KEYCODE_S = 83;
	const KEYCODE_T = 84;
	const KEYCODE_U = 85;
	const KEYCODE_V = 86;
	const KEYCODE_W = 87;
	const KEYCODE_X = 88;
	const KEYCODE_Y = 89;
	const KEYCODE_Z = 90;

	// 숫자패드 숫자키 KEYGROUP_NUMPAD_NUMERIC
	const KEYCODE_NUMPAD_0 = 96;
	const KEYCODE_NUMPAD_1 = 97;
	const KEYCODE_NUMPAD_2 = 98;
	const KEYCODE_NUMPAD_3 = 99;
	const KEYCODE_NUMPAD_4 = 100;
	const KEYCODE_NUMPAD_5 = 101;
	const KEYCODE_NUMPAD_6 = 102;
	const KEYCODE_NUMPAD_7 = 103;
	const KEYCODE_NUMPAD_8 = 104;
	const KEYCODE_NUMPAD_9 = 105;

	// 숫자패드 문자키 KEYGROUP_NUMPAD_CHARS
	const KEYCODE_NUMPAD_DOT		= 110;	// .
	const KEYCODE_NUMPAD_SLASH		= 111;	// /
	const KEYCODE_NUMPAD_SLASH_2	=  47;	// /
	const KEYCODE_NUMPAD_ASTERISK	= 106;	// *
	const KEYCODE_NUMPAD_ASTERISK_2	=  42;	// *
	const KEYCODE_NUMPAD_PLUS		= 107;	// +
	const KEYCODE_NUMPAD_PLUS_2		=  43;	// +
	const KEYCODE_NUMPAD_MINUS		= 109;	// -
	const KEYCODE_NUMPAD_MINUS_2	=  45;	// -

	// 기능 키 KEYGROUP_FUNCTION
	const KEYCODE_F1 = 112;
	const KEYCODE_F2 = 113;
	const KEYCODE_F3 = 114;
	const KEYCODE_F4 = 115;
	const KEYCODE_F5 = 116;
	const KEYCODE_F6 = 117;
	const KEYCODE_F7 = 118;
	const KEYCODE_F8 = 119;
	const KEYCODE_F9 = 120;
	const KEYCODE_F10 = 121;
	const KEYCODE_F11 = 122;
	const KEYCODE_F12 = 123;

	// 일반 문자키 KEYGROUP_CHARS_ETC
	const KEYCODE_SPACEBAR			= 32;	// 
	const KEYCODE_MINUS				= 189;	// -
	const KEYCODE_MINUS_MOZILLA		= 173;	// -
	const KEYCODE_EQUAL				= 187;	// =
	const KEYCODE_EQUAL_MOZILLA		=  61;	// =
	const KEYCODE_COMMA				= 188;	// ,
	const KEYCODE_DOT				= 190;	// .
	const KEYCODE_BACKQOUTE			= 192;	// `
	const KEYCODE_SINGLEQOUTE		= 222;	// '
	const KEYCODE_SLASH				= 191;	// /
	const KEYCODE_BACKSLASH			= 220;	// \
	const KEYCODE_SEMICOLON			= 186;	// ;
	const KEYCODE_SEMICOLON_MOZILLA	=  59;	// ;
	const KEYCODE_BRACKET_OPEN		= 219;	// [
	const KEYCODE_BRACKET_CLOSE		= 221;	// ]

	// 한/영: 테스트는 229, 인터넷 문서에는 21로 나와 있다.
	const KEYCODE_HANGUL_1			=  21;
	const KEYCODE_HANGUL_2			= 229;

	///////////////////////////////////////////////////////////////////////////////////////////

	// 키보드 포함여부 검사
	// keycode가 allowable_keygroups에 들어 있는가?
	// 주의: keycode는 숫자값이고
	//       allowable_keycode_str는 문자열이다.
	//
	function is_keycode_in(keycode, allowable_keygroups)
	{
		// keycode가 allowable_keygroups에 속하는가?
		return(
			((allowable_keygroups & KEYGROUP_CTRL)           && is_keycode_in_keygroup_x(keycode, KEYGROUP_CTRL))			||
			((allowable_keygroups & KEYGROUP_EDIT)           && is_keycode_in_keygroup_x(keycode, KEYGROUP_EDIT))			||
			((allowable_keygroups & KEYGROUP_WINDOWS)        && is_keycode_in_keygroup_x(keycode, KEYGROUP_WINDOWS))		||
			((allowable_keygroups & KEYGROUP_SYSTEM)         && is_keycode_in_keygroup_x(keycode, KEYGROUP_SYSTEM))			||
			((allowable_keygroups & KEYGROUP_NAVIGATION)     && is_keycode_in_keygroup_x(keycode, KEYGROUP_NAVIGATION))		||
			((allowable_keygroups & KEYGROUP_NUMERIC)        && is_keycode_in_keygroup_x(keycode, KEYGROUP_NUMERIC))		||
			((allowable_keygroups & KEYGROUP_ALPHA)          && is_keycode_in_keygroup_x(keycode, KEYGROUP_ALPHA))			||
			((allowable_keygroups & KEYGROUP_NUMPAD_NUMERIC) && is_keycode_in_keygroup_x(keycode, KEYGROUP_NUMPAD_NUMERIC))	||
			((allowable_keygroups & KEYGROUP_NUMPAD_CHARS)   && is_keycode_in_keygroup_x(keycode, KEYGROUP_NUMPAD_CHARS))	||
			((allowable_keygroups & KEYGROUP_FUNCTION)       && is_keycode_in_keygroup_x(keycode, KEYGROUP_FUNCTION))		||
			((allowable_keygroups & KEYGROUP_CHARS_ETC)      && is_keycode_in_keygroup_x(keycode, KEYGROUP_CHARS_ETC))
		);
	}

	// keygroup 값은 정의된 상수값과 정확히 일치해야 한다.
	function is_keycode_in_keygroup_x(keycode, keygroup)
	{
		let arr_keycode = null;
		if( keygroup===KEYGROUP_CTRL )
			arr_keycode = [KEYCODE_SHIFT, KEYCODE_CTRL, KEYCODE_ALT];
		else if( keygroup===KEYGROUP_EDIT )
			arr_keycode = [KEYCODE_ESC, KEYCODE_BACKSPACE, KEYCODE_TAB, KEYCODE_ENTER, KEYCODE_INSERT, KEYCODE_DELETE];
		else if( keygroup===KEYGROUP_WINDOWS )
			arr_keycode = [KEYCODE_WINDOWS_LEFT, KEYCODE_WINDOWS_RIGHT];
		else if( keygroup===KEYGROUP_SYSTEM )
			arr_keycode = [KEYCODE_PAUSEBREAK, KEYCODE_CAPSLOOK, KEYCODE_NUMLOCK, KEYCODE_SCROLLLOCK, KEYCODE_HAN_ENG_1, KEYCODE_HAN_ENG_2, KEYCODE_HANJA, KEYCODE_FUNCTION];
		else if( keygroup===KEYGROUP_NAVIGATION )
			arr_keycode = [KEYCODE_PAGEUP, KEYCODE_PAGEDN, KEYCODE_END, KEYCODE_HOME, KEYCODE_ARROW_LEFT, KEYCODE_ARROW_UP, KEYCODE_ARROW_RIGHT, KEYCODE_ARROW_DOWN];
		else if( keygroup===KEYGROUP_NUMERIC )
			arr_keycode = [KEYCODE_0, KEYCODE_1, KEYCODE_2, KEYCODE_3, KEYCODE_4, KEYCODE_5, KEYCODE_6, KEYCODE_7, KEYCODE_8, KEYCODE_9];
		else if( keygroup===KEYGROUP_ALPHA )
			arr_keycode = [KEYCODE_A, KEYCODE_B, KEYCODE_C, KEYCODE_D, KEYCODE_E, KEYCODE_F, KEYCODE_G, KEYCODE_H, KEYCODE_I, KEYCODE_J, KEYCODE_K, KEYCODE_L, KEYCODE_M, KEYCODE_N, KEYCODE_O, KEYCODE_P, KEYCODE_Q, KEYCODE_R, KEYCODE_S, KEYCODE_T, KEYCODE_U, KEYCODE_V, KEYCODE_W, KEYCODE_X, KEYCODE_Y, KEYCODE_Z];
		else if( keygroup===KEYGROUP_NUMPAD_NUMERIC )
			arr_keycode = [KEYCODE_NUMPAD_0, KEYCODE_NUMPAD_1, KEYCODE_NUMPAD_2, KEYCODE_NUMPAD_3, KEYCODE_NUMPAD_4, KEYCODE_NUMPAD_5, KEYCODE_NUMPAD_6, KEYCODE_NUMPAD_7, KEYCODE_NUMPAD_8, KEYCODE_NUMPAD_9];
		else if( keygroup===KEYGROUP_NUMPAD_CHARS )
			arr_keycode = [KEYCODE_NUMPAD_DOT, KEYCODE_NUMPAD_SLASH, KEYCODE_NUMPAD_SLASH_2, KEYCODE_NUMPAD_ASTERISK, KEYCODE_NUMPAD_ASTERISK_2, KEYCODE_NUMPAD_PLUS, KEYCODE_NUMPAD_PLUS_2, KEYCODE_NUMPAD_MINUS, KEYCODE_NUMPAD_MINUS_2];
		else if( keygroup===KEYGROUP_FUNCTION )
			arr_keycode = [KEYCODE_F1, KEYCODE_F2, KEYCODE_F3, KEYCODE_F4, KEYCODE_F5, KEYCODE_F6, KEYCODE_F7, KEYCODE_F8, KEYCODE_F9, KEYCODE_F10, KEYCODE_F11, KEYCODE_F12];
		else if( keygroup===KEYGROUP_CHARS_ETC )
			arr_keycode = [KEYCODE_SPACEBAR, KEYCODE_MINUS, KEYCODE_MINUS_MOZILLA, KEYCODE_EQUAL, KEYCODE_EQUAL_MOZILLA, KEYCODE_COMMA, KEYCODE_DOT, KEYCODE_BACKQOUTE, KEYCODE_SINGLEQOUTE, KEYCODE_SLASH, KEYCODE_BACKSLASH, KEYCODE_SEMICOLON, KEYCODE_SEMICOLON_MOZILLA, KEYCODE_BRACKET_OPEN, KEYCODE_BRACKET_CLOSE];
		else
			return false;

		return is_keycode_in_keyarray_x(keycode, arr_keycode);
	}

	// keycode가 배열 내에 있는지 검사한다.
	function is_keycode_in_keyarray_x(keycode, arr_keycode)
	{
		for( let i=0 ; i < arr_keycode.length ; i++)
		{
			if( arr_keycode[i]===keycode )
				return true;
		}
		return false;
	}

	// target_str가 format_string문자들로 구성되어 있는지 검사한다.
	// target_str:
	// function does_target_string_consist_of_format_string_x(target_str,format_string)
	// {
	// 	if( target_str==='' )
	// 		return true;
	//
	// 	if( format_string==='' )
	// 		return false;
	//
	// 	for(let i=0; i< target_str.length;i++)
	// 	{
	// 		let output = format_string.indexOf(target_str.charAt(i),0)  //해당문자열에 특수문자열이 있는지 확인
	// 		if (output < 0) //특수문자가 없다면 -1을 반환 -1이외의값에 alert 수행후 함수 종료
	// 			return false;
	// 	}
	//
	// 	return true;
	// }

	///////////////////////////////////////////////////////////////////////////////////////////

	// To Do: 기능 구현 필요
	// http://cpdev.tistory.com/42
	// 붙여넣기 점검
	//if( event.ctrlKey && keycode===KEYCODE_V )
	//{
	//	let clipboardData = event.clipboardData || window.clipboardData;
	//	let clipboard_value = clipboardData.getData('Text');
	//	if( clipboard_value!='' && !does_target_string_consist_of_format_string_x(clipboard_value,"0123456789") )
	//		return false;
	//}

	// SHIFT 키 방지 (다른 키가 입력될 수 있기 때문에 이것을 방지한다.)
	//if( event.shiftKey )
	//	return false;

	//keycode===KEYCODE_NUMPAD_MINUS ||
	//(keycode===KEYCODE_MINUS && !event.shiftKey) )

	// 항상 허용 문자들
	function always_allowed_x(event)
	{
		let keycode = event.which || event.keyCode;

		return (
			typeof keycode==='undefined'				||
			is_keycode_in(keycode, KEYGROUP_CTRL | KEYGROUP_EDIT | KEYGROUP_WINDOWS | KEYGROUP_SYSTEM | KEYGROUP_NAVIGATION | KEYGROUP_FUNCTION) ||
			(keycode===KEYCODE_C && event.ctrlKey)	||
			(keycode===KEYCODE_V && event.ctrlKey)	||
			(keycode===KEYCODE_X && event.ctrlKey)	||
			(keycode===KEYCODE_Z && event.ctrlKey)
		);
	}

	// 허용문자: a-zA-Z
	// https://stackoverflow.com/questions/15807715/in-javascript-how-to-detect-the-character-case-upper-lower-in-keydown-keyup
	// keydown 이벤트에서는 대소문자를 구분할 수 없고 keypress 이벤트에서만 구분할 수 있다.
	function alphabet_x(event)
	{
		let keycode = event.which || event.keyCode;

		return (
			always_allowed_x(event)			||
		/**/is_keycode_in(keycode, KEYGROUP_ALPHA)
		);
	}

	// 허용문자: 0123456789
	function numeric_x(event)
	{
		let keycode = event.which || event.keyCode;

		return (
			always_allowed_x(event)								||
		/**/is_keycode_in(keycode, KEYGROUP_NUMPAD_NUMERIC)		||
		/**/(is_keycode_in(keycode, KEYGROUP_NUMERIC) && !event.shiftKey)
		);
	}

	// 허용문자: .
	function dot_x(event)
	{
		let keycode = event.which || event.keyCode;

		return (
			always_allowed_x(event)			||
		/**/keycode===KEYCODE_NUMPAD_DOT		||
		/**/(keycode===KEYCODE_DOT && !event.shiftKey)
		);
	}

	// 허용문자: +
	function plus_x(event)
	{
		let keycode = event.which || event.keyCode;

		return (
			always_allowed_x(event)						||
		/**/keycode===KEYCODE_NUMPAD_PLUS				||
		/**/keycode===KEYCODE_NUMPAD_PLUS_2				||
		/**/(keycode===KEYCODE_EQUAL && event.shiftKey)	||
		/**/(keycode===KEYCODE_EQUAL_MOZILLA && event.shiftKey)
		);
	}

	// 허용문자: -
	function minus_x(event)
	{
		let keycode = event.which || event.keyCode;

		return (
			always_allowed_x(event)						||
		/**/keycode===KEYCODE_NUMPAD_MINUS				||
		/**/keycode===KEYCODE_NUMPAD_MINUS_2				||
		/**/(keycode===KEYCODE_MINUS && !event.shiftKey)	||
		/**/(keycode===KEYCODE_MINUS_MOZILLA && !event.shiftKey)
		);
	}

	// 허용문자: -
	function dash_x(event)
	{
		return minus_x(event);
	}

	// 허용문자: _
	function underscore_x(event)
	{
		let keycode = event.which || event.keyCode;

		return (
			always_allowed_x(event)						||
		/**/(keycode===KEYCODE_MINUS && event.shiftKey)	||
		/**/(keycode===KEYCODE_MINUS_MOZILLA && event.shiftKey)
		);
	}

	// 허용문자: ~
	function tilt_x(event)
	{
		let keycode = event.which || event.keyCode;

		return (
			always_allowed_x(event)			||
		/**/(keycode===KEYCODE_BACKQOUTE && event.shiftKey)
		);
	}

	// 허용문자: @
	function at_x(event)
	{
		let keycode = event.which || event.keyCode;

		return (
			always_allowed_x(event)			||
		/**/(keycode===KEYCODE_2 && event.shiftKey)
		);
	}

	// 허용문자: #
	function sharp_x(event)
	{
		let keycode = event.which || event.keyCode;

		return (
			always_allowed_x(event)			||
		/**/(keycode===KEYCODE_3 && event.shiftKey)
		);
	}

	// 허용문자: ,
	function comma_x(event)
	{
		let keycode = event.which || event.keyCode;

		return (
			always_allowed_x(event)			||
		/**/(keycode===KEYCODE_COMMA && !event.shiftKey)
		);
	}

	// 허용문자: :
	function colon_x(event)
	{
		let keycode = event.which || event.keyCode;

		return (
			always_allowed_x(event)									||
		/**/(keycode===KEYCODE_SEMICOLON && event.shiftKey)			||
		/**/(keycode===KEYCODE_SEMICOLON_MOZILLA && event.shiftKey)
		);
	}

	// 허용문자: 공백 문자
	function space_x(event)
	{
		let keycode = event.which || event.keyCode;

		return (
			always_allowed_x(event)		||
		/**/keycode===KEYCODE_SPACEBAR
		);
	}

	// 허용문자: *
	function asterisk_x(event)
	{
		let keycode = event.which || event.keyCode;

		return (
			always_allowed_x(event)				||
		/**/keycode===KEYCODE_NUMPAD_ASTERISK	||
		/**/keycode===KEYCODE_NUMPAD_ASTERISK_2	||
		/**/(keycode===KEYCODE_8 && event.shiftKey)
		);
	}

	// 허용문자: /
	function slash_x(event)
	{
		let keycode = event.which || event.keyCode;

		return (
			always_allowed_x(event)			||
		/**/keycode===KEYCODE_NUMPAD_SLASH	||
		/**/keycode===KEYCODE_NUMPAD_SLASH_2	||
		/**/(keycode===KEYCODE_SLASH)
		);
	}

	///////////////////////////////////////////////////////////////////////////////////////////

	// 허용문자: %
	function percentage_x(event)
	{
		let keycode = event.which || event.keyCode;

		return (
			always_allowed_x(event)			||
		/**/(keycode===KEYCODE_5 && event.shiftKey)
		);
	}

	// 허용문자: ?
	function question_x(event)
	{
		let keycode = event.which || event.keyCode;

		return (
			always_allowed_x(event)			||
		/**/(keycode===KEYCODE_SLASH && event.shiftKey)
		);
	}

	// 허용 문자: 한글
	// 예) 한글
	// 한글을 감지할 수 없다.
	function hangul_x(event)
	{
		let keycode = event.which || event.keyCode;

		return (
			always_allowed_x(event)			||
			keycode===KEYCODE_HANGUL_1		||
			keycode===KEYCODE_HANGUL_2
		);
	}

	///////////////////////////////////////////////////////////////////////////////////////////

	// 허용 문자: 0123456789
	// 예) 3
	function numeric(event)
	{
		return numeric_x(event);
	}

	// 허용 문자: 0123456789
	// 예) 3
	function integer_zero_positive(event)
	{
		return numeric_x(event);
	}

	// 허용 문자: 0123456789
	// 예) 3
	function integer_positive(event)
	{
		return numeric_x(event);
	}

	// 허용 문자: -0123456789
	// 예) -3
	function integer(event)
	{
		return (minus_x(event) || numeric_x(event));
	}

	///////////////////////////////////////////////////////////////////////////////////////////

	// 허용 문자: .0123456789
	// 예) 3.4
	function float_zero_positive(event)
	{
		return (dot_x(event) || numeric_x(event));
	}

	// 허용 문자: .0123456789
	// 예) 3.4
	function float_positive(event)
	{
		return (dot_x(event) || numeric_x(event));
	}

	// 허용 문자: +-.0123456789
	// 예) -3.4
	function _float(event)
	{
		return (plus_x(event) || minus_x(event) || dot_x(event) || numeric_x(event));
	}

	///////////////////////////////////////////////////////////////////////////////////////////

	// 허용 문자: -0123456789
	// 예) 2017-11-12
	function date(event)
	{
		return (minus_x(event) || numeric_x(event));
	}

	// 허용 문자: :0123456789
	// 예) 12:23:34
	function time(event)
	{
		return (colon_x(event) || numeric_x(event));
	}

	// 허용 문자: 공백-:0123456789
	// 예) 2017-11-12 12:23:34
	function datetime(event)
	{
		return (space_x(event) || minus_x(event) || colon_x(event) || numeric_x(event));
	}

	// 허용 문자: -0123456789
	// 예) 2017-11
	function yearmonth(event)
	{
		return (minus_x(event) || numeric_x(event));
	}

	///////////////////////////////////////////////////////////////////////////////////////////

	// 불허 문자: \ / : * ? " < > |
	// 예) my_filename.html
	function filename(event)
	{
		let keycode = event.which || event.keyCode;

		return !(
			keycode===KEYCODE_BACKSLASH								||
			keycode===KEYCODE_SLASH									||
			keycode===KEYCODE_NUMPAD_SLASH							||
			keycode===KEYCODE_NUMPAD_SLASH_2							||
			(keycode===KEYCODE_SEMICOLON && event.shiftKey)			||
			(keycode===KEYCODE_SEMICOLON_MOZILLA && event.shiftKey)	||
			(keycode===KEYCODE_8 && event.shiftKey)					||
			keycode===KEYCODE_NUMPAD_ASTERISK						||
			keycode===KEYCODE_NUMPAD_ASTERISK_2						||
			(keycode===KEYCODE_SLASH && event.shiftKey)				||
			(keycode===KEYCODE_SINGLEQOUTE && event.shiftKey)		||
			(keycode===KEYCODE_COMMA && event.shiftKey)				||
			(keycode===KEYCODE_DOT && event.shiftKey)				||
			(keycode===KEYCODE_BACKSLASH && event.shiftKey)
		);
	}

	// 허용 문자: /a-zA-Z_-0-9.
	function strict_file_path(event)
	{
		return (slash_x(event) || alphabet_x(event) || numeric_x(event) || underscore_x(event) || dash_x(event) || dot_x(event));
	}

	// 허용 문자: 0-9a-zA-Z~_-/.:
	function simple_url(event)
	{
		return (alphabet_x(event) || numeric_x(event) || tilt_x(event) || underscore_x(event) || dash_x(event) || slash_x(event) || dot_x(event) || colon_x(event));
	}

	// 허용 문자: 0-9a-zA-Z\~@#%^&\(\)_\-+=\|\?\/.:
	function standard_url(event)
	{
		return true;
	}

	// 허용 문자: 0-9a-zA-Z\~@#%^&\(\)_\-+=\|\?\/.:
	function general_url(event)
	{
		return true;
	}

	// 허용 문자: #a-f0-9
	function hex_color(event)
	{
		return (sharp_x(event) || alphabet_x(event) || numeric_x(event));
	}

	// 허용 문자: #a-f0-9
	function color_picker(event)
	{
		return (sharp_x(event) || alphabet_x(event) || numeric_x(event));
	}

	// 허용 문자: 모두
	function html_tag(event)
	{
		return true;
	}

	// 불허 문자: 한글
	// 한글 감지가 안되어서 구현하지 않는다.
	function ascii(event)
	{
		return true;
	}

	// 허용 문자: .0123456789
	// 예) 0.5.6
	function numeric_dot(event)
	{
		return (dot_x(event) || numeric_x(event));
	}

	// 허용 문자: -0123456789
	// 예) 010-1234-5678
	function numeric_dash(event)
	{
		return (numeric_x(event) || dash_x(event));
	}

	// 허용 문자: ,0123456789
	// 예) 12,300,300
	function numeric_comma(event)
	{
		return (numeric_x(event) || comma_x(event));
	}

	// 허용 문자: a-zA-Z
	// 예) student
	function alphabet(event)
	{
		return alphabet_x(event);
	}

	// 허용 문자: a-zA-Z0-9
	// 예) student05
	function alpha_numeric(event)
	{
		return (alphabet_x(event) || numeric_x(event));
	}


	// 허용 문자: a-zA-Z0-9-_
	// 예) my_name
	function alpha_numeric_2(event)
	{
		return (dash_x(event) || underscore_x(event) || alphabet_x(event) || numeric_x(event));
	}


	// 허용 문자: a-zA-Z0-9-_,
	// 예) my_name,
	function alpha_numeric_3(event)
	{
		return (dash_x(event) || underscore_x(event) || comma_x(event) || alphabet_x(event) || numeric_x(event));
	}


	// 허용 문자: a-zA-Z0-9-_.
	// 예) my_name.
	function alpha_numeric_2_dot(event)
	{
		return (dash_x(event) || underscore_x(event) || dot_x(event) || alphabet_x(event) || numeric_x(event));
	}


	// 허용 문자: a-zA-Z0-9-_/
	// 예) my_name/your_name
	function alpha_numeric_2_slash(event)
	{
		return (dash_x(event) || underscore_x(event) || slash_x(event) || alphabet_x(event) || numeric_x(event));
	}


	// 허용 문자: A-Z
	// 예) STUDENT
	// keydown 이벤트에서 대소문자 구분이 불가능하다.
	function uppercase(event)
	{
		return alphabet_x(event);
	}

	// 허용 문자: A-Z 공백문자
	// 예) KWANG IL
	// keydown 이벤트에서 대소문자 구분이 불가능하다.
	function uppercase_space(event)
	{
		return (alphabet_x(event) || space_x(event));
	}

	// 허용 문자: A-Z0-9
	// 예) STUDENT05
	// keydown 이벤트에서 대소문자 구분이 불가능하다.
	function uppercase_numeric(event)
	{
		return (alphabet_x(event) || numeric_x(event));
	}

	// 허용 문자: a-z
	// 예) student
	// keydown 이벤트에서 대소문자 구분이 불가능하다.
	function lowercase(event)
	{
		return alphabet_x(event);
	}

	// 허용 문자: a-z0-9
	// 예) student05
	// keydown 이벤트에서 대소문자 구분이 불가능하다.
	function lowercase_numeric(event)
	{
		return (alphabet_x(event) || numeric_x(event));
	}

	// 허용 문자: a-z0-9-_
	// 예) my_name
	function lowercase_numeric_2(event)
	{
		return (dash_x(event) || underscore_x(event) || alphabet_x(event) || numeric_x(event));
	}


	// 허용 문자: a-z0-9-_,
	// 예) my_name,
	function lowercase_numeric_3(event)
	{
		return (dash_x(event) || underscore_x(event) || comma_x(event) || alphabet_x(event) || numeric_x(event));
	}


	// 허용 문자: a-z0-9-_.
	// 예) my_name.
	function lowercase_numeric_2_dot(event)
	{
		return (dash_x(event) || underscore_x(event) || dot_x(event) || alphabet_x(event) || numeric_x(event));
	}


	// 허용 문자: a-z0-9-_/
	// 예) my_name/your_name
	function lowercase_numeric_2_slash(event)
	{
		return (dash_x(event) || underscore_x(event) || slash_x(event) || alphabet_x(event) || numeric_x(event));
	}

	// 허용 문자: .0-9a-zA-Z_+-@
	// 예) gadje@paran.com
	function email_address(event)
	{
		return ( dot_x(event) || alphabet_x(event) || numeric_x(event) || underscore_x(event) || plus_x(event) || dash_x(event) || at_x(event) );
	}

	// 허용 문자: /^([*+!.&#$|\'\\%\/0-9a-z^_`{}=?~:-]*)$/i
	// 예) gadje@paran.com
	function email_address_rfc(event)
	{
		return true;
	}

	// 허용 문자: .0-9a-zA-Z_+-@
	// 예) gadje05
	function account_general(event)
	{
		return ( dot_x(event) || alphabet_x(event) || numeric_x(event) || underscore_x(event) || plus_x(event) || dash_x(event) || at_x(event) );
	}

	// 허용 문자: .0-9a-zA-Z_+-@
	// 예) gadje05@@ddanzi
	function account_login_service(event)
	{
		return ( dot_x(event) || alphabet_x(event) || numeric_x(event) || underscore_x(event) || plus_x(event) || dash_x(event) || at_x(event) );
	}

	// 허용 문자: .0-9a-zA-Z_+-@
	// 예) gadje05@@ddanzi
	function account_email_or_login_service(event)
	{
		return ( dot_x(event) || alphabet_x(event) || numeric_x(event) || underscore_x(event) || plus_x(event) || dash_x(event) || at_x(event) );
	}

	// 허용 문자: .0-9a-zA-Z-@
	// 예) www.apiservice.kr
	function domain(event)
	{
		return ( dot_x(event) || alphabet_x(event) || numeric_x(event) || dash_x(event) || at_x(event) );
	}

	// 허용 문자: .0-9
	// 예) 123.123.123.123
	function ip_address(event)
	{
		return ( dot_x(event) || numeric_x(event) );
	}

	// 허용 문자: .0-9
	// 예) 123.123.123.123
	function ip_address_filter(event)
	{
		return ( dot_x(event) || numeric_x(event) || asterisk_x(event) );
	}

	// 허용 문자: 0123456789-
	// 예) 010-1234-1234
	function phone_num(event)
	{
		return ( numeric_x(event) || dash_x(event) );
	}

	// 허용 문자: 0123456789-
	// 예) 010-1234-1234
	function phone_num_dash(event)
	{
		return ( numeric_x(event) || dash_x(event) );
	}

	// 허용 문자: 0123456789
	// 예) 01012341234
	function phone_num_no_dash(event)
	{
		return ( numeric_x(event) );
	}

	// 허용 문자: 0123456789-
	// 예) 010-1234-1234
	function phone_num_global(event)
	{
		return ( numeric_x(event) || dash_x(event) || plus_x(event) );
	}

	// 허용 문자: 0123456789-
	// 예) 031-1234-1234
	function telephone(event)
	{
		return ( numeric_x(event) || dash_x(event) );
	}

	// 허용 문자: 0123456789
	// 예) 123456
	function auth_num(event)
	{
		return numeric_x(event);
	}

	// 허용 문자: 0123456789
	// 예) 770707
	function birthday6(event)
	{
		return numeric_x(event);
	}

	// 허용 문자: 0123456789
	// 예) 19770707
	function birthday8(event)
	{
		return numeric_x(event);
	}

	// 허용 문자: 0123456789
	// 예) 2.0.40
	function app_version(event)
	{
		return ( dot_x(event) || numeric_x(event) );
	}

	// 허용 문자: 0123456789-
	// 예) 105-87-02930
	function corporate_registration_number(event)
	{
		return ( numeric_x(event) || dash_x(event) );
	}

	// 허용 문자: 한글
	// 예) 한글
	// 한글을 감지할 수 없다.
	function hangul(event)
	{
		return hangul_x(event);
	}

	// 허용 문자: .0-9a-z_+-@
	// 예) worker
	function worker_id(event)
	{
		return ( dot_x(event) || alphabet_x(event) || numeric_x(event) || underscore_x(event) || plus_x(event) || dash_x(event) || at_x(event) );
	}

	// 허용 문자: .0-9a-z_+-@
	// 예) worker
	function worker_id4(event)
	{
		return ( dot_x(event) || alphabet_x(event) || numeric_x(event) || underscore_x(event) || plus_x(event) || dash_x(event) || at_x(event) );
	}

	// 허용 문자: 0-9,
	// 예) 12,13,14
	function numeric_list(event)
	{
		return ( numeric_x(event) || comma_x(event) );
	}

	// 허용 문자: a-zA-Z0-9,
	// 예) 209ghal3g,1a937ghghao,29a8gha9
	function alpha_numeric_list(event)
	{
		return ( alphabet_x(event) || numeric_x(event) || comma_x(event) );
	}

	// 허용 문자: 0-9a-zA-Zㄱ-ㅎㅏ-ㅣ가-힣
	function hangul_alpha_numeric(event)
	{
		return ( hangul_x(event) || alphabet_x(event) || numeric_x(event) );
	}

	// 허용 문자: a-zA-Z\sㄱ-ㅎㅏ-ㅣ가-힣
	function user_name(event)
	{
		return ( hangul_x(event) || alphabet_x(event) || space_x(event) );
	}

	// 허용 문자: 0-9\-a-zA-Z\sㄱ-ㅎㅏ-ㅣ가-힣
	function hangul_alpha_numeric_dash_space(event)
	{
		return ( hangul_x(event) || alphabet_x(event) || numeric_x(event) || dash_x(event) || space_x(event) );
	}

	// 허용 문자: 0123456789-
	// 예) 2-40
	function numeric_range(event)
	{
		return ( numeric_x(event) || dash_x(event) );
	}

	// 허용 문자: 아스키 + 한글 \x20-\x7Eㄱ-ㅎㅏ-ㅣ가-힣
	// ascii: 한글 감지가 안되어서 구현하지 않는다.
	function address_user_input(event)
	{
		return ( true/*ascii*/ || hangul_x(event) );
	}

	///////////////////////////////////////////////////////////////////////////////////////////

	let m_key_function_map = [
		{'alphabet'		: alphabet_x  },
		{'numeric'		: numeric_x   },
		{'dot'			: dot_x       },
		{'plus'			: plus_x      },
		{'minus'		: minus_x     },
		{'dash'			: dash_x      },
		{'underscore'	: underscore_x},
		{'at'			: at_x        },
		{'sharp'		: sharp_x     },
		{'comma'		: comma_x     },
		{'colon'		: colon_x     },
		{'space'		: space_x     },
		{'asterisk'		: asterisk_x  },
		{'percentage'	: percentage_x},
		{'question'		: question_x  },
		{'slash'		: slash_x     }
	];

	function _get_function_list(listKey)
	{
		const _get_function_name = function(key_function_map, key_type) {
			for( let index in key_function_map )
			{
				if( typeof key_function_map[index][key_type]==='function' )
					return key_function_map[index][key_type];
			}
			return null;
		};

		let listFunctions = [];
		for( let index in listKey )
		{
			let function_name = _get_function_name(m_key_function_map, listKey[index]);
			if( function_name!=null )
				listFunctions.push(function_name);
		}

		return listFunctions;
	}

	// listKey: ['numeric','dot']
	function general(event, listKey)
	{
		let listFunctions = _get_function_list(listKey);
		if( listFunctions.length===0 )
		{
			return always_allowed_x(event);
		}
		else
		{
			for( let index in listFunctions )
			{
				let fn = listFunctions[index];
				if( fn.call(null,event) )
					return true;
			}
			return false;
		}
	}

	///////////////////////////////////////////////////////////////////////////////////////////

	// Enter 키인가?
	function is_enter(event)
	{
		let keycode = event.which || event.keyCode;
		return (keycode===KEYCODE_ENTER);
	}

	// Tab 문자인가?
	function is_tab(event)
	{
		let keycode = event.which || event.keyCode;
		return (keycode===KEYCODE_TAB);
	}

	// BACKSPACE 문자인가?
	function is_backspace(event)
	{
		let keycode = event.which || event.keyCode;
		return (keycode===KEYCODE_BACKSPACE);
	}

	// DELETE 문자인가?
	function is_delete(event)
	{
		let keycode = event.which || event.keyCode;
		return (keycode===KEYCODE_DELETE);
	}

	// 다음 이동 설정
	function initialize_move_to_next_input(objSubmit/*,fnSubmit*/)
	{
		// '다음' 진행 시
		$(':input').keydown(function (e) {
			let key = e.charCode ? e.charCode : e.keyCode ? e.keyCode : 0;
			if ( key === KEYCODE_ENTER || key === KEYCODE_TAB )	// enter, tab
			{
				e.preventDefault();
				let inputs = $(':input:visible:enabled');
				if ((inputs.length-1) === inputs.index(this))
				{
					if( objSubmit )
						$(objSubmit).focus().select();
					//if( typeof fnSubmit==='function' )
					//	fnSubmit.apply();
				}
				else
				{
					inputs.eq(inputs.index(this) + 1).focus().select();
				}
			}
		});
	}

	///////////////////////////////////////////////////////////////////////////////////////////

	// 노출할 public 메쏘드들
	return {
		// 정수
		'numeric'						: numeric,
		'integer_zero_positive'			: integer_zero_positive,
		'integer_positive'				: integer_positive,
		'integer'						: integer,
		// 부동소수
		'float_zero_positive'			: float_zero_positive,
		'float_positive'				: float_positive,
		'_float'						: _float,
		// 날짜
		'date'							: date,
		'time'							: time,
		'datetime'						: datetime,
		'yearmonth'						: yearmonth,
		// 일반
		'filename'						: filename,
		'strict_file_path'				: strict_file_path,
		'simple_url'					: simple_url,
		'standard_url'					: standard_url,
		'general_url'					: general_url,
		'hex_color'						: hex_color,
		'color_picker'					: color_picker,
		'html_tag'						: html_tag,
		'ascii'							: ascii,
		'numeric_dot'					: numeric_dot,
		'numeric_dash'					: numeric_dash,
		'numeric_comma'					: numeric_comma,
		'alphabet'						: alphabet,
		'alpha_numeric'					: alpha_numeric,
		'alpha_numeric_2'				: alpha_numeric_2,
		'alpha_numeric_3'				: alpha_numeric_3,
		'alpha_numeric_2_dot'			: alpha_numeric_2_dot,
		'alpha_numeric_2_slash'			: alpha_numeric_2_slash,
		'uppercase'						: uppercase,
		'uppercase_space'				: uppercase_space,
		'uppercase_numeric'				: uppercase_numeric,
		'lowercase'						: lowercase,
		'lowercase_numeric'				: lowercase_numeric,
		'lowercase_numeric_2'			: lowercase_numeric_2,
		'lowercase_numeric_3'			: lowercase_numeric_3,
		'lowercase_numeric_2_dot'		: lowercase_numeric_2_dot,
		'lowercase_numeric_2_slash'		: lowercase_numeric_2_slash,
		'email_address'					: email_address,
		'email_address_rfc'				: email_address_rfc,
		'account_general'				: account_general,
		'account_login_service'			: account_login_service,
		'account_email_or_login_service': account_email_or_login_service,
		'domain'						: domain,
		'ip_address'					: ip_address,
		'ip_address_filter'				: ip_address_filter,
		'phone_num'						: phone_num,
		'phone_num_dash'				: phone_num_dash,
		'phone_num_no_dash'				: phone_num_no_dash,
		'phone_num_global'				: phone_num_global,
		'telephone'						: telephone,
		'auth_num'						: auth_num,
		'birthday6'						: birthday6,
		'birthday8'						: birthday8,
		'app_version'					: app_version,
		'corporate_registration_number'	: corporate_registration_number,
		'hangul'						: hangul,
		'worker_id'						: worker_id,
		'worker_id4'					: worker_id4,
		'numeric_list'					: numeric_list,
		'alpha_numeric_list'			: alpha_numeric_list,
		'hangul_alpha_numeric'			: hangul_alpha_numeric,
		'user_name'						: user_name,
		'hangul_alpha_numeric_dash_space'	: hangul_alpha_numeric_dash_space,
		'numeric_range'					: numeric_range,
		'address_user_input'			: address_user_input,
		'general'						: general,
		// 도우미 함수
		'is_enter'						: is_enter,
		'is_tab'						: is_tab,
		'is_backspace'					: is_backspace,
		'is_delete'						: is_delete,
		// 세팅 함수
		'initialize_move_to_next_input'	: initialize_move_to_next_input
	};
})();
