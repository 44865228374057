import challengeStyles from "../../common/css/Challenge.module.css";
import TabItem from "./TabItem";
import {isEmptyObj} from "../../Utils/CommonUtils";
import {useDialog} from "../../common/hook/DialogContext";
import {useAuth} from "../../common/hook/AuthContext";
import { useTranslation } from "react-i18next";

const ChallengeTopTabs = ({ tabMenu, uid, challenge, teamUid }) => {
    const baseUrl = `/challenge/${uid}`;
    const {addAlert, addConfirm} = useDialog();
    const { isLogin } = useAuth();
    const {t} = useTranslation();

    return (
        <div className={challengeStyles.top_tabs}>
            <TabItem isOn={tabMenu === "info"} url={baseUrl + `/info/overview`}><span>{t("challenge.menu.info.info")}</span></TabItem>
            {isEmptyObj(challenge) ?
                <TabItem isOn={tabMenu === "data"} url={baseUrl + `/data/agreement`}><span>{t("challenge.menu.data.data")}</span></TabItem>
                :
                challenge.challengeTeamMember ?
                    <TabItem isOn={tabMenu === "data"} url={baseUrl + `/data/agreement`}><span>{t("challenge.menu.data.data")}</span></TabItem>
                    :
                    <button type="button" onClick={() => addAlert(t("msg.valid.limitData"))} className={challengeStyles.tab_item}><span>{t("challenge.menu.data.data")}</span></button>

            }
            <TabItem isOn={tabMenu === "board"} url={baseUrl + `/board`}><span>{t("challenge.menu.board.board")}</span></TabItem>
            <TabItem isOn={tabMenu === "team"} url={baseUrl + `/team`}><span>{t("challenge.menu.team.team")}</span></TabItem>
            <TabItem isOn={tabMenu === "ranking"} url={baseUrl + `/public-ranking`}><span>{t("challenge.menu.chart.chart")}</span></TabItem>
            {(isLogin && challenge.challengeTeamMember) && (
                <TabItem isOn={tabMenu === "submit"} url={baseUrl + `/submit`}><span>{t("challenge.menu.submission.submission")}</span></TabItem>
            )}
        </div>
    );
};

export default ChallengeTopTabs;