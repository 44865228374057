import challengeStyles from "../../common/css/Challenge.module.css";
import commonStyles from "../../common/css/Common.module.css";
import agreementStyles from "./ChallengeTeamCreateAgreement.module.css";
import EnterItem from "../common/EnterItem";
import {useEffect, useState} from "react";
import ChallengeConsentItem from "./ChallengeConsentItem";
import {useNavigate} from "react-router-dom";
import ConsentWrap from "../account/ConsentWrap";
import ConsentItem from "../account/ConsentItem";
import _ from "lodash";
import {Loading} from "../../routes/basic/Unknown";
import ChallengeTeamEnterBox from "./ChallengeTeamEnterBox";
import {useDialog} from "../../common/hook/DialogContext";
import { useTranslation } from "react-i18next";

const ChallengeTeamCreateAgreement = ({uid}) => {
    const [agreements, setAgreements] = useState([]);
    const navigate = useNavigate();
    const [checkedState, setCheckedState] = useState({});
    const {addAlert, addConfirm} = useDialog();
    const {t} = useTranslation();

    const getChallengeAgreements = () => {
        fetch(`/v1/challenge-agreement/${uid}`)
            .then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    return response.json().then(json => {
                        addAlert(
                            json.message,
                            () => navigate(`/challenge/${uid}/team`)
                        );
                    })
                }
            })
            .then((json) => {
                setAgreements(json);
            });
    }

    useEffect(() => {
        getChallengeAgreements();
    }, []);


    // DB에서 가져온 데이터를 이용하여 상태 초기화
    useEffect(() => {
        const initialState = {};
        if (agreements) {
            agreements.forEach((item, idx) => {
                initialState[idx] = false;
            });
        }
        setCheckedState(initialState);
    }, [agreements]);

    // 체크박스 변경 핸들러
    const handleCheckboxChange = (id) => {
        setCheckedState({ ...checkedState, [id]: !checkedState[id] });
    };

    // 모두 동의 체크박스 핸들러
    const handleAllConsentChange = () => {
        const isAllChecked = !isAllConsentsChecked();
        const newState = {};
        Object.keys(checkedState).forEach(key => {
            newState[key] = isAllChecked;
        });
        setCheckedState(newState);
    };

    // 모든 체크박스가 체크되었는지 확인
    const isAllConsentsChecked = () => {
        return Object.values(checkedState).every(value => value);
    };

    return(
        !_.isEmpty(agreements) ?
            <>
                <ChallengeTeamEnterBox>
                    <ConsentWrap addClass={challengeStyles.consent_wrap}>
                        <ConsentItem addClass={`${challengeStyles.consent_item} ${agreementStyles.consent_item} ${challengeStyles.all_check}`}>
                            <EnterItem addClass={challengeStyles.enter_item}>
                                <div className={`${commonStyles.enter} ${challengeStyles.enter}`}>
                                    <div className={`${commonStyles.check_item} ${challengeStyles.check_item}`}>
                                        <label>
                                            <input
                                                type="checkbox"
                                                checked={isAllConsentsChecked()}
                                                onChange={handleAllConsentChange}
                                            />
                                            <span>{t("challenge.menu.team.allAgree")}</span>
                                        </label>
                                    </div>
                                </div>
                            </EnterItem>
                        </ConsentItem>
                        {agreements.map((agreement, idx) => (
                            <ChallengeConsentItem
                                key={idx}
                                addClass={`${challengeStyles.consent_item} ${agreementStyles.consent_item}`}
                                agreement={agreement}
                                checked={checkedState[idx]}
                                onChange={() => handleCheckboxChange(idx)}
                            />
                        ))}
                    </ConsentWrap>
                </ChallengeTeamEnterBox>
                <div className={challengeStyles.bottom_btn_wrap}>
                    <button type="button" className={`${commonStyles.cc_btn} ${challengeStyles.cc_btn} ${commonStyles.bg_white}`} onClick={() => navigate(`/challenge/${uid}/team`)}>
                        <span>{t("common.cancel")}</span>
                    </button>
                    <button type="button" className={`${commonStyles.cc_btn} ${challengeStyles.cc_btn}`} onClick={() => navigate(`/challenge/${uid}/team/create-application`)} disabled={!isAllConsentsChecked()}>
                        <span>{t("common.next")}</span>
                    </button>
                </div>
            </>
            :
            <Loading />
    );
};

export default ChallengeTeamCreateAgreement;