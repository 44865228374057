import { useTranslation } from "react-i18next";

const Footer = ({footerRef}) => {
    const {t} = useTranslation();
    return (
        <footer ref={footerRef} id="footer">
            <div>
                <div className="copyright">© MAIC 2020. All rights reserved</div>
                <button type="button" className="email_refusal_btn"><span>{t("common.footer")}</span></button>
            </div>
        </footer>
    );
};

export default Footer;