import commonStyles from "../../common/css/Common.module.css";

const ChallengeRule = ({evaluationRules}) => {
    const contentHtml = () => {
        return {__html: evaluationRules};
    }
    return (
        <div className={commonStyles.editor_content} dangerouslySetInnerHTML={contentHtml()}></div>
    );
};

export default ChallengeRule;