import SrOnly from "../../component/common/SrOnly";
import AccountWrap from "../../component/account/AccountWrap";
import AccountBox from "../../component/account/AccountBox";
import AccountTitle from "../../component/account/AccountTitle";
import accountStyles from "../../common/css/Account.module.css";
import Enter from "../../component/common/Enter";
import commonStyles from "../../common/css/Common.module.css";
import loginStyles from "./Login.module.css";
import EnterItem from "../../component/common/EnterItem";
import CheckItem from "../../component/common/CheckItem";
import AccountBtnWrap from "../../component/account/AccountBtnWrap";
import {Link, useNavigate} from "react-router-dom";
import {useEffect, useRef, useState} from "react";
import Url from "../../common/Url";
import Container from "../../component/common/Container";
import Content from "../../component/common/Content";
import useKeepDoubleClick from "../../common/hook/useKeepDoubleClick";
import {useAuth} from "../../common/hook/AuthContext";
import {useDialog} from "../../common/hook/DialogContext";
import { useTranslation } from "react-i18next";

const Login = () => {
    const { isLogin, login, logout } = useAuth();
    const [id, setId] = useState("");
    const [pw, setPw] = useState("");

    const navigate = useNavigate();
    const selectRef = useRef();
    const [clicked, preventDoubleClick] = useKeepDoubleClick();
    const {addAlert, addConfirm} = useDialog();
    const {t} = useTranslation();

    const onChangeId = (e) => {
        setId(e.target.value);
    }

    const onChangePw = (e) => {
        setPw(e.target.value);
    }

    const validate = () => {
        if (!id) {
            addAlert(t("msg.valid.emptyId"));
            return false;
        } else if (!pw) {
            addAlert(t("msg.valid.emptyPw"));
            return false;
        }
        return true;
    }

    useEffect(() => {
        if (localStorage.getItem("rememberedId") !== null) {
            setId(localStorage.getItem("rememberedId"));
            selectRef.current.checked = true;
        }
    }, []);

    useEffect(() => {
        if (isLogin) {
            addAlert(
                t("msg.error.alreadyLogin"),
                () => navigate(Url.home, { replace: true })
            );
        }
    }, [isLogin]);

    return (
        <Container>
            <Content>

                <SrOnly>로그인 페이지</SrOnly>

                <AccountWrap>
                    <AccountBox>
                        <AccountTitle>{t("common.login")}</AccountTitle>
                        <div className="enter_wrap">
                            <EnterItem addClass={accountStyles.enter_item}>
                                <Enter
                                    addClass={`${accountStyles.enter}`}
                                    id={"user_id"}
                                    type={"text"}
                                    title={t("login.id")}
                                    placeholder={t("login.id")}
                                    label={"아이디"}
                                    inputChange={onChangeId}
                                    value={id}
                                >
                                </Enter>
                            </EnterItem>
                            <EnterItem addClass={accountStyles.enter_item}>
                                <Enter
                                    addClass={accountStyles.enter}
                                    id={"user_pw"}
                                    type={"password"}
                                    title={t("login.pw")}
                                    placeholder={t("login.pw")}
                                    label={"비밀번호 입력"}
                                    inputChange={onChangePw}
                                    name={"password"}
                                    value={pw}
                                />
                            </EnterItem>
                            <EnterItem addClass={`${accountStyles.enter_item} ${accountStyles.option}`}>
                                <div className={`${commonStyles.enter} ${accountStyles.enter}`}>
                                    <CheckItem>
                                        <label>
                                            <input type="checkbox" ref={selectRef} />
                                                <span>{t("login.save")}</span>
                                        </label>
                                    </CheckItem>
                                </div>
                                <Link to={Url.findId} className={accountStyles.find_account_btn}><span>{t("login.findId")}</span></Link>
                                <Link to={Url.passwordFind} className={accountStyles.find_account_btn}><span>{t("login.findPw")}</span></Link>
                            </EnterItem>
                        </div>

                        <AccountBtnWrap addClass={loginStyles.account_btn_wrap}>
                            <a href="#" onClick={(e) => {e.preventDefault(); login(validate, id, pw, selectRef)}} className={`${commonStyles.cc_btn} ${accountStyles.cc_btn}`} title={t("common.login")}><span>{t("common.login")}</span></a>
                            <Link to={Url.signUp} className={`${commonStyles.cc_btn} ${accountStyles.cc_btn} ${commonStyles.bg_white}`} title={t("login.sign")}>
                                <span>{t("login.sign")}</span>
                            </Link>
                        </AccountBtnWrap>
                    </AccountBox>
                </AccountWrap>

            </Content>
        </Container>
    );
};

export default Login;