import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import commonStyles from "../../common/css/Common.module.css";

const Ckeditor = ({ setContent, content, addClass = "" }) => {
    const customUploadAdapter = (loader) => {
        return {
            upload() {
                return new Promise((resolve, reject) => {
                    const formData = new FormData();
                    loader.file.then((file) => {
                        formData.append("uploadFile", file);
                        fetch("/v1/image/upload-editor", {
                            method: "post",
                            credentials: "include",
                            redirect: "follow",
                            body: formData,
                        })
                            .then((res) => res.json())
                            .then((data) => {
                                resolve({
                                    default: data.url,
                                });
                            });
                    });
                });
            },
        };
    };
    function uploadPlugin(editor) {
        editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
            return customUploadAdapter(loader);
        };
    }
    return (
        <div className={`${commonStyles.editor_content} ${addClass}`}>
            <CKEditor
                editor={ClassicEditor}
                data={content}
                config={{ extraPlugins: [uploadPlugin]}}
                onChange={(event, editor) => {
                    setContent(editor.getData());
                }}
                // 에디터 렌더링 될때 스타일 적용 하는곳.
                onReady={(e) => {
                    e.editing.view.change((writer) => {
                        writer.setStyle(
                        "min-height",
                        "360px",
                        e.editing.view.document.getRoot()
                        );
                    });
                }}
            ></CKEditor>
        </div>
    );
};
export default Ckeditor;
