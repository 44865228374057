import {MdBody, MdFooter, MdHeader, ModalBox} from "../common/ModalTags";
import commonStyles from "../../common/css/Common.module.css";
import challengeStyles from "../../common/css/Challenge.module.css";
import EnterItem from "../common/EnterItem";
import RadioItem from "../common/RadioItem";
import {useEffect, useState} from "react";
import useKeepDoubleClick from "../../common/hook/useKeepDoubleClick";
import {useDialog} from "../../common/hook/DialogContext";
import { useTranslation } from "react-i18next";

const ChangeTeamInfoMd = ({open, closedMd, teamUid, teamInfoCondition, teamInfoIntro, teamInfoRefresh, setTeamInfoRefresh}) => {
    const [condition, setCondition] = useState(teamInfoCondition);
    const [intro, setIntro] = useState(teamInfoIntro);
    const [clicked, preventDoubleClick] = useKeepDoubleClick();
    const {addAlert, addConfirm} = useDialog();
    const {t} = useTranslation();

    const conditionValues = [
        t("challenge.menu.team.reqAll"), t("challenge.menu.team.reqMd"), t("challenge.menu.team.reqDev"), t("challenge.menu.team.reqmdNdev")
    ];
    const close = () => {
        setCondition(teamInfoCondition);
        setIntro(teamInfoIntro);
        closedMd();
    }

    const conditionHandle = (e) => {
        setCondition(e.target.value);
        if(e.target.value === condition) {
            e.target.checked = true;
        }
    };

    useEffect(() => {
        setCondition(teamInfoCondition);
    }, [teamInfoCondition]);

    useEffect(() => {
        setIntro(teamInfoIntro);
    }, [teamInfoIntro]);

    const updateData = () => {
        addConfirm(
            t("msg.info.updateTeam"),
            () => {
                fetch(`/v1/challenge-team/${teamUid}`, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                    method: "put",
                    credentials: "include",
                    redirect: "follow",
                    body: JSON.stringify({
                        condition: condition,
                        intro: intro
                    }),
                }).then((response) => {
                    clicked.current = false;
                    if (response.ok) {
                        addAlert(
                            t("msg.info.finishUpdate"),
                            () => {
                                setTeamInfoRefresh(!teamInfoRefresh);
                                close();
                            }
                        );
                    } else if (response.status === 400) {
                        response.json().then(json => {
                            addAlert(json.message);
                        })
                    } else if (response.status === 401) {
                        response.json().then(json => {
                            addAlert(json.message);
                        })
                    }else {
                        throw new Error(t("msg.error.failUpdate"));
                    }
                }).catch(e => {
                    addAlert(e.message);
                });
            },
            () => clicked.current = false
        );
    };

    const validation = () => {
        if (intro.trim() === "") {
            addAlert(t("msg.valid.emptyIntro2"));
            return;
        }

        return true;
    };
    const submit = () => {
        preventDoubleClick(updateData, validation);
    };

    return (
        <ModalBox addClass={commonStyles.change_team_info} open={open} close={close}>
            <div>
                <div>
                    <MdHeader>{t("challenge.menu.team.updateTeamInfo")}</MdHeader>
                    <MdBody>
                        <div>
                            <div>
                                <div className={`${commonStyles.enter_wrap} ${challengeStyles.enter_wrap}`}>
                                    {/* <EnterItem>
                                        <div className={`${commonStyles.enter} ${challengeStyles.enter} ${commonStyles.flex} ${challengeStyles.flex}`}>
                                            {conditionValues.map((conditionValue, idx) => (
                                                <RadioItem key={`conditionValue${idx}`} addClass={challengeStyles.radio_item}>
                                                    <label>
                                                        <input
                                                            type="radio"
                                                            id={`team_option_${idx + 1}`}
                                                            name="team_option"
                                                            value={conditionValue}
                                                            onClick={(e) => conditionHandle(e)}
                                                            checked={conditionValue === condition}
                                                        />
                                                        <span>{conditionValue}</span>
                                                    </label>
                                                </RadioItem>
                                            ))}
                                        </div>
                                    </EnterItem> */}
                                    <EnterItem addClass={challengeStyles.enter_item}>
                                        <label htmlFor="team_intro" className={commonStyles.sr_only}>{t("challenge.menu.team.teamInfo")}</label>
                                        <div className={`${commonStyles.enter} ${challengeStyles.enter}`}>
                                            <textarea
                                                id="team_intro"
                                                value={intro}
                                                onChange={(e) => setIntro(e.target.value)}
                                            />
                                        </div>
                                    </EnterItem>
                                </div>
                            </div>
                        </div>
                    </MdBody>
                    <MdFooter>
                        <div className={`${commonStyles.bottom_btn_wrap} ${challengeStyles.bottom_btn_wrap}`}>
                            <button
                                type="button"
                                className={`${commonStyles.cc_btn} ${challengeStyles.cc_btn}`}
                                onClick={submit}
                            >
                                <span>{t("common.change")}</span>
                            </button>
                        </div>
                    </MdFooter>
                </div>
            </div>
        </ModalBox>
    );
};

export default ChangeTeamInfoMd;