import React, {useEffect} from "react";
import loadingStyle from "../../common/css/Loading.module.css";
import {useDialog} from "../../common/hook/DialogContext";


function Unknown() {
    const {addAlert, addConfirm} = useDialog();
    // 임의 처리
    function useAlert() {
        addAlert(
            "잘못된 이동입니다. 메인 페이지로 이동합니다.",
            () => window.location.replace("/")
        )
    }

    useEffect(() => {
        setTimeout(useAlert, 1000);
    }, []);

    return (
        <div id="container">
            <div className="content">
                <Loading/>
            </div>
        </div>
    )
}

export const Loading = ({changeStyle = {}}) => {
    return (
        <div className={loadingStyle.loading} style={changeStyle}>
            <span></span>
            <span></span>
            <span></span>
        </div>
    )
}

export default Unknown;