import ChallengeTeamCreate from "./ChallengeTeamCreate";
import ChallengeTeamList from "./ChallengeTeamList";
import ChallengeTeamDetail from "./ChallengeTeamDetail";

const ChallengeTeam = ({innerMenu, uid, challenge, challengeRefresh, setChallengeRefresh}) => {
    return(
        <>
            {innerMenu === "list" && (
                <ChallengeTeamList uid={uid} isCreate={challenge.create} openState={challenge.openState} />
            )}

            {(innerMenu === "createAgreement" || innerMenu === "createApplication") && (
                <ChallengeTeamCreate innerMenu={innerMenu} uid={uid} challengeRefresh={challengeRefresh} setChallengeRefresh={setChallengeRefresh} />
            )}

            {innerMenu === "detail" && (
                <ChallengeTeamDetail challengeUid={uid} isJupyter={challenge.jupyter} isApply={challenge.apply} openState={challenge.openState} />
            )}


        </>
    )
}

export default ChallengeTeam;