import {gsap} from "gsap";
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import {useLocation} from "react-router-dom";
import {useEffect, useRef} from "react";
import commonStyles from "../../common/css/Common.module.css";

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(ScrollToPlugin);

const ScrollBottom = ({footerRef}) => {
    const {pathname} = useLocation();
    const scrollRef = useRef(null);


    useEffect(() => {
        gsap.to(scrollRef.current, {
            duration: 0.5,
            autoAlpha: 0,
            yPercent: -80,
            ease: 'back.in(1.5)',
            scrollTrigger: {
                trigger: footerRef.current,
                start: 'top 90%',
                end: 'top 90%',
                toggleActions: 'play none reverse none'
            }
        });
    }, [pathname]);

    function moveScrollBottom() {
        const docEnd = document.documentElement.scrollHeight;
        gsap.to(window, {duration: 0.8, scrollTo: docEnd, ease: 'back.inOut(1.5)'});
    };

    return (
        <button ref={scrollRef} type="button" id="scroll_bottom_btn" className={`${commonStyles.scroll_btn} ${commonStyles.scroll_bottom_btn}`} onClick={moveScrollBottom}>
            <span></span><em className={commonStyles.sr_only}>화면 하단으로 이동</em>
        </button>

    );
};

export default ScrollBottom;