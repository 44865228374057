import $ from "jquery";
import jQuery from "jquery";
export const AMControl=(function(){
	// "use strict";

	// 줄바꿈 처리
	function nl2br(str)
	{
		let breakTag = '<br />';
		return (str + '').replace(/([^>]?)\n/g, '$1'+ breakTag +'\n');
	}

	function toast2(msg,target/*id 또는 class*/,position)
	{
		let objJson = {
			"msg"					: msg,
			"duration_milliseconds"	: 3500,
			"duration"				: "1",
			"base_object"			: $(target),
			"position"				: position
		};
		toast(objJson);
	}

	// 토스트 메시지 표시
	//let objJson = {
	//  "msg"					: msg,
	//  "duration_milliseconds"	: 3500,
	//  "duration"				: "1",
	//  "base_object"			: $("#id"),
	//  "position"				: 'left'	// base_object 개체가 존재할 때 의미를 가짐
	//};
	// position: left, right, up(top), down(bottom)
	//           up-left, up-right, down-left, down-right
	//           up-left-align, down-left-align
	//           center (버튼위)
	//           default=screen-center (디폴트)
	function toast(objJson)
	{
		let delay_second = 3500;
		if( typeof objJson.duration_milliseconds!='undefined' )
			delay_second = parseInt(objJson.duration_milliseconds,10);
		else if( objJson.duration==='1' || objJson.duration==='0' )
			delay_second = (objJson.duration==="1")? 3500 : 2000;

		// 1. 기존 <div> 태그 제거
		if( $("#div_toast_message").length > 0 )
			$("#div_toast_message").remove();

		// 2. 태그 생성, 텍스트 지정
		let style = "display: none; position: fixed; top: 40%; margin: auto; padding: 10px; max-width: 90%; font-size: 13px; line-height: 1.7em; color: #f7f7f7; text-align: left; letter-spacing: 0.1em; border-radius: 10px; background-color: rgba(0,0,0,0.8); z-index: 9990;";
		$("body").append("<div id='div_toast_message' style='"+style+"'>"+nl2br(objJson.msg)+"</div>");

		// https://www.safaribooksonline.com/library/view/javascript-dhtml/0596004672/ch13s08.html
		const get_scroll_x = function(){
			let scrollX = 0;
			if( document.body && typeof document.body.scrollTop!="undefined" )
			{
				scrollX += document.body.scrollLeft;
				if(document.body.parentNode && typeof document.body.parentNode.scrollTop != "undefined")
					scrollX += document.body.parentNode.scrollLeft;
			}
			else if( typeof window.pageXOffset!="undefined" )
			{
				scrollX += window.pageXOffset;
			}

			return scrollX;
		};

		const get_scroll_y = function(){
			let scrollY = 0;
			if( document.body && typeof document.body.scrollTop!="undefined" )
			{
				scrollY += document.body.scrollTop;
				if(document.body.parentNode && typeof document.body.parentNode.scrollTop != "undefined")
					scrollY += document.body.parentNode.scrollTop;
			}
			else if( typeof window.pageXOffset!="undefined" )
			{
				scrollY += window.pageYOffset;
			}

			return scrollY;
		};

		let scroll_x = get_scroll_x();
		let scroll_y = get_scroll_y();

		let objBaseObject = null;
		if( objJson.base_object instanceof jQuery )
			objBaseObject = objJson.base_object;
		else if( typeof objJson.base_object==='string' && objJson.base_object!=='' )
			objBaseObject = $('#'+objJson.base_object);

		let top  = "40%";
		let left = ( $(window).width() - $('#div_toast_message').outerWidth() ) / 2;

		if( objBaseObject instanceof jQuery )
		{
			// 왼쪽
			if( objJson.position==="left" )
			{
				top  = objBaseObject.offset().top - ($('#div_toast_message').outerHeight() - objBaseObject.outerHeight()) /2 - scroll_y;
				left = objBaseObject.offset().left - $('#div_toast_message').outerWidth() -5 - scroll_x;
			}
			// 오른쪽
			else if( objJson.position==="right" )
			{
				top  = objBaseObject.offset().top - ($('#div_toast_message').outerHeight() - objBaseObject.outerHeight()) /2 - scroll_y;
				left = objBaseObject.offset().left +objBaseObject.outerWidth()  + 5 - scroll_x;
			}
			// 위쪽
			else if( objJson.position==="up" || objJson.position==="top" )
			{
				top  = objBaseObject.offset().top - $('#div_toast_message').outerHeight() - 5 - scroll_y;
				left = objBaseObject.offset().left - ($('#div_toast_message').outerWidth()-objBaseObject.outerWidth())/2 - scroll_x;
			}
			// 아래쪽
			else if( objJson.position==="down" || objJson.position==="bottom" )
			{
				top  = objBaseObject.offset().top +objBaseObject.outerHeight() + 5 - scroll_y;
				left = objBaseObject.offset().left - ($('#div_toast_message').outerWidth()-objBaseObject.outerWidth())/2 - scroll_x;
			}
			// 왼쪽위
			else if( objJson.position==="up-left" )
			{
				top  = objBaseObject.offset().top - $('#div_toast_message').outerHeight() - 5 - scroll_y;
				left = objBaseObject.offset().left - $('#div_toast_message').outerWidth() -5 - scroll_x;
			}
			// 오른쪽위
			else if( objJson.position==="up-right" )
			{
				top  = objBaseObject.offset().top - $('#div_toast_message').outerHeight() - 5 - scroll_y;
				left = objBaseObject.offset().left +objBaseObject.outerWidth()  + 5 - scroll_x;
			}
			// 왼쪽아래
			else if( objJson.position==="down-left" )
			{
				top  = objBaseObject.offset().top +objBaseObject.outerHeight() + 5 - scroll_y;
				left = objBaseObject.offset().left - $('#div_toast_message').outerWidth() -5 - scroll_x;
			}
			// 오른쪽아래
			else if( objJson.position==="down-right" )
			{
				top  = objBaseObject.offset().top +objBaseObject.outerHeight() + 5 - scroll_y;
				left = objBaseObject.offset().left +objBaseObject.outerWidth()  + 5 - scroll_x;
			}
			// 상단-왼쪽맞춤
			else if( objJson.position==="up-left-align" )
			{
				top  = objBaseObject.offset().top - $('#div_toast_message').outerHeight() - 5 - scroll_y;
				left = objBaseObject.offset().left - scroll_x;
			}
			// 하단-왼쪽맞춤
			else if( objJson.position==="down-left-align" )
			{
				top  = objBaseObject.offset().top +objBaseObject.outerHeight() + 5 - scroll_y;
				left = objBaseObject.offset().left - scroll_x;
			}
			// 중앙(버튼위)
			else if( objJson.position==="center" )
			{
				top  = objBaseObject.offset().top - ($('#div_toast_message').outerHeight() - objBaseObject.outerHeight()) /2 - scroll_y;
				left = objBaseObject.offset().left - ($('#div_toast_message').outerWidth()-objBaseObject.outerWidth())/2 - scroll_x;
			}
			// 화면중앙
			else if( objJson.position==="default" || objJson.position==="screen-center" )
			{
				top  = "40%";
				left = ( $(window).width() - $('#div_toast_message').outerWidth() ) / 2;
			}
			else
			{
				//console.log(objJson);
			}
		}

		$('#div_toast_message').css('top', top);
		$('#div_toast_message').css('left', left);
		$("#div_toast_message").fadeIn().delay(delay_second).fadeOut();
	}

	// 노출할 public 메쏘드들
	return {
		'toast2': toast2,
		'toast'	: toast
	};
})();
