import commonStyles from "../../common/css/Common.module.css";

const ChallengeDataFile = ({fileDetail}) => {
    const contentHtml = () => {
        return {__html: fileDetail};
    }
    return (
        <div className={commonStyles.editor_content} dangerouslySetInnerHTML={contentHtml()}></div>
    );
};

export default ChallengeDataFile;