import {useLocation} from "react-router-dom";
import {useEffect, useState} from "react";
import Url from "../../common/Url";
import commonStyles from "../../common/css/Common.module.css";
import accountStyles from "../../common/css/Account.module.css";

const Container = ({children}) => {
    const [isAccount, setIsAccount] = useState(false);
    const location = useLocation();

    useEffect(() => {
        const pathname = location.pathname;
        if (pathname.includes(Url.login) || pathname.includes(Url.findId) || pathname.includes(Url.passwordReset) || pathname.includes(Url.passwordFind)) {
            setIsAccount(true);
        } else {
            setIsAccount(false);
        }

    }, [location]);

    return (
            <main id={!isAccount ? commonStyles["container"] : accountStyles["container"]}>{children}</main>
    );
};

export default Container;
