import commonStyles from "../../common/css/Common.module.css";

const EditorContent = ({children}) => {

    const contentHtml = () => {
        return {__html: children};
    }

    return (
        <div className={commonStyles.editor_content} dangerouslySetInnerHTML={contentHtml()}></div>
    );
};

export default EditorContent;
