import Container from "../../component/common/Container";
import Content from "../../component/common/Content";
import MyPage from "../../component/myPage/MyPage";

const MyPageRoute = ({tabMenu}) => {
    return (
        <Container>
            <Content>
                <MyPage tabMenu={tabMenu} />
            </Content>
        </Container>
    );
};

export default MyPageRoute;