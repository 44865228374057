import {useRef} from "react";

const useKeepDoubleClick = () => {
    const clicked = useRef(false);

    const preventDoubleClick = (submitFunction, validateFunction = () => {return true}) => {
        if (clicked.current) {
            return;
        }
        clicked.current = true;

        if (!validateFunction()) {
            clicked.current = false;
            return;
        }
        submitFunction();
    }

    return [clicked, preventDoubleClick];
}

export default useKeepDoubleClick;
