import challengeStyles from "../../common/css/Challenge.module.css";

const ChallengeTeamRankingContent = ({ rankInfo }) => {
    return (
        <tr>
            <td data-th="NO">
                <div>{rankInfo.ranking}</div>
            </td>
            <td data-th="팀" className={challengeStyles.ta_left}>
                <div>{rankInfo.name}</div>
            </td>
            <td data-th="구성원" className={challengeStyles.ta_left}>
                <div>
                    {rankInfo.memberNames.map((memberName, idx) => (
                        rankInfo.memberNames.length -1 !== idx ?
                            `${memberName}, `
                            :
                            memberName
                    ))}
                </div>
            </td>
            <td data-th="점수">
                <div>{rankInfo.score}</div>
            </td>
            <td data-th="제출수">
                <div>{rankInfo.submitCnt}</div>
            </td>
            <td data-th="최종 제출일">
                <div>{rankInfo.finalDate}</div>
            </td>
        </tr>
    );
};

export default ChallengeTeamRankingContent;