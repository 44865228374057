import Container from "../../component/common/Container";
import Content from "../../component/common/Content";
import SrOnly from "../../component/common/SrOnly";
import AccountWrap from "../../component/account/AccountWrap";
import AccountBox from "../../component/account/AccountBox";
import AccountTitle from "../../component/account/AccountTitle";
import ConsentWrap from "../../component/account/ConsentWrap";
import ConsentItem from "../../component/account/ConsentItem";
import ConsentBox from "../../component/account/ConsentBox";
import ContentHtmlBox from "../../component/account/ContentHtmlBox";
import EnterItem from "../../component/common/EnterItem";
import accountStyles from "../../common/css/Account.module.css";
import Enter from "../../component/common/Enter";
import CheckItem from "../../component/common/CheckItem";
import Essential from "../../component/common/Essential";
import commonStyles from "../../common/css/Common.module.css";
import CcBtn from "../../component/common/CcBtn";
import RadioItem from "../../component/common/RadioItem";
import AccountBtnWrap from "../../component/account/AccountBtnWrap";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {FormInputHelper} from "../../common/php_lib/form-input-helper";
import {emailPattern, isBlankStr, namePattern, pwPattern, signUpIdPattern} from "../../Utils/ValidateUtils";
import useKeepDoubleClick from "../../common/hook/useKeepDoubleClick";
import {useDialog} from "../../common/hook/DialogContext";
import { useTranslation } from "react-i18next";

const SignUp = () => {
    const [signUpData, setSignUpData] = useState({
        userId: '',
        password: '',
        checkPassword: '',
        userName: '',
        email: '',
        dept: '',
        position: '',
        isClinician: ''
    });
    const [duplicateId, setDuplicateId] = useState(false);
    const [tosAgreement, setTosAgreement] = useState(false);
    const [privacyAgreement, setPrivacyAgreement] = useState(false);
    const navigate = useNavigate();
    const [clicked, preventDoubleClick] = useKeepDoubleClick();
    const {addAlert, addConfirm} = useDialog();
    const {t} = useTranslation();

    const idChange = (event) => {
        setSignUpData(prevState => ({
            ...prevState,
            ['userId']: event.target.value
        }));
        setDuplicateId(true);
    };

    const inputChange = (event) => {
        const { name, value } = event.target;
        setSignUpData(prevState => ({
            ...prevState,
            [name]: value
        }));
    }

    const isClinicianChange = (event) => {
        setSignUpData(prevState => ({
            ...prevState,
            ['isClinician']: event.target.value
        }));
    };

    const checkIdValidate = () => {
        if(!signUpIdPattern.test(signUpData.userId) || !signUpData.userId) {
            addAlert(t("msg.valid.wrongId"));
            return false;
        }
        return true;
    }

    const checkId = () => {
        fetch("/v1/users/check-duplicate-id", {
            method: "post",
            headers: {
                "Content-Type": "application/json",
            },
            credentials: "include",
            body: JSON.stringify({
                userId: signUpData.userId,
            }),
        }).then((response) => {
            clicked.current = false;
            if (response.ok) {
                setDuplicateId(false);
                addAlert(t("msg.valid.dubpCheck"));
            } else if (response.status === 409) {
                setDuplicateId(true);
                addAlert(t("msg.valid.alreadyId"));
            }
        });
    };

    const signUpEvent = () => {
        addConfirm(
            t("msg.info.requestSignUp"),
            () => {
                fetch("/v1/users/signup", {
                    method: "post",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    credentials: "include",
                    body: JSON.stringify(signUpData),
                }).then((response) => {
                    clicked.current = false;
                    if(response.status === 201) {
                        addAlert(
                            t("msg.info.finishSignUp"),
                            () => navigate("/", { state: { id: signUpData.userId } })
                        );
                    } else {
                        return response.json();
                    }
                }).then(json => {
                    if(json !== undefined) {
                        addAlert(json.message);
                    }
                });
            },
            () => clicked.current = false
        )

    };

    const tosCheckFromChildren = () => {
        !tosAgreement ? setTosAgreement(true) : setTosAgreement(false);
    };

    const privacyCheckFromChildren = () => {
        !privacyAgreement ? setPrivacyAgreement(true) : setPrivacyAgreement(false);
    };

    const validate = () => {
        if (!tosAgreement) {
            addAlert(t("msg.valid.agree"))
            return false;
        }

        if (!privacyAgreement) {
            addAlert(t("msg.valid.agree2"))
            return false;
        }

        if(!signUpIdPattern.test(signUpData.userId) || !signUpData.userId) {
            addAlert(t("msg.valid.wrongId2"));
            return false;
        }

        if (duplicateId) {
            addAlert(t("msg.valid.dupId"))
            return false;
        }
        if(!pwPattern.test(signUpData.password) || !signUpData.password) {
            addAlert(t("msg.valid.pw"));
            return false;
        }
        if(isBlankStr(signUpData.checkPassword)) {
            addAlert(t("msg.valid.checkConfirm"));
            return false;
        }
        if (signUpData.password !== signUpData.checkPassword) {
            addAlert(t("msg.valid.notEqPw"));
            return false;
        }

        if (!namePattern.test(signUpData.userName) || !signUpData.userName) {
            addAlert(t("msg.valid.wrongName"));
            return false;
        }
        if (!emailPattern.test(signUpData.email) || !signUpData.email) {
            addAlert(t("msg.valid.wrongEmail"));
            return false;
        }
        return true;
    }

    useEffect(() => {
        FormInputHelper.initKeyUpChecker();
        FormInputHelper.initKeyDownChecker();
        FormInputHelper.initCharacterLimiter();
    }, []);

    return (
        <Container>
            <Content>

                <SrOnly tag={"h2"}>회원가입 페이지</SrOnly>

                <AccountWrap>
                    <AccountBox>
                        <AccountTitle>{t("common.sign")}</AccountTitle>
                        <ConsentWrap>
                            <ConsentItem>
                                <ContentHtmlBox tabIndex={1}>
                                    {t("sign.agreeDoc1")}
                                </ContentHtmlBox>
                                <div className="enter_wrap">
                                    <EnterItem addClass={accountStyles.enter_item}>
                                        <div className={`${commonStyles.enter} ${accountStyles.enter}`}>
                                            <CheckItem>
                                                <label>
                                                    <input type="checkbox" onChange={tosCheckFromChildren} checked={tosAgreement} />
                                                    <span><Essential>[{t("common.need")}]</Essential>{t("sign.agree1")}</span>
                                                </label>
                                            </CheckItem>
                                        </div>
                                    </EnterItem>
                                </div>
                            </ConsentItem>
                            <ConsentItem>
                                <ContentHtmlBox tabIndex={1}>
                                    {t("sign.agreeDoc2")}
                                </ContentHtmlBox>
                                <div className="enter_wrap">
                                    <EnterItem addClass={accountStyles.enter_item}>
                                        <div className={`${commonStyles.enter} ${accountStyles.enter}`}>
                                            <CheckItem>
                                                <label>
                                                    <input type="checkbox" onChange={privacyCheckFromChildren} checked={privacyAgreement} />
                                                    <span><Essential>[{t("common.need")}]</Essential>{t("sign.agree2")}</span>
                                                </label>
                                            </CheckItem>
                                        </div>
                                    </EnterItem>
                                </div>
                            </ConsentItem>
                        </ConsentWrap>
                        <div className="enter_wrap">
                            <EnterItem addClass={accountStyles.enter_item}>
                                <Enter
                                    addClass={`${accountStyles.enter} ${commonStyles.flex} ${accountStyles.flex}`}
                                    id={"user_id"}
                                    type={"text"}
                                    title={t("sign.id")}
                                    placeholder={t("sign.id")}
                                    label={"아이디"}
                                    inputChange={idChange}
                                    value={signUpData.userId}
                                >
                                    <CcBtn
                                        addClass={`${accountStyles.cc_btn} ${commonStyles.bg_gray}`}
                                        title={t("sign.double")}
                                        onClick={() => preventDoubleClick(checkId, checkIdValidate)}
                                    >
                                        <span>{t("sign.double")}</span>
                                    </CcBtn>
                                </Enter>
                            </EnterItem>
                            <EnterItem addClass={accountStyles.enter_item}>
                                <Enter
                                    addClass={accountStyles.enter}
                                    id={"user_pw"}
                                    type={"password"}
                                    title={t("sign.pw")}
                                    placeholder={t("sign.pw")}
                                    label={"비밀번호"}
                                    inputChange={inputChange}
                                    name={"password"}
                                    value={signUpData.password}
                                />
                            </EnterItem>
                            <EnterItem addClass={accountStyles.enter_item}>
                                <Enter
                                    addClass={accountStyles.enter}
                                    id={"user_cpw"}
                                    type={"password"}
                                    title={t("sign.pwConfirm")}
                                    placeholder={t("sign.pwConfirm")}
                                    label={"비밀번호 확인"}
                                    inputChange={inputChange}
                                    name={"checkPassword"}
                                    value={signUpData.checkPassword}
                                />
                            </EnterItem>
                            <EnterItem addClass={accountStyles.enter_item}>
                                <Enter
                                    addClass={accountStyles.enter}
                                    id={"user_name"}
                                    type={"text"}
                                    title={t("sign.name")}
                                    placeholder={t("sign.name")}
                                    label={"이름 입력"}
                                    inputChange={inputChange}
                                    name={"userName"}
                                    value={signUpData.userName}
                                    maxLength={30}
                                    dataType={"user_name"}
                                />
                            </EnterItem>
                            <EnterItem addClass={accountStyles.enter_item}>
                                <Enter
                                    addClass={accountStyles.enter}
                                    id={"user_email"}
                                    type={"text"}
                                    title={t("sign.email")}
                                    placeholder={t("sign.email")}
                                    label={"이메일 입력"}
                                    inputChange={inputChange}
                                    name={"email"}
                                    value={signUpData.email}
                                    maxLength={50}
                                    dataType={"email_address"}
                                />
                            </EnterItem>
                            <EnterItem addClass={accountStyles.enter_item}>
                                <div className={`${commonStyles.enter} ${accountStyles.enter} ${commonStyles.flex} ${accountStyles.flex}`}>
                                    <RadioItem addClass={accountStyles.radio_item}>
                                        <label>
                                            <input type="radio" name="account_radio" onChange={isClinicianChange} value={"true"} checked={signUpData.isClinician === "true"} />
                                            <span>{t("sign.docY")}</span>
                                        </label>
                                    </RadioItem>
                                    <RadioItem addClass={accountStyles.radio_item}>
                                        <label>
                                            <input type="radio" name="account_radio" onChange={isClinicianChange} value={"false"} checked={signUpData.isClinician === "false"} />
                                            <span>{t("sign.docN")}</span>
                                        </label>
                                    </RadioItem>
                                </div>
                            </EnterItem>
                            <EnterItem addClass={accountStyles.enter_item}>
                                <Enter
                                    addClass={accountStyles.enter}
                                    id={"user_team"}
                                    type={"text"}
                                    title={t("sign.dept")}
                                    placeholder={t("sign.dept")}
                                    label={"[선택] 소속병원/기관 입력"}
                                    inputChange={inputChange}
                                    name={"dept"}
                                    value={signUpData.dept}
                                    maxLength={50}
                                />
                            </EnterItem>
                            <EnterItem addClass={accountStyles.enter_item}>
                                <Enter
                                    addClass={accountStyles.enter}
                                    id={"user_position"}
                                    type={"text"}
                                    title={t("sign.agent")}
                                    placeholder={t("sign.agent")}
                                    label={"[선택] 직위 입력"}
                                    inputChange={inputChange}
                                    name={"position"}
                                    value={signUpData.position}
                                    maxLength={50}
                                />
                            </EnterItem>
                        </div>

                        <AccountBtnWrap>
                            <a  href={"#"}
                                onClick={(event) => {
                                    event.preventDefault();
                                    preventDoubleClick(signUpEvent, validate);
                                }}
                                className={`${commonStyles.cc_btn} ${accountStyles.cc_btn}`}
                                title={t("sign.signBtn")}>
                                <span>{t("sign.signBtn")}</span>
                            </a>
                        </AccountBtnWrap>
                    </AccountBox>
                </AccountWrap>

            </Content>
        </Container>
    );
};

export default SignUp;