import BbsWrap from "./BbsWrap";
import challengeStyles from "../../common/css/Challenge.module.css";
import TeamBox from "./teamBox/TeamBox";
import TeamName from "./teamBox/TeamName";
import TeamInfo from "./teamBox/TeamInfo";
import {ItemCondition, ItemIntro, ItemMember} from "./teamBox/InfoItem";
import commonStyles from "../../common/css/Common.module.css";
import {useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import _ from "lodash";
import {Loading} from "../../routes/basic/Unknown";
import ChallengeTeamApply from "./ChallengeTeamApply";
import ChangeTeamInfoMd from "./ChangeTeamInfoMd";
import ChangeTeamLeaderMd from "./ChangeTeamLeaderMd";
import {useAuth} from "../../common/hook/AuthContext";
import {useDialog} from "../../common/hook/DialogContext";
import BanTeamMemberMd from "./BanTeamMemberMd";
import { useTranslation } from "react-i18next";

const ChallengeTeamDetail = ({challengeUid, isJupyter, isApply, openState}) => {
    const param = useParams();
    const {isLogin, userUid} = useAuth();
    const [teamInfoRefresh, setTeamInfoRefresh] = useState(true);
    const [teamUid, setTeamUid] = useState(param.teamUid);
    const [teamInfo, setTeamInfo] = useState({});
    const [changeTeamInfoMdOpen, setChangeTeamInfoMdOpen] = useState(false);
    const [changeTeamLeaderMdOpen, setChangeTeamLeaderMdOpen] = useState(false);
    const [banTeamMemberMdOpen, setBanTeamMemberMdOpen] = useState(false);
    const [isTeamLeader, setIsTeamLeader] = useState(false);
    const [isTeamMember, setIsTeamMember] = useState(false);
    const navigate = useNavigate();
    const {addAlert, addConfirm} = useDialog();
    const {t} = useTranslation();

    const getTeam = () => {
        fetch(`/v1/challenge-team/${teamUid}`)
            .then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    return response.json().then(json => {
                        addAlert(json.message);
                    })
                }
            })
            .then((json) => {
                setTeamInfo(json);
            });
    }

    useEffect(() => {
        getTeam();
    },[teamInfoRefresh]);

    useEffect(() => {
        if (!_.isEmpty(teamInfo)) {
            setIsTeamLeader(Number(userUid) === teamInfo.teamLeaderInfo.userUid);
            for (let i =0; i < teamInfo.teamMemberInfos.length; i++) {
                if (Number(userUid) === teamInfo.teamMemberInfos[i].userUid) {
                    setIsTeamMember(true);
                    break;
                }
            }

        }
    }, [teamInfo, userUid])

    return (
        <>
            {!_.isEmpty(teamInfo) ?
                <BbsWrap>
                    <div className={challengeStyles.top}>
                        <div className={challengeStyles.table_title}>{t("challenge.menu.team.infoRequest")}</div>
                    </div>

                    <TeamBox>
                        <TeamName>{teamInfo.name}</TeamName>
                        <TeamInfo>
                            {/* <ItemCondition>
                                <div>{teamInfo.condition}</div>
                                {isTeamLeader && openState !== "CLOSED" && (
                                    <button type="button" className={challengeStyles.change_btn} onClick={() => setChangeTeamInfoMdOpen(true)}>
                                        <span>{t("common.change")}</span>
                                    </button>
                                )}
                            </ItemCondition> */}
                            <ItemIntro>
                                <div style={{whiteSpace: "pre-wrap"}}>{teamInfo.intro}</div>
                                {isTeamLeader && openState !== "CLOSED" && (
                                    <button type="button" className={challengeStyles.change_btn} onClick={() => setChangeTeamInfoMdOpen(true)}>
                                        <span>{t("common.change")}</span>
                                    </button>
                                )}
                            </ItemIntro>
                            <ItemMember>
                                <div className={challengeStyles.member_list}>
                                    <div className={`${challengeStyles.member_item} ${challengeStyles.leader}`}>
                                    <span>
                                        {teamInfo.teamLeaderInfo.name} ({teamInfo.teamLeaderInfo.clinician ? `${t("sign.docY")} / ${teamInfo.teamLeaderInfo.email}` : teamInfo.teamLeaderInfo.email})
                                    </span>
                                        {(isTeamLeader && !_.isEmpty(teamInfo.teamMemberInfos)) && (
                                            <button type="button" className={challengeStyles.change_btn} onClick={() => setChangeTeamLeaderMdOpen(true)}>
                                                <span>{t("common.change")}</span>
                                            </button>
                                        )}
                                        {(isTeamLeader && !_.isEmpty(teamInfo.teamMemberInfos)) && (
                                            <button type="button" className={challengeStyles.change_btn} onClick={() => setBanTeamMemberMdOpen(true)}>
                                                <span>{t("challenge.menu.team.kick")}</span>
                                            </button>
                                        )}
                                    </div>
                                    <div className={challengeStyles.member_item}>
                                        {
                                            teamInfo.teamMemberInfos.map((teamMemberInfo, idx) => (
                                                <span key={`${teamMemberInfo.email}++${idx}++`}>
                                                    {teamMemberInfo.name} ({teamMemberInfo.clinician ? `${t("sign.docY")} / ${teamMemberInfo.email}` : teamMemberInfo.email})
                                                </span>
                                            ))
                                        }
                                    </div>
                                </div>
                            </ItemMember>
                        </TeamInfo>
                    </TeamBox>
                    <div className={challengeStyles.bottom_btn_wrap}>
                        <button type="button"
                                className={`${commonStyles.cc_btn} ${challengeStyles.cc_btn} ${commonStyles.bg_white}`}
                                onClick={() => navigate(`/challenge/${challengeUid}/team`)}>
                            <span>{t("challenge.menu.board.list")}</span>
                        </button>
                        {((isTeamLeader || isTeamMember) && openState !== "CLOSED" && isJupyter && teamInfo.url ) && (
                            <button
                                type="button"
                                className={`${commonStyles.cc_btn} ${challengeStyles.cc_btn} ${isJupyter && teamInfo.url && openState !== "CLOSED" ? "" : commonStyles.bg_white_black}`}
                                onClick={
                                    () => {
                                        if (isJupyter && teamInfo.url && openState !== "CLOSED") {
                                            addAlert(
                                                t("msg.info.connectJupyter"),
                                                () => window.open(teamInfo.url, "_blank")
                                            );
                                        } else {
                                            addAlert(t("msg.valid.limitJupyter"))
                                        }
                                    }
                                }
                            >
                                <span>{t("challenge.menu.team.conn")}</span>
                            </button>
                        )}
                    </div>

                    <ChallengeTeamApply
                        isLogin={isLogin}
                        teamApplyWaitingInfos={teamInfo.teamApplyWaitingInfos}
                        challengeUid={challengeUid}
                        teamUid={teamUid}
                        teamInfoRefresh={teamInfoRefresh}
                        setTeamInfoRefresh={setTeamInfoRefresh}
                        isTeamLeader={isTeamLeader}
                        isTeamMember={isTeamMember}
                        userUid={userUid}
                        isApply={isApply}
                        openState={openState}
                    />
                </BbsWrap>
                :
                <Loading/>}

            <ChangeTeamInfoMd
                open={changeTeamInfoMdOpen}
                closedMd={() => setChangeTeamInfoMdOpen(false)}
                teamUid={teamUid}
                teamInfoCondition={teamInfo.condition}
                teamInfoIntro={teamInfo.intro}
                teamInfoRefresh={teamInfoRefresh}
                setTeamInfoRefresh={setTeamInfoRefresh}
            />

            <ChangeTeamLeaderMd
                open={changeTeamLeaderMdOpen}
                closedMd={() => setChangeTeamLeaderMdOpen(false)}
                memberInfos={teamInfo.teamMemberInfos}
                teamInfoRefresh={teamInfoRefresh}
                setTeamInfoRefresh={setTeamInfoRefresh}
            />

            <BanTeamMemberMd
                open={banTeamMemberMdOpen}
                closedMd={() => setBanTeamMemberMdOpen(false)}
                memberInfos={teamInfo.teamMemberInfos}
                teamInfoRefresh={teamInfoRefresh}
                setTeamInfoRefresh={setTeamInfoRefresh}
            />
        </>
    )
}

export default ChallengeTeamDetail;