import challengeStyles from "../../common/css/Challenge.module.css";
import EnterItem from "../common/EnterItem";
import commonStyles from "../../common/css/Common.module.css";
import {useState} from "react";
import {useNavigate} from "react-router-dom";
import {useDialog} from "../../common/hook/DialogContext";
import useKeepDoubleClick from "../../common/hook/useKeepDoubleClick";
import { useTranslation } from "react-i18next";

const ChallengeTeamApply = ({
                                isLogin,
                                teamApplyWaitingInfos,
                                challengeUid,
                                teamUid,
                                teamInfoRefresh,
                                setTeamInfoRefresh,
                                isTeamLeader,
                                isTeamMember,
                                userUid,
                                isApply,
                                openState,
}) => {
    const [content, setContent] = useState("");
    const [clicked, preventDoubleClick] = useKeepDoubleClick();
    const navigate = useNavigate();
    const {addAlert, addConfirm} = useDialog();
    const {t} = useTranslation();

    const insertData = () => {
        addConfirm(
            t("msg.info.requestJoin"),
            () => {
                fetch("/v1/challenge-team-apply", {
                    headers: {
                        "Content-Type": "application/json",
                    },
                    method: "post",
                    credentials: "include",
                    redirect: "follow",
                    body: JSON.stringify({
                        teamUid: teamUid,
                        content: content,
                    }),
                }).then((response) => {
                    clicked.current = false;
                    if (response.ok) {
                        addAlert(
                            t("msg.info.finishSubmit"),
                            () => setTeamInfoRefresh(!teamInfoRefresh)
                        );
                    } else if (response.status === 400) {
                        response.json().then(json => {
                            addAlert(json.message);
                        })
                    } else if (response.status === 401) {
                        response.json().then(json => {
                            addAlert(
                                json.message,
                                () => navigate(`/challenge/${challengeUid}/team`)
                            );
                        })
                    }else {
                        throw new Error(t("msg.error.failSubmit"));
                    }
                }).catch(e => {
                    addAlert(e.message);
                });
            },
            () => {
                clicked.current = false;
            }
        );

    };

    const validation = () => {
        if (!isApply || openState === "CLOSED") {
            addAlert(t("msg.valid.limitJoin"));
            return;
        }

        if (isTeamMember || isTeamLeader) {
            addAlert(t("msg.valid.alreadyTeam"));
            return;
        }

        if (content.trim() === "") {
            addAlert(t("msg.valid.emptyIntro"));
            return;
        }

        return true;
    };
    const submit = () => {
        preventDoubleClick(insertData, validation);
    };

    const updateAcceptance = (teamApplyUid, acceptance) => {
        addConfirm(
            //`${t("msg.info.requestJoin1")} ${acceptance} ${t("msg.info.requestJoin2")}`,
            `${t("msg.info.requestJoin2")} ${acceptance}`,
            () => {
                fetch(`/v1/challenge-team-apply/${teamApplyUid}/acceptance/${acceptance === t("challenge.menu.team.submit") ? `approved` : `rejected`}`, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                    method: "put",
                    credentials: "include",
                    redirect: "follow",
                }).then((response) => {
                    clicked.current = false;
                    if (response.ok) {
                        addAlert(
                            `${acceptance} ${t("msg.info.requestJoin3")}`,
                            () => setTeamInfoRefresh(!teamInfoRefresh)
                        );
                    } else if (response.status === 400) {
                        response.json().then(json => {
                            addAlert(json.message);
                        })
                    } else if (response.status === 401) {
                        response.json().then(json => {
                            addAlert(json.message);
                        })
                    }else {
                        throw new Error(`${acceptance}${t("msg.info.requestJoin4")}`);
                    }
                }).catch(e => {
                    addAlert(e.message);
                });
            },
            () => {
                clicked.current = false;

            }
        );
    };

    const submitAcceptance = (teamApplyUid, acceptance) => {
        preventDoubleClick(() => updateAcceptance(teamApplyUid, acceptance));
    };

    const deleteTeamApply = (teamApplyUid) => {
        addConfirm(
            t("msg.info.deleteJoin"),
            () => {
                fetch(`/v1/challenge-team-apply/${teamApplyUid}`, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                    method: "delete",
                    credentials: "include",
                    redirect: "follow",
                }).then((response) => {
                    clicked.current = false;
                    if (response.ok) {
                        addAlert(
                            t("msg.info.finishDelete"),
                            () => setTeamInfoRefresh(!teamInfoRefresh)
                        );
                    } else if (response.status === 400) {
                        response.json().then(json => {
                            addAlert(json.message);
                        })
                    } else if (response.status === 401) {
                        response.json().then(json => {
                            addAlert(json.message);
                        })
                    }else {
                        throw new Error(t("msg.error.failDelete"));
                    }
                }).catch(e => {
                    addAlert(e.message);
                });
            },
            () => {
                clicked.current = false;
            }
        );
    };

    const validationDelete = (WaitingInfoUserUid) => {
        if (Number(userUid) !== WaitingInfoUserUid) {
            addAlert(t("msg.error.notSelfDelete"));
            return;
        }

        return true;
    };


    return (
        <div className={challengeStyles.request_wrap}>
            <div className={challengeStyles.request_enter}>
                <EnterItem addClass={challengeStyles.enter_item}>
                    <div className={`${commonStyles.enter} ${challengeStyles.enter} ${commonStyles.flex} ${challengeStyles.flex}`}>
                        <input
                            type="text"
                            placeholder={t("challenge.menu.team.intro")}
                            value={content}
                            onChange={(e) => setContent(e.target.value)}
                            maxLength={100}
                            disabled={isTeamLeader || isTeamMember}
                        />
                        <button
                            type="button"
                            className={`${commonStyles.cc_btn} ${challengeStyles.cc_btn} ${isApply && openState !== "CLOSED" ? "" : commonStyles.bg_white_black }`}
                            onClick={() => {
                                if (isLogin) {
                                    submit();
                                } else {
                                    addAlert(t("msg.error.login"));
                                }
                            }}
                        >
                            <span>{t("challenge.menu.team.request")}</span>
                        </button>
                    </div>
                </EnterItem>
            </div>
            <div className={challengeStyles.request_list}>
                {teamApplyWaitingInfos.map((waitingInfo, idx) => (
                    <div className={challengeStyles.request_item} key={`${waitingInfo.email}++${idx}++`}>
                        <div className="request_info">
                            <div className={challengeStyles.request_top}>
                                <div className={challengeStyles.name}><em className={commonStyles.fc_blue}>{waitingInfo.name}</em> ({waitingInfo.clinician ? `${t("sign.docY")} / ${waitingInfo.email}`: waitingInfo.email})</div>
                                <div className={challengeStyles.date}>{waitingInfo.createdDate}</div>
                            </div>
                            <div className={challengeStyles.request_comment}>{waitingInfo.content}</div>
                        </div>
                        {isTeamLeader && openState !== "CLOSED" ?
                            <div className={challengeStyles.request_btn_wrap}>
                                <button
                                    type="button"
                                    className={challengeStyles.request_btn}
                                    onClick={() => submitAcceptance(waitingInfo.teamApplyUid, t("challenge.menu.team.submit"))}
                                >
                                    <span>{t("challenge.menu.team.submit")}</span>
                                </button>
                                <button
                                    type="button"
                                    className={`${challengeStyles.request_btn} ${challengeStyles.reject}`}
                                    onClick={() => submitAcceptance(waitingInfo.teamApplyUid, t("challenge.menu.team.nothank"))}
                                >
                                    <span>{t("challenge.menu.team.nothank")}</span>
                                </button>
                            </div>
                            :
                            Number(userUid) === waitingInfo.userUid ?
                                <div className={challengeStyles.request_btn_wrap}>
                                    <button
                                        type="button"
                                        className={`${challengeStyles.request_btn} ${challengeStyles.delete}`}
                                        onClick={() => preventDoubleClick(() => deleteTeamApply(waitingInfo.teamApplyUid), () => validationDelete(waitingInfo.userUid))}
                                    >
                                        <span>{t("common.delete")}</span>
                                    </button>
                                </div>
                                :
                                ""
                        }

                    </div>
                ))}
            </div>
        </div>
    );
};

export default ChallengeTeamApply;