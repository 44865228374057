import {createContext, useContext, useState} from "react";

const DialogContext = createContext();

export const DialogProvider = ({ children }) => {
    const [alerts, setAlerts] = useState([]);
    const [confirms, setConfirms] = useState([]);

    const addAlert = (message, afterClose = () => {}) => {
        setAlerts(prev => [...prev, { id: Date.now() + message, message: message, afterClose: afterClose }]);
    };

    const removeAlert = (id) => {
        setAlerts(prev => prev.filter(alert => alert.id !== id));
    };

    const addConfirm = (message, afterConfirm = () => {}, afterCancel = () => {}) => {
        setConfirms(prev => [...prev, { id: Date.now() + message, message: message, afterConfirm: afterConfirm, afterCancel: afterCancel }]);
    };

    const removeConfirm = (id) => {
        setConfirms(prev => prev.filter(confirm => confirm.id !== id));
    };

    return (
        <DialogContext.Provider value={{ addAlert, removeAlert, addConfirm, removeConfirm, alerts, confirms }}>
            {children}
        </DialogContext.Provider>
    );
};

export const useDialog = () => {
    return useContext(DialogContext);
}