import {Link} from "react-router-dom";
import Url from "../../common/Url";
import { useTranslation } from "react-i18next";

const ChallengeList = ({ challenges }) => {
    const {t} = useTranslation();
    
    return (
        <div className="challenge_list">
            {challenges.map(challenge => (
                <Link to={`/challenge/${challenge.uid}/info/overview`} className="challenge_item">
                    <div className="item_thumb">
                        <img src={`/v1/image/${challenge.thumbnailUid}`} alt="" />
                        <div className={`item_status ${challenge.openState === "OPENED" ? 'underway' : ''}`}>
                            {
                                challenge.openState === "OPENED" ?
                                    t("main.ing")
                                    :
                                    challenge.openState === "CLOSED" ?
                                        t("main.finish")
                                        :
                                        t("main.pre")
                            }
                        </div>
                    </div>
                    <div className="item_info">
                        <div className="item_title">{challenge.name}</div>
                        <div className="item_desc">{challenge.intro}</div>
                        <div className="more_btn">{t("main.detail")}</div>
                    </div>
                </Link>
            ))}
        </div>
    );
};

export default ChallengeList;