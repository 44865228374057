import Container from "../../component/common/Container";
import Content from "../../component/common/Content";
import SrOnly from "../../component/common/SrOnly";
import AccountWrap from "../../component/account/AccountWrap";
import AccountBox from "../../component/account/AccountBox";
import AccountTitle from "../../component/account/AccountTitle";
import AccountDesc from "../../component/account/AccountDesc";
import accountStyles from "../../common/css/Account.module.css";
import Enter from "../../component/common/Enter";
import EnterItem from "../../component/common/EnterItem";
import {useState} from "react";
import AccountBtnWrap from "../../component/account/AccountBtnWrap";
import {isBlankStr, pwPattern} from "../../Utils/ValidateUtils";
import commonStyles from "../../common/css/Common.module.css";
import {useLocation, useNavigate} from "react-router-dom";
import useKeepDoubleClick from "../../common/hook/useKeepDoubleClick";
import {useDialog} from "../../common/hook/DialogContext";
import { useTranslation } from "react-i18next";

const AccountPasswordReset = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [requestData, setRequestData] = useState({
        npw: '',
        cpw: '',
    });
    const [clicked, preventDoubleClick] = useKeepDoubleClick();
    const {addAlert, addConfirm} = useDialog();
    const {t} = useTranslation();

    const inputChange = (event) => {
        const { name, value } = event.target;
        setRequestData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const resetPassword = () => {
        let userId = "";
        let email = "";
        if (location.state !== null) {
            userId = location.state.userId;
            email = location.state.email;
        } else {
            addAlert(t("msg.info.needAuthPw"));
            return;
        }

        fetch("/v1/users/password-reset", {
            method: "put",
            headers: {
                "Content-Type": "application/json",
            },
            credentials: "include",
            body: JSON.stringify({
                userId: userId,
                npw: requestData.npw,
                cpw: requestData.cpw,
                email: email,
            }),
        }).then((response) => {
            if (response.ok) {
                addAlert(
                    t("msg.info.finishChangePw"),
                    () => navigate("/login", {state: {id: userId}})
                );
            } else {
                return response.json().then(json => {
                    clicked.current = false;
                    addAlert(json.message);
                })
            }
        });
    };

    const validate = () => {
        if (!pwPattern.test(requestData.npw) || isBlankStr(requestData.npw)) {
            addAlert(t("msg.valid.pw"));
            return false;
        }
        if (!requestData.cpw && isBlankStr(requestData.cpw)) {
            addAlert(t("msg.valid.checkConfirm"));
            return false;
        }
        if (requestData.npw !== requestData.cpw) {
            addAlert(t("msg.valid.notEqPw"))
            return false;
        }
        return true;
    }

    return (
        <Container>
            <Content>

                <SrOnly tag={"h2"}>비밀번호 재설정 페이지</SrOnly>

                <AccountWrap>
                    <AccountBox>
                        <AccountTitle>{t("mypage.info.pw")}</AccountTitle>
                        <AccountDesc>* {t("mypage.info.updatePw")}</AccountDesc>
                        <div className="enter_wrap">
                            <EnterItem addClass={accountStyles.enter_item}>
                                <Enter
                                    addClass={accountStyles.enter}
                                    id={"user_npw"}
                                    type={"password"}
                                    title={t("mypage.info.updatePw1")}
                                    placeholder={t("mypage.info.updatePw1")}
                                    label={"새 비밀번호 입력"}
                                    inputChange={inputChange}
                                    name={"npw"}
                                    value={requestData.npw}
                                />
                            </EnterItem>
                            <EnterItem addClass={accountStyles.enter_item}>
                                <Enter
                                    addClass={accountStyles.enter}
                                    id={"user_cpw"}
                                    type={"password"}
                                    title={t("mypage.info.updatePw2")}
                                    placeholder={t("mypage.info.updatePw2")}
                                    label={"새 비밀번호 확인 입력"}
                                    inputChange={inputChange}
                                    name={"cpw"}
                                    value={requestData.cpw}
                                />
                            </EnterItem>
                        </div>

                        <AccountBtnWrap>
                            <a
                                href="#"
                                className={`${commonStyles.cc_btn} ${accountStyles.cc_btn}`}
                                onClick={
                                    (e) => {
                                        e.preventDefault();
                                        preventDoubleClick(resetPassword, validate);
                                    }
                                }
                            >
                                <span>{t("common.submit")}</span>
                            </a>
                        </AccountBtnWrap>
                    </AccountBox>
                </AccountWrap>

            </Content>
        </Container>
    );
};

export default AccountPasswordReset;