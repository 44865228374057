import commonStyles from "../../common/css/Common.module.css";

const ChallengeOverview = ({overview}) => {
    const contentHtml = () => {
        return {__html: overview};
    }

    return (
        <div className={commonStyles.editor_content} dangerouslySetInnerHTML={contentHtml()}></div>
    );
};

export default ChallengeOverview;