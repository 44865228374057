import challengeStyles from "../../common/css/Challenge.module.css";
import applicationStyles from "./ChallengeTeamCreateApplicationStyle.module.css";

const ChallengeTeamEnterBox = ({children}) => {
    return (
        <div className={`${challengeStyles.team_enter_box} ${applicationStyles.team_enter_box}`}>
            {children}
        </div>
    );
};

export default ChallengeTeamEnterBox;