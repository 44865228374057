import ChallengeBoardCreate from "./ChallengeBoardCreate";
import ChallengeBoardList from "./ChallengeBoardList";
import ChallengeBoardDetail from "./ChallengeBoardDetail";

const ChallengeBoard = ({innerMenu, uid, challenge}) => {
    return(
        <>
            {innerMenu === "list" && (
                <ChallengeBoardList challengeUid={uid} />
            )}

            {(innerMenu === "create" || innerMenu === "update") && (
                <ChallengeBoardCreate challengeUid={uid} innerMenu={innerMenu} />
            )}

            {innerMenu === "detail" && (
                <ChallengeBoardDetail challengeUid={uid} />
            )}


        </>
    )
}

export default ChallengeBoard;