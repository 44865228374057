import challengeStyles from "../../../common/css/Challenge.module.css";

export const ItemCondition = ({children}) => {
    return (
        <div className={`${challengeStyles.info_item} ${challengeStyles.condition}`}>
            {children}
        </div>
    );
};

export const ItemMember = ({children}) => {
    return (
        <div className={`${challengeStyles.info_item} ${challengeStyles.member}`}>
            {children}
        </div>
    );
};

export const ItemIntro = ({children}) => {
    return (
        <div className={`${challengeStyles.info_item} ${challengeStyles.intro}`}>
            {children}
        </div>
    );
};