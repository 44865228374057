import challengeStyles from "../../common/css/Challenge.module.css";

const BbsWrap = ({children, addClass = ""}) => {
    return (
        <div className={`${challengeStyles.bbs_wrap} ${addClass}`}>
            {children}
        </div>
    );
};

export default BbsWrap;