import commonStyles from "../../common/css/Common.module.css"
const SrOnly = ({children, tag}) => {
    return (
        tag === "h2" ?
            <h2 className={commonStyles.sr_only}>{children}</h2>
            :
            <span className={commonStyles.sr_only}>{children}</span>
    );
};

export default SrOnly;