import ChallengeOverview from "./ChallengeOverview";
import ChallengeRule from "./ChallengeRule";
import ChallengeSchedule from "./ChallengeSchedule";
import ChallengeReward from "./ChallengeReward";
import ChallengeBottomTabs from "./ChallengeBottomTabs";

const ChallengeInfo = ({tabMenu, innerMenu, challenge, uid}) => {
    return (
        <>
            <ChallengeBottomTabs
                tabMenu={tabMenu}
                innerMenu={innerMenu}
                uid={uid}
            />

            <div>
                {innerMenu === "overview" && (
                    <ChallengeOverview overview={challenge.overview} />
                )}

                {innerMenu === "rule" && (
                    <ChallengeRule evaluationRules={challenge.evaluationRules} />
                )}

                {innerMenu === "schedule" && (
                    <ChallengeSchedule scheduleDetail={challenge.scheduleDetail} />
                )}

                {innerMenu === "reward" && (
                    <ChallengeReward rewardDetail={challenge.rewardDetail} />
                )}
            </div>
        </>
    );
};

export default ChallengeInfo;