import {AMControl} from "./control";
import {AMKeycode} from "./keycode";
import {AMString} from "./string";
import $ from "jquery";
import jQuery from "jquery";

export const FormInputHelper = (function () {
    // "use strict";

    //디폴트:
    //keydown-toast='true'
    //keydown-alert='false'
    //
    //keyup-toast='true'
    //keyup-alert='false'


    // data_type에 대한 메시지
    function _get_data_type_msg(Obj, default_msg) {
        let data_type_msg = Obj.attr("data_type_msg");
        if (typeof data_type_msg === 'string' && data_type_msg !== '')
            return data_type_msg;
        else
            return default_msg;
    }

    // keydown 이벤트의 메시지
    function _keydown_msg(msg, objInput) {
        _keydown_toast(msg, objInput);
        // _keydown_alert(msg, objInput);
    }

    // keydown 이벤트의 toast 메시지
    function _keydown_toast(msg, objInput) {
        // keydown-toast: 디폴트 true
        if (typeof AMControl === 'undefined' || msg === "" || objInput.attr("keydown-toast") === "false")
            return;

        let objJson = {
            "msg": msg,
            "duration": "0",
            "base_object": objInput,
            "position": "up"
        };
        AMControl.toast(objJson);
    }

    // keydown 이벤트의 메시지박스
    // function _keydown_alert(msg, objInput)
    // {
    // 	// keydown-alert: 디폴트 false
    // 	if( msg==="" || objInput.attr("keydown-alert")!==="true" )
    // 		return;
    //
    // 	_alert_box(msg, objInput);
    // }

    ////////////////////////////////////////////////////////////////////////////////////////////////////////

    // keyup 이벤트의 메시지
    function _keyup_msg(msg, objInput) {
        _keyup_toast(msg, objInput);
        // _keyup_alert(msg, objInput);
    }

    // keyup 이벤트의 toast 메시지
    function _keyup_toast(msg, objInput) {
        // keyup-toast: 디폴트 true
        if (typeof AMControl === 'undefined' || msg === "" || objInput.attr("keyup-toast") === "false")
            return;

        let objJson = {
            "msg": msg,
            "duration": "0",
            "base_object": objInput,
            "position": "up"
        };
        AMControl.toast(objJson);
    }

    // keyup 이벤트의 메시지박스
    // function _keyup_alert(msg, objInput)
    // {
    // 	// keyup-alert: 디폴트 false
    // 	if( msg==="" || objInput.attr("keyup-alert")!==="true" )
    // 		return;
    //
    // 	_alert_box(msg, objInput);
    // }

    // 메시지 박스
    // function _alert_box(msg, objInput)
    // {
    // 	if( typeof window['AppBridgeCombine']==='object' )
    // 	{
    // 		AppBridgeCombine.SimpleAlertAndFocus(msg, objInput);
    // 	}
    // 	else if( typeof window['AppBridgeMsg']==='object' && AppBridgeMsg.hasOwnProperty("Alert") && typeof AppBridgeMsg.Alert==='function' )
    // 	{
    // 		let title = '알림';
    // 		if( typeof window['AppBridgeMsgInfo']==='object' && AppBridgeMsgInfo.hasOwnProperty("service_name") && AppBridgeMsgInfo.service_name!=="" )
    // 			title = AppBridgeMsgInfo.service_name;
    //
    // 		AppBridgeMsg.Alert({"title": title, "msg": msg});
    // 	}
    // 	else
    // 	{
    // 		alert(msg);
    // 	}
    // }

    ////////////////////////////////////////////////////////////////////////////////////////////////////////

    function initialize(objInput) {
        // initTextareaHeightController(objInput);
        initCharacterLimiter(objInput);
        initCaseChecker(objInput);
        initKeyDownChecker(objInput);
        initKeyUpChecker(objInput);
        initDatePicker(objInput);
        initTimePicker(objInput);
        initDateTimePicker(objInput);
        initYearMonthPicker(objInput);
        // initColorPicker(objInput);
    }

    // textarea 최대,최소높이 설정
    function initTextareaHeightController(objInput) {
        if (objInput instanceof jQuery) {
            if (!objInput.is('textarea'))
                objInput = $("textarea");
        } else {
            objInput = $("textarea");
        }

        objInput.each(function () {
            if ($(this).attr('height_control') !== "no") {
                let max_height = ($(this).attr('maxheight') > 0) ? $(this).attr('maxheight') : $(this).height();
                let min_height = ($(this).attr('minheight') > 0) ? $(this).attr('minheight') : $(this).height();
                $(this).css({
                    "maxHeight": max_height + "px",
                    "minHeight": min_height + "px"
                }).autosize({'append': "\n"});
            }
        });
    }

    // textarea 글자수 제한
    function initCharacterLimiter(objInput) {
        if (objInput instanceof jQuery) {
            if (!objInput.is('textarea') && !objInput.is("input[type='text']"))
                objInput = $("textarea, input[type='text']");
        } else {
            objInput = $("textarea, input[type='text']");
        }

        objInput.each(function () {
            if ($(this).attr('maxCharacters') === "")
                return true;

            let $input = $(this);
            let maximumCount = $(this).attr('maxCharacters') * 1;	//  문자를 숫자로 만들기 위해 1을 곱한다.
            // update 함수는 keyup, paste, input 이벤트에서 호출한다.
            const update = function () {
                let now = maximumCount - $input.val().length;
                // 사용자가 입력한 값이 제한 값을 초과하는지를 검사한다.
                if (now < 0) {
                    let str = $input.val();
                    _keyup_msg('글자 입력수가 초과하였습니다.', $(this));
                    $input.val(str.substr(0, maximumCount));
                    now = 0;
                }
            };
            // input, keyup, paste 이벤트와 update 함수를 바인드한다
            $input.on('input keyup paste', function () {
                setTimeout(update, 0);
            });
            update();
        });
    }

    // To Do: 나중에 작업 예정
    // onkeypress: 키보드 입력 방지
    // http://stackoverflow.com/questions/15728261/how-to-prevent-number-input-on-keydown

    // 키보드 입력시 값체크
    function initCaseChecker(objInput) {
        if (objInput instanceof jQuery) {
            if (!objInput.is("[data_type]"))
                objInput = $("[data_type]");
        } else {
            objInput = $("[data_type]");
        }

        objInput.on('input', function (evt) {

            if ($(this).attr("data_type") === 'uppercase' || $(this).attr("data_type") === 'uppercase_space' || $(this).attr("data_type") === 'uppercase_numeric') {
                let input = $(this);
                let start = input[0].selectionStart;
                $(this).val(function (_, val) {
                    return val.toUpperCase();
                });
                input[0].selectionStart = input[0].selectionEnd = start;
            } else if ($(this).attr("data_type") === 'lowercase' || $(this).attr("data_type") === 'lowercase_numeric') {
                let input = $(this);
                let start = input[0].selectionStart;
                $(this).val(function (_, val) {
                    return val.toLowerCase();
                });
                input[0].selectionStart = input[0].selectionEnd = start;
            } else if (AMString.startsWith($(this).attr("data_type"), '{')) {
                let objDataType = JSON.parse($(this).attr("data_type"));
                if (objDataType.case_checker === 'uppercase' || objDataType.case_checker === 'lowercase') {
                    let input = $(this);
                    let start = input[0].selectionStart;
                    if (objDataType.case_checker === 'uppercase') {
                        $(this).val(function (_, val) {
                            return val.toUpperCase();
                        });
                    } else if (objDataType.case_checker === 'lowercase') {
                        $(this).val(function (_, val) {
                            return val.toLowerCase();
                        });
                    }
                    input[0].selectionStart = input[0].selectionEnd = start;
                }
            }
        });
    }

    // 키보드 입력시 값체크
    // 현실적으로 정해진 문자만 입력 가능하게 할 수 없는 것 같다.
    function initKeyDownChecker(objInput) {
        if (objInput instanceof jQuery) {
            if (!objInput.is("[data_type]"))
                objInput = $("[data_type]");
        } else {
            objInput = $("[data_type]");
        }

        if (typeof AMKeycode !== 'object')
            return;

        // 모바일에서는 keycode 값이 다르다.
        // 모바일 키코드: https://stackoverflow.com/questions/31979619/how-to-get-keycodes-from-android-mobile-device-keyboard
        // if( typeof SmartDevice==='object' )
        // {
        // 	if( SmartDevice.is_mobile_client() )
        // 		return;
        // }

        const escape_deletion_for_auto_comma = function (evt, thisObj) {
            if (AMKeycode.is_backspace(evt) &&
                thisObj.attr("auto_comma") === 'true' &&
                thisObj[0].selectionStart === thisObj[0].selectionEnd &&
                thisObj[0].selectionStart > 0 &&
                thisObj.val().substring(thisObj[0].selectionStart - 1, thisObj[0].selectionStart) === ',') {
                evt.preventDefault ? evt.preventDefault() : (evt.returnValue = false);

                let start = thisObj[0].selectionStart;
                thisObj[0].selectionStart = thisObj[0].selectionEnd = start - 1;

                return true;
            } else if (AMKeycode.is_delete(evt) &&
                thisObj.attr("auto_comma") === 'true' &&
                thisObj[0].selectionStart === thisObj[0].selectionEnd &&
                thisObj[0].selectionStart < thisObj.val().length &&
                thisObj.val().substring(thisObj[0].selectionStart, thisObj[0].selectionStart + 1) === ',') {
                evt.preventDefault ? evt.preventDefault() : (evt.returnValue = false);

                let start = thisObj[0].selectionStart;
                thisObj[0].selectionStart = thisObj[0].selectionEnd = start + 1;

                return true;
            } else {
                return false;
            }
        };

        objInput.keydown(function (evt) {

            if ($(this).attr("data_type") === 'integer_positive') {
                if (!escape_deletion_for_auto_comma(evt, $(this))) {
                    if (!AMKeycode.integer_positive(evt)) {
                        evt.preventDefault ? evt.preventDefault() : (evt.returnValue = false);
                        _keydown_msg(_get_data_type_msg($(this), "양의 정수만 입력해 주세요."), $(this));
                    }
                }
            } else if ($(this).attr("data_type") === 'integer_zero_positive') {
                if (!escape_deletion_for_auto_comma(evt, $(this))) {
                    if (!AMKeycode.integer_zero_positive(evt)) {
                        evt.preventDefault ? evt.preventDefault() : (evt.returnValue = false);
                        _keydown_msg(_get_data_type_msg($(this), "0보다 크거나 같은 정수만 입력해 주세요."), $(this));
                    }
                }
            } else if ($(this).attr("data_type") === 'integer') {
                if (!escape_deletion_for_auto_comma(evt, $(this))) {
                    if (!AMKeycode.integer(evt)) {
                        evt.preventDefault ? evt.preventDefault() : (evt.returnValue = false);
                        _keydown_msg(_get_data_type_msg($(this), "정수(음수 가능)만 입력해 주세요."), $(this));
                    }
                }
            } else if ($(this).attr("data_type") === 'float_positive') {
                if (!escape_deletion_for_auto_comma(evt, $(this))) {
                    if (!AMKeycode.float_positive(evt)) {
                        evt.preventDefault ? evt.preventDefault() : (evt.returnValue = false);
                        _keydown_msg(_get_data_type_msg($(this), "양의 실수(부동소수점) 형식(정수 형식 가능)으로 입력해 주세요."), $(this));
                    }
                }
            } else if ($(this).attr("data_type") === 'float_zero_positive') {
                if (!escape_deletion_for_auto_comma(evt, $(this))) {
                    if (!AMKeycode.float_zero_positive(evt)) {
                        evt.preventDefault ? evt.preventDefault() : (evt.returnValue = false);
                        _keydown_msg(_get_data_type_msg($(this), "0보다 크거나 같은 실수(부동소수점) 형식(정수 형식 가능)으로 입력해 주세요."), $(this));
                    }
                }
            } else if ($(this).attr("data_type") === 'float') {
                if (!escape_deletion_for_auto_comma(evt, $(this))) {
                    if (!AMKeycode._float(evt)) {
                        evt.preventDefault ? evt.preventDefault() : (evt.returnValue = false);
                        _keydown_msg(_get_data_type_msg($(this), "실수(부동소수점) 형식(음수 가능)으로 입력해 주세요."), $(this));
                    }
                }
            } else if ($(this).attr("data_type") === 'date') {
                if (!AMKeycode.date(evt)) {
                    evt.preventDefault ? evt.preventDefault() : (evt.returnValue = false);
                    _keydown_msg(_get_data_type_msg($(this), "'2014-01-01' 형식으로 입력해 주세요."), $(this));
                }
            } else if ($(this).attr("data_type") === 'time') {
                if (!AMKeycode.time(evt)) {
                    evt.preventDefault ? evt.preventDefault() : (evt.returnValue = false);
                    _keydown_msg(_get_data_type_msg($(this), "'11:23:10' 형식으로 입력해 주세요."), $(this));
                }
            } else if ($(this).attr("data_type") === 'datetime') {
                if (!AMKeycode.datetime(evt)) {
                    evt.preventDefault ? evt.preventDefault() : (evt.returnValue = false);
                    _keydown_msg(_get_data_type_msg($(this), "'2014-01-01 11:23:10' 형식으로 입력해 주세요."), $(this));
                }
            } else if ($(this).attr("data_type") === 'yearmonth') {
                if (!AMKeycode.yearmonth(evt)) {
                    evt.preventDefault ? evt.preventDefault() : (evt.returnValue = false);
                    _keydown_msg(_get_data_type_msg($(this), "'2014-01' 형식으로 입력해 주세요."), $(this));
                }
            } else if ($(this).attr("data_type") === 'ascii') {
                if (!AMKeycode.ascii(evt)) {
                    evt.preventDefault ? evt.preventDefault() : (evt.returnValue = false);
                    _keydown_msg(_get_data_type_msg($(this), "아스키코드(한글 불허)만 입력해 주세요."), $(this));
                }
            } else if ($(this).attr("data_type") === 'numeric') {
                if (!AMKeycode.numeric(evt)) {
                    evt.preventDefault ? evt.preventDefault() : (evt.returnValue = false);
                    _keydown_msg(_get_data_type_msg($(this), "Please enter numbers only."), $(this));
                }
            } else if ($(this).attr("data_type") === 'numeric_dash') {
                if (!AMKeycode.numeric_dash(evt)) {
                    evt.preventDefault ? evt.preventDefault() : (evt.returnValue = false);
                    _keydown_msg(_get_data_type_msg($(this), "숫자, - 만 입력해 주세요."), $(this));
                }
            } else if ($(this).attr("data_type") === 'alphabet') {
                if (!AMKeycode.alphabet(evt)) {
                    evt.preventDefault ? evt.preventDefault() : (evt.returnValue = false);
                    _keydown_msg(_get_data_type_msg($(this), "알파벳만 입력해 주세요."), $(this));
                }
            } else if ($(this).attr("data_type") === 'alpha_numeric') {
                if (!AMKeycode.alpha_numeric(evt)) {
                    evt.preventDefault ? evt.preventDefault() : (evt.returnValue = false);
                    _keydown_msg(_get_data_type_msg($(this), "알파벳이나 숫자만 입력해 주세요."), $(this));
                }
            } else if ($(this).attr("data_type") === 'alpha_numeric_2') {
                if (!AMKeycode.alpha_numeric_2(evt)) {
                    evt.preventDefault ? evt.preventDefault() : (evt.returnValue = false);
                    _keydown_msg(_get_data_type_msg($(this), "알파벳, 숫자, -, _만 입력해 주세요."), $(this));
                }
            } else if ($(this).attr("data_type") === 'alpha_numeric_3') {
                if (!AMKeycode.alpha_numeric_3(evt)) {
                    evt.preventDefault ? evt.preventDefault() : (evt.returnValue = false);
                    _keydown_msg(_get_data_type_msg($(this), "알파벳, 숫자, -, _, 콤마(,)만 입력해 주세요."), $(this));
                }
            } else if ($(this).attr("data_type") === 'alpha_numeric_2_dot') {
                if (!AMKeycode.alpha_numeric_2_dot(evt)) {
                    evt.preventDefault ? evt.preventDefault() : (evt.returnValue = false);
                    _keydown_msg(_get_data_type_msg($(this), "알파벳, 숫자, -, _, 점(.)만 입력해 주세요."), $(this));
                }
            } else if ($(this).attr("data_type") === 'alpha_numeric_2_slash') {
                if (!AMKeycode.alpha_numeric_2_slash(evt)) {
                    evt.preventDefault ? evt.preventDefault() : (evt.returnValue = false);
                    _keydown_msg(_get_data_type_msg($(this), "알파벳, 숫자, -, _, / 만 입력해 주세요."), $(this));
                }
            } else if ($(this).attr("data_type") === 'uppercase') {
                if (!AMKeycode.uppercase(evt)) {
                    evt.preventDefault ? evt.preventDefault() : (evt.returnValue = false);
                    _keydown_msg(_get_data_type_msg($(this), "대문자만 입력해 주세요."), $(this));
                }
            } else if ($(this).attr("data_type") === 'uppercase_space') {
                if (!AMKeycode.uppercase_space(evt)) {
                    evt.preventDefault ? evt.preventDefault() : (evt.returnValue = false);
                    _keydown_msg(_get_data_type_msg($(this), "대문자나 공백문자만 입력해 주세요."), $(this));
                }
            } else if ($(this).attr("data_type") === 'uppercase_numeric') {
                if (!AMKeycode.uppercase_numeric(evt)) {
                    evt.preventDefault ? evt.preventDefault() : (evt.returnValue = false);
                    _keydown_msg(_get_data_type_msg($(this), "대문자나 숫자만 입력해 주세요."), $(this));
                }
            } else if ($(this).attr("data_type") === 'lowercase') {
                if (!AMKeycode.lowercase(evt)) {
                    evt.preventDefault ? evt.preventDefault() : (evt.returnValue = false);
                    _keydown_msg(_get_data_type_msg($(this), "소문자만 입력해 주세요."), $(this));
                }
            } else if ($(this).attr("data_type") === 'lowercase_numeric') {
                if (!AMKeycode.lowercase_numeric(evt)) {
                    evt.preventDefault ? evt.preventDefault() : (evt.returnValue = false);
                    _keydown_msg(_get_data_type_msg($(this), "소문자나 숫자만 입력해 주세요."), $(this));
                }
            } else if ($(this).attr("data_type") === 'lowercase_numeric_2') {
                if (!AMKeycode.lowercase_numeric_2(evt)) {
                    evt.preventDefault ? evt.preventDefault() : (evt.returnValue = false);
                    _keydown_msg(_get_data_type_msg($(this), "소문자, 숫자, -, _만 입력해 주세요."), $(this));
                }
            } else if ($(this).attr("data_type") === 'lowercase_numeric_3') {
                if (!AMKeycode.lowercase_numeric_3(evt)) {
                    evt.preventDefault ? evt.preventDefault() : (evt.returnValue = false);
                    _keydown_msg(_get_data_type_msg($(this), "소문자, 숫자, -, _, 콤마(,)만 입력해 주세요."), $(this));
                }
            } else if ($(this).attr("data_type") === 'lowercase_numeric_2_dot') {
                if (!AMKeycode.lowercase_numeric_2_dot(evt)) {
                    evt.preventDefault ? evt.preventDefault() : (evt.returnValue = false);
                    _keydown_msg(_get_data_type_msg($(this), "소문자, 숫자, -, _, 점(.)만 입력해 주세요."), $(this));
                }
            } else if ($(this).attr("data_type") === 'lowercase_numeric_2_slash') {
                if (!AMKeycode.lowercase_numeric_2_slash(evt)) {
                    evt.preventDefault ? evt.preventDefault() : (evt.returnValue = false);
                    _keydown_msg(_get_data_type_msg($(this), "소문자, 숫자, -, _, / 만 입력해 주세요."), $(this));
                }
            } else if ($(this).attr("data_type") === 'email_address') {
                if (!AMKeycode.email_address(evt)) {
                    evt.preventDefault ? evt.preventDefault() : (evt.returnValue = false);
                    _keydown_msg(_get_data_type_msg($(this), "Please enter a valid email address."), $(this));
                }
            } else if ($(this).attr("data_type") === 'email_address_rfc') {
                if (!AMKeycode.email_address_rfc(evt)) {
                    evt.preventDefault ? evt.preventDefault() : (evt.returnValue = false);
                    _keydown_msg(_get_data_type_msg($(this), "이메일주소를 올바로 입력해 주세요."), $(this));
                }
            } else if ($(this).attr("data_type") === 'account_general') {
                if (!AMKeycode.account_general(evt)) {
                    evt.preventDefault ? evt.preventDefault() : (evt.returnValue = false);
                    _keydown_msg(_get_data_type_msg($(this), "계정을 올바로 입력해 주세요."), $(this));
                }
            } else if ($(this).attr("data_type") === 'account_login_service') {
                if (!AMKeycode.account_login_service(evt)) {
                    evt.preventDefault ? evt.preventDefault() : (evt.returnValue = false);
                    _keydown_msg(_get_data_type_msg($(this), "외부 계정을 올바로 입력해 주세요."), $(this));
                }
            } else if ($(this).attr("data_type") === 'account_email_or_login_service') {
                if (!AMKeycode.account_email_or_login_service(evt)) {
                    evt.preventDefault ? evt.preventDefault() : (evt.returnValue = false);
                    _keydown_msg(_get_data_type_msg($(this), "이메일계정 또는 외부 계정을 올바로 입력해 주세요."), $(this));
                }
            } else if ($(this).attr("data_type") === 'domain') {
                if (!AMKeycode.domain(evt)) {
                    evt.preventDefault ? evt.preventDefault() : (evt.returnValue = false);
                    _keydown_msg(_get_data_type_msg($(this), "도메인을 올바로 입력해 주세요."), $(this));
                }
            } else if ($(this).attr("data_type") === 'ip_address') {
                if (!AMKeycode.ip_address(evt)) {
                    evt.preventDefault ? evt.preventDefault() : (evt.returnValue = false);
                    _keydown_msg(_get_data_type_msg($(this), "IP주소를 올바로 입력해 주세요."), $(this));
                }
            } else if ($(this).attr("data_type") === 'ip_address_filter') {
                if (!AMKeycode.ip_address_filter(evt)) {
                    evt.preventDefault ? evt.preventDefault() : (evt.returnValue = false);
                    _keydown_msg(_get_data_type_msg($(this), "IP주소 또는 IP주소 필터값을 올바로 입력해 주세요."), $(this));
                }
            } else if ($(this).attr("data_type") === 'filename') {
                if (!AMKeycode.filename(evt)) {
                    evt.preventDefault ? evt.preventDefault() : (evt.returnValue = false);
                    _keydown_msg(_get_data_type_msg($(this), "파일명으로 가능한 문자만 입력해 주세요.\n불허 문자: \\ / : * ? \" < > |"), $(this));
                }
            } else if ($(this).attr("data_type") === 'strict_file_path') {
                if (!AMKeycode.strict_file_path(evt)) {
                    evt.preventDefault ? evt.preventDefault() : (evt.returnValue = false);
                    _keydown_msg(_get_data_type_msg($(this), "경로를 올바로 입력해 주세요.\n허용문자: 숫자,영문,-,_,/,."), $(this));
                }
            } else if ($(this).attr("data_type") === 'simple_url') {
                if (!AMKeycode.simple_url(evt)) {
                    evt.preventDefault ? evt.preventDefault() : (evt.returnValue = false);
                    _keydown_msg(_get_data_type_msg($(this), "URL 주소를 올바로 입력해 주세요."), $(this));
                }
            } else if ($(this).attr("data_type") === 'standard_url') {
                if (!AMKeycode.standard_url(evt)) {
                    evt.preventDefault ? evt.preventDefault() : (evt.returnValue = false);
                    _keydown_msg(_get_data_type_msg($(this), "URL 주소를 올바로 입력해 주세요."), $(this));
                }
            } else if ($(this).attr("data_type") === 'general_url') {
                if (!AMKeycode.general_url(evt)) {
                    evt.preventDefault ? evt.preventDefault() : (evt.returnValue = false);
                    _keydown_msg(_get_data_type_msg($(this), "URL 주소를 올바로 입력해 주세요."), $(this));
                }
            } else if ($(this).attr("data_type") === 'hex_color') {
                if (!AMKeycode.hex_color(evt)) {
                    evt.preventDefault ? evt.preventDefault() : (evt.returnValue = false);
                    _keydown_msg(_get_data_type_msg($(this), "16진수 형식 색상 값을 올바로 입력해 주세요."), $(this));
                }
            } else if ($(this).attr("data_type") === 'color_picker') {
                if (!AMKeycode.color_picker(evt)) {
                    evt.preventDefault ? evt.preventDefault() : (evt.returnValue = false);
                    _keydown_msg(_get_data_type_msg($(this), "16진수 형식 색상 값을 올바로 입력해 주세요."), $(this));
                }
            } else if ($(this).attr("data_type") === 'html_tag') {
                if (!AMKeycode.html_tag(evt)) {
                    evt.preventDefault ? evt.preventDefault() : (evt.returnValue = false);
                    _keydown_msg(_get_data_type_msg($(this), "HTML 태그를 올바로 입력해 주세요."), $(this));
                }
            } else if ($(this).attr("data_type") === 'phone_num') {
                if (!AMKeycode.phone_num(evt)) {
                    evt.preventDefault ? evt.preventDefault() : (evt.returnValue = false);
                    _keydown_msg(_get_data_type_msg($(this), "핸드폰번호를 올바로 입력해 주세요."), $(this));
                }
            } else if ($(this).attr("data_type") === 'phone_num_dash') {
                if (!AMKeycode.phone_num_dash(evt)) {
                    evt.preventDefault ? evt.preventDefault() : (evt.returnValue = false);
                    _keydown_msg(_get_data_type_msg($(this), "핸드폰번호(- 필수)를 올바로 입력해 주세요."), $(this));
                }
            } else if ($(this).attr("data_type") === 'phone_num_no_dash') {
                if (!AMKeycode.phone_num_no_dash(evt)) {
                    evt.preventDefault ? evt.preventDefault() : (evt.returnValue = false);
                    _keydown_msg(_get_data_type_msg($(this), "핸드폰번호(- 사용불가)를 올바로 입력해 주세요."), $(this));
                }
            } else if ($(this).attr("data_type") === 'phone_num_global') {
                if (!AMKeycode.phone_num_global(evt)) {
                    evt.preventDefault ? evt.preventDefault() : (evt.returnValue = false);
                    _keydown_msg(_get_data_type_msg($(this), "핸드폰번호(국제전화번호 포함)를 올바로 입력해 주세요."), $(this));
                }
            } else if ($(this).attr("data_type") === 'telephone') {
                if (!AMKeycode.telephone(evt)) {
                    evt.preventDefault ? evt.preventDefault() : (evt.returnValue = false);
                    _keydown_msg(_get_data_type_msg($(this), "일반 전화를 올바로 입력해 주세요."), $(this));
                }
            } else if ($(this).attr("data_type") === 'telephone_dash') {
                if (!AMKeycode.telephone(evt)) {
                    evt.preventDefault ? evt.preventDefault() : (evt.returnValue = false);
                    _keydown_msg(_get_data_type_msg($(this), "전화번호(- 필수)를 올바로 입력해 주세요."), $(this));
                }
            } else if ($(this).attr("data_type") === 'auth_num') {
                if (!AMKeycode.auth_num(evt)) {
                    evt.preventDefault ? evt.preventDefault() : (evt.returnValue = false);
                    _keydown_msg(_get_data_type_msg($(this), "6자리 숫자를 올바로 입력해 주세요."), $(this));
                }
            } else if ($(this).attr("data_type") === 'birthday6') {
                if (!AMKeycode.birthday6(evt)) {
                    evt.preventDefault ? evt.preventDefault() : (evt.returnValue = false);
                    _keydown_msg(_get_data_type_msg($(this), "생년월일 형식(6자리 숫자)으로 입력해 주세요."), $(this));
                }
            } else if ($(this).attr("data_type") === 'birthday8') {
                if (!AMKeycode.birthday8(evt)) {
                    evt.preventDefault ? evt.preventDefault() : (evt.returnValue = false);
                    _keydown_msg(_get_data_type_msg($(this), "생년월일 형식(8자리 숫자)으로 입력해 주세요."), $(this));
                }
            } else if ($(this).attr("data_type") === 'app_version') {
                if (!AMKeycode.app_version(evt)) {
                    evt.preventDefault ? evt.preventDefault() : (evt.returnValue = false);
                    _keydown_msg(_get_data_type_msg($(this), "앱 버전 형식(xx.yy.zz)으로 입력해 주세요."), $(this));
                }
            } else if ($(this).attr("data_type") === 'corporate_registration_number') {
                if (!AMKeycode.corporate_registration_number(evt)) {
                    evt.preventDefault ? evt.preventDefault() : (evt.returnValue = false);
                    _keydown_msg(_get_data_type_msg($(this), "사업자등록번호 형식(10자리 숫자)으로 입력해 주세요."), $(this));
                }
            }
            // sequential_word 생략
            else if ($(this).attr("data_type") === 'no_sequential_word') {
                //if( !AMKeycode.no_sequential_word(evt) )
                //{
                //	evt.preventDefault ? evt.preventDefault() : (evt.returnValue = false);
                //	_keydown_msg( _get_data_type_msg($(this),"비밀번호를 올바로 입력해 주세요.\n(4자 이상의 연속된 숫자나 문자 불허)"), $(this));
                //}
            } else if ($(this).attr("data_type") === 'hangul') {
                if (!AMKeycode.hangul(evt)) {
                    evt.preventDefault ? evt.preventDefault() : (evt.returnValue = false);
                    _keydown_msg(_get_data_type_msg($(this), "한글만을 입력해 주세요."), $(this));
                }
            }
                // no_abnormal_hangul 생략
                // password_an6 생략
                // password_ans8 생략
                // password_ans10 생략
                // user_password 생략
            // worker_password 생략
            else if ($(this).attr("data_type") === 'worker_id') {
                if (!AMKeycode.worker_id(evt)) {
                    evt.preventDefault ? evt.preventDefault() : (evt.returnValue = false);
                    _keydown_msg(_get_data_type_msg($(this), "작업자 ID를 올바로 입력해 주세요.\n허용문자: 영문 대소문자 시작, 숫자, _, 점(.) 사용 가능, 5~30자, 또는 이메일계정 형식"), $(this));
                }
            } else if ($(this).attr("data_type") === 'worker_id4') {
                if (!AMKeycode.worker_id4(evt)) {
                    evt.preventDefault ? evt.preventDefault() : (evt.returnValue = false);
                    _keydown_msg(_get_data_type_msg($(this), "작업자 ID를 올바로 입력해 주세요.\n허용문자: 영문 대소문자 시작, 숫자, _, 점(.) 사용 가능, 4~30자, 또는 이메일계정 형식"), $(this));
                }
            } else if ($(this).attr("data_type") === 'numeric_list') {
                if (!AMKeycode.numeric_list(evt)) {
                    evt.preventDefault ? evt.preventDefault() : (evt.returnValue = false);
                    _keydown_msg(_get_data_type_msg($(this), "숫자,숫자,숫자,... 형태로 입력해 주세요.(숫자만 입력도 가능)"), $(this));
                }
            } else if ($(this).attr("data_type") === 'alpha_numeric_list') {
                if (!AMKeycode.alpha_numeric_list(evt)) {
                    evt.preventDefault ? evt.preventDefault() : (evt.returnValue = false);
                    _keydown_msg(_get_data_type_msg($(this), "알파벳or숫자,알파벳or숫자,알파벳or숫자,... 형태로 입력해 주세요.(알파벳or숫자만 입력도 가능)"), $(this));
                }
            } else if ($(this).attr("data_type") === 'hangul_alpha_numeric') {
                if (!AMKeycode.hangul_alpha_numeric(evt)) {
                    evt.preventDefault ? evt.preventDefault() : (evt.returnValue = false);
                    _keydown_msg(_get_data_type_msg($(this), "한글, 알파벳, 숫자만 입력해 주세요."), $(this));
                }
            } else if ($(this).attr("data_type") === 'user_name') {
                if (!AMKeycode.user_name(evt)) {
                    evt.preventDefault ? evt.preventDefault() : (evt.returnValue = false);
                    _keydown_msg(_get_data_type_msg($(this), "Please enter only Korean, alphabet, and space characters."), $(this));
                }
            } else if ($(this).attr("data_type") === 'hangul_alpha_numeric_dash_space') {
                if (!AMKeycode.hangul_alpha_numeric_dash_space(evt)) {
                    evt.preventDefault ? evt.preventDefault() : (evt.returnValue = false);
                    _keydown_msg(_get_data_type_msg($(this), "한글, 알파벳, 숫자, -, 공백문자만 입력해 주세요."), $(this));
                }
            } else if ($(this).attr("data_type") === 'numeric_range') {
                if (!AMKeycode.numeric_range(evt)) {
                    evt.preventDefault ? evt.preventDefault() : (evt.returnValue = false);
                    _keydown_msg(_get_data_type_msg($(this), "숫자 범위 형식(xx-yy)으로 입력해 주세요."), $(this));
                }
            } else if ($(this).attr("data_type") === 'numeric_comma') {
                if (!AMKeycode.numeric_comma(evt)) {
                    evt.preventDefault ? evt.preventDefault() : (evt.returnValue = false);
                    _keydown_msg(_get_data_type_msg($(this), "Please enter numbers only."), $(this));
                }
            } else if ($(this).attr("data_type") === 'address_user_input') {
                if (!AMKeycode.address_user_input(evt)) {
                    evt.preventDefault ? evt.preventDefault() : (evt.returnValue = false);
                    _keydown_msg(_get_data_type_msg($(this), "주소 형식만 입력해 주세요."), $(this));
                }
            } else if (AMString.startsWith($(this).attr("data_type"), '{')) {
                let objDataType = JSON.parse($(this).attr("data_type"));
                if (Array.isArray(objDataType.keydown_filter) && objDataType.keydown_filter.length > 0) {
                    if (!AMKeycode.general(evt, objDataType.keydown_filter)) {
                        evt.preventDefault ? evt.preventDefault() : (evt.returnValue = false);
                        _keydown_msg(objDataType.error_msg, $(this));
                    }
                }
            }
        });
    }

    function _remove_dot_for_integer(objInput) {
        let toast_msg = '';

        // 111.00 에서 .00은 제거
        let dot_index = objInput.val().indexOf(".");
        if (dot_index > -1) {
            let new_val = objInput.val().substr(0, dot_index);
            objInput.val(new_val);
            toast_msg = "입력 값에 포함된 소수점(.)과 이후 문자들을 제거했습니다.";
        }

        if (typeof AMControl !== 'undefined' && toast_msg !== "") {
            let objJson = {
                "msg": toast_msg,
                "duration": "1",
                "base_object": objInput,
                "position": "up"
            };
            AMControl.toast(objJson);
        }
    }

    function _remove_comma_dot_for_integer(objInput) {
        let toast_msg = '';

        // 콤마(,)는 메시지를 표시하지 않고 그냥 제거한다.
        let comma_index = objInput.val().indexOf(",");
        if (comma_index > -1) {
            objInput.val(objInput.val().replace(/[,]/g, ""));
            toast_msg += "입력 값에 포함된 콤마(,)를 제거했습니다.";
        }

        // 111.00 에서 .00은 제거
        let dot_index = objInput.val().indexOf(".");
        if (dot_index > -1) {
            let new_val = objInput.val().substr(0, dot_index);
            objInput.val(new_val);
            if (toast_msg !== "")
                toast_msg += "\n";
            toast_msg += "입력 값에 포함된 소수점(.)과 이후 문자들을 제거했습니다.";
        }

        if (typeof AMControl !== 'undefined' && toast_msg !== "") {
            let objJson = {
                "msg": toast_msg,
                "duration": "1",
                "base_object": objInput,
                "position": "up"
            };
            AMControl.toast(objJson);
        }
    }

    function _remove_comma_for_float(objInput) {
        let toast_msg = '';

        // 콤마(,)는 메시지를 표시하지 않고 그냥 제거한다.
        let comma_index = objInput.val().indexOf(",");
        if (comma_index > -1) {
            objInput.val(objInput.val().replace(/[,]/g, ""));
            toast_msg += "입력 값에 포함된 콤마(,)를 제거했습니다.";
        }

        if (typeof AMControl !== 'undefined' && toast_msg !== "") {
            let objJson = {
                "msg": toast_msg,
                "duration": "1",
                "base_object": objInput,
                "position": "up"
            };
            AMControl.toast(objJson);
        }
    }

    // 키보드 입력시 값체크
    function initKeyUpChecker(objInput) {
        if (objInput instanceof jQuery) {
            if (!objInput.is("[data_type]"))
                objInput = $("[data_type]");
        } else {
            objInput = $("[data_type]");
        }

        //objInput.keyup(function(evt){
        objInput.on('keyup', function (evt) {
            check_input_data($(this), evt);
        });

        //objInput.keyup(function(evt){
        objInput.on('paste', function (evt) {
            let self = $(this);
            setTimeout(function () {
                check_input_data(self, evt);
            }, 1);
        });
        // To Do: 붙여넣기할 때 데이터 자체를 걸러내려면 이 코드를 구현해야 한다.
        //objInput.on("paste", function(e){
        //	// access the clipboard using the api
        //	let pastedData = e.originalEvent.clipboardData.getData('text');
        //	let pastedData = window.clipboardData.getData('text');
        //	alert(pastedData);
        //});
    }

    // 자동으로 , 를 추가한다.
    function _auto_comma(thisObj) {
        let source = thisObj.val();
        let target = AMString.float_format(source);
        if (source !== target) {
            let source_comma_count = (source.match(/,/g) || []).length;
            let target_comma_count = (target.match(/,/g) || []).length;

            let input = thisObj;
            let start = input[0].selectionStart;
            thisObj.val(target);

            if (source_comma_count === target_comma_count)
                input[0].selectionStart = input[0].selectionEnd = start;
            else if (source_comma_count < target_comma_count)
                input[0].selectionStart = input[0].selectionEnd = start + 1;
            else if (source_comma_count > target_comma_count)
                input[0].selectionStart = input[0].selectionEnd = Math.max(0, start - 1);
        }
    }

    function check_input_data(thisObj, evt) {
        if (thisObj.attr("data_type") === 'integer_positive') {
            if (thisObj.attr("auto_comma") === 'true')
                _remove_dot_for_integer(thisObj);		// . 제거 및 toast 메시지 표시
            else
                _remove_comma_dot_for_integer(thisObj);	// , . 제거 및 toast 메시지 표시

            let regx_integer_positive = (thisObj.attr("auto_comma") === 'true') ? /^[0-9,]*$/ : /^[0-9]*$/;
            if (!regx_integer_positive.test(thisObj.val())) {
                let input = thisObj;
                let start = input[0].selectionStart;

                if (thisObj.attr("auto_comma") === 'true')
                    thisObj.val(thisObj.val().replace(/[^0-9,]/g, ""));
                else
                    thisObj.val(thisObj.val().replace(/[^0-9]/g, ""));

                _keyup_msg(_get_data_type_msg(thisObj, "양의 정수만 입력해 주세요."), thisObj);

                input[0].selectionStart = input[0].selectionEnd = start;
            } else {
                if (thisObj.attr("auto_comma") === 'true')
                    _auto_comma(thisObj);
            }
        } else if (thisObj.attr("data_type") === 'integer_zero_positive') {
            if (thisObj.attr("auto_comma") === 'true')
                _remove_dot_for_integer(thisObj);		// . 제거 및 toast 메시지 표시
            else
                _remove_comma_dot_for_integer(thisObj);	// , . 제거 및 toast 메시지 표시

            let regx_integer_zero_positive = (thisObj.attr("auto_comma") === 'true') ? /^[0-9,]*$/ : /^[0-9]*$/;
            if (!regx_integer_zero_positive.test(thisObj.val())) {
                let input = thisObj;
                let start = input[0].selectionStart;

                if (thisObj.attr("auto_comma") === 'true')
                    thisObj.val(thisObj.val().replace(/[^0-9,]/g, ""));
                else
                    thisObj.val(thisObj.val().replace(/[^0-9]/g, ""));

                _keyup_msg(_get_data_type_msg(thisObj, "0보다 크거나 같은 정수만 입력해 주세요."), thisObj);

                input[0].selectionStart = input[0].selectionEnd = start;
            } else {
                if (thisObj.attr("auto_comma") === 'true')
                    _auto_comma(thisObj);
            }
        } else if (thisObj.attr("data_type") === 'integer') {
            if (thisObj.attr("auto_comma") === 'true')
                _remove_dot_for_integer(thisObj);		// . 제거 및 toast 메시지 표시
            else
                _remove_comma_dot_for_integer(thisObj);	// , . 제거 및 toast 메시지 표시

            let regx_integer = (thisObj.attr("auto_comma") === 'true') ? /^(-?)[0-9,]*$/ : /^(-?)[0-9]*$/;
            if (!regx_integer.test(thisObj.val())) {
                let input = thisObj;
                let start = input[0].selectionStart;

                if (thisObj.attr("auto_comma") === 'true')
                    thisObj.val(thisObj.val().replace(/[^0-9-,]/g, ""));
                else
                    thisObj.val(thisObj.val().replace(/[^0-9-]/g, ""));

                _keyup_msg(_get_data_type_msg(thisObj, "정수(음수 가능)만 입력해 주세요."), thisObj);

                input[0].selectionStart = input[0].selectionEnd = start;
            } else {
                if (thisObj.attr("auto_comma") === 'true')
                    _auto_comma(thisObj);
            }
        } else if (thisObj.attr("data_type") === 'float_positive') {
            if (thisObj.attr("auto_comma") !== 'true')
                _remove_comma_for_float(thisObj);	// , 제거 및 toast 메시지 표시

            let regx_float_positive = (thisObj.attr("auto_comma") === 'true') ? /^[0-9.,]*$/ : /^[0-9.]*$/;
            if (!regx_float_positive.test(thisObj.val())) {
                let input = thisObj;
                let start = input[0].selectionStart;

                if (thisObj.attr("auto_comma") === 'true')
                    thisObj.val(thisObj.val().replace(/[^0-9.,]/g, ""));
                else
                    thisObj.val(thisObj.val().replace(/[^0-9.]/g, ""));

                _keyup_msg(_get_data_type_msg(thisObj, "양의 실수(부동소수점) 형식(정수 형식 가능)으로 입력해 주세요."), thisObj);

                input[0].selectionStart = input[0].selectionEnd = start;
            } else {
                if (thisObj.attr("auto_comma") === 'true')
                    _auto_comma(thisObj);
            }
        } else if (thisObj.attr("data_type") === 'float_zero_positive') {
            if (thisObj.attr("auto_comma") !== 'true')
                _remove_comma_for_float(thisObj);	// , 제거 및 toast 메시지 표시

            let regx_float_zero_positive = (thisObj.attr("auto_comma") === 'true') ? /^[0-9.,]*$/ : /^[0-9.]*$/;
            if (!regx_float_zero_positive.test(thisObj.val())) {
                let input = thisObj;
                let start = input[0].selectionStart;

                if (thisObj.attr("auto_comma") === 'true')
                    thisObj.val(thisObj.val().replace(/[^0-9.,]/g, ""));
                else
                    thisObj.val(thisObj.val().replace(/[^0-9.]/g, ""));

                _keyup_msg(_get_data_type_msg(thisObj, "0보다 크거나 같은 실수(부동소수점) 형식(정수 형식 가능)으로 입력해 주세요."), thisObj);

                input[0].selectionStart = input[0].selectionEnd = start;
            } else {
                if (thisObj.attr("auto_comma") === 'true')
                    _auto_comma(thisObj);
            }
        } else if (thisObj.attr("data_type") === 'float') {
            if (thisObj.attr("auto_comma") !== 'true')
                _remove_comma_for_float(thisObj);	// , 제거 및 toast 메시지 표시

            let regx_float = (thisObj.attr("auto_comma") === 'true') ? /^[+-]?[0-9.,]*$/ : /^[+-]?[0-9.]*$/;
            if (!regx_float.test(thisObj.val())) {
                let input = thisObj;
                let start = input[0].selectionStart;

                if (thisObj.attr("auto_comma") === 'true')
                    thisObj.val(thisObj.val().replace(/[^0-9+-.,]/g, ""));
                else
                    thisObj.val(thisObj.val().replace(/[^0-9+-.]/g, ""));

                _keyup_msg(_get_data_type_msg(thisObj, "실수(부동소수점) 형식(음수 가능)으로 입력해 주세요."), thisObj);

                input[0].selectionStart = input[0].selectionEnd = start;
            } else {
                if (thisObj.attr("auto_comma") === 'true')
                    _auto_comma(thisObj);
            }
        } else if (thisObj.attr("data_type") === 'date') {
            //let regx_date = /^[0-9-]{0,10}$/;
            let regx_date = /^[0-9-]*$/;
            if (!regx_date.test(thisObj.val())) {
                let input = thisObj;
                let start = input[0].selectionStart;

                thisObj.val(thisObj.val().replace(/([^0-9-]*)/g, ""));

                _keyup_msg(_get_data_type_msg(thisObj, "'2014-01-01' 형식으로 입력해 주세요."), thisObj);

                input[0].selectionStart = input[0].selectionEnd = start;
            }
        } else if (thisObj.attr("data_type") === 'time') {
            //let regx_time = /^[0-9:]{0,8}$/;
            let regx_time = /^[0-9:]*$/;
            if (!regx_time.test(thisObj.val())) {
                let input = thisObj;
                let start = input[0].selectionStart;

                thisObj.val(thisObj.val().replace(/([^0-9:])/g, ""));

                _keyup_msg(_get_data_type_msg(thisObj, "'11:23:10' 형식으로 입력해 주세요."), thisObj);

                input[0].selectionStart = input[0].selectionEnd = start;
            }
        } else if (thisObj.attr("data_type") === 'datetime') {
            //let regx_datetime = /^[0-9-:\s]{0,19}$/;
            let regx_datetime = /^[0-9-:\s]*$/;
            if (!regx_datetime.test(thisObj.val())) {
                let input = thisObj;
                let start = input[0].selectionStart;

                thisObj.val(thisObj.val().replace(/([^0-9-:\s]*)/g, ""));

                _keyup_msg(_get_data_type_msg(thisObj, "'2014-01-01 11:23:10' 형식으로 입력해 주세요."), thisObj);

                input[0].selectionStart = input[0].selectionEnd = start;
            }
        } else if (thisObj.attr("data_type") === 'yearmonth') {
            //let regx_yearmonth = /^[0-9-]{0,7}$/;
            let regx_yearmonth = /^[0-9-]*$/;
            if (!regx_yearmonth.test(thisObj.val())) {
                let input = thisObj;
                let start = input[0].selectionStart;

                thisObj.val(thisObj.val().replace(/([^0-9-]*)/g, ""));

                _keyup_msg(_get_data_type_msg(thisObj, "'2014-01' 형식으로 입력해 주세요."), thisObj);

                input[0].selectionStart = input[0].selectionEnd = start;
            }
        } else if (thisObj.attr("data_type") === 'ascii') {
            let regx_ascii = /^([\x20-\x7E]*)$/;
            if (!regx_ascii.test(thisObj.val())) {
                let input = thisObj;
                let start = input[0].selectionStart;

                thisObj.val(thisObj.val().replace(/([^\x20-\x7E]*)/g, ""));

                _keyup_msg(_get_data_type_msg(thisObj, "아스키코드(한글 불허)만 입력해 주세요."), thisObj);

                input[0].selectionStart = input[0].selectionEnd = start;
            }
        } else if (thisObj.attr("data_type") === 'numeric') {
            let regx_numeric = /^([0-9]*)$/;
            if (!regx_numeric.test(thisObj.val())) {
                let input = thisObj;
                let start = input[0].selectionStart;

                thisObj.val(thisObj.val().replace(/([^0-9]*)/g, ""));

                _keyup_msg(_get_data_type_msg(thisObj, "숫자만 입력해 주세요."), thisObj);

                input[0].selectionStart = input[0].selectionEnd = start;
            }
        } else if (thisObj.attr("data_type") === 'numeric_dash') {
            let regx_numeric_dash = /^([0-9-]*)$/;
            if (!regx_numeric_dash.test(thisObj.val())) {
                let input = thisObj;
                let start = input[0].selectionStart;

                thisObj.val(thisObj.val().replace(/([^0-9-]*)/g, ""));

                _keyup_msg(_get_data_type_msg(thisObj, "숫자, - 만 입력해 주세요."), thisObj);

                input[0].selectionStart = input[0].selectionEnd = start;
            }
        } else if (thisObj.attr("data_type") === 'alphabet') {
            let regx_alphabet = /^([a-zA-Z]*)$/;
            if (!regx_alphabet.test(thisObj.val())) {
                let input = thisObj;
                let start = input[0].selectionStart;

                thisObj.val(thisObj.val().replace(/([^a-zA-Z]*)/g, ""));

                _keyup_msg(_get_data_type_msg(thisObj, "알파벳만 입력해 주세요."), thisObj);

                input[0].selectionStart = input[0].selectionEnd = start;
            }
        } else if (thisObj.attr("data_type") === 'alpha_numeric') {
            let regx_alpha_numeric = /^([0-9a-zA-Z]*)$/;
            if (!regx_alpha_numeric.test(thisObj.val())) {
                let input = thisObj;
                let start = input[0].selectionStart;

                thisObj.val(thisObj.val().replace(/([^0-9a-zA-Z]*)/g, ""));

                _keyup_msg(_get_data_type_msg(thisObj, "알파벳이나 숫자만 입력해 주세요."), thisObj);

                input[0].selectionStart = input[0].selectionEnd = start;
            }
        } else if (thisObj.attr("data_type") === 'alpha_numeric_2') {
            let regx_alpha_numeric_2 = /^([-_0-9a-zA-Z]*)$/;
            if (!regx_alpha_numeric_2.test(thisObj.val())) {
                let input = thisObj;
                let start = input[0].selectionStart;

                thisObj.val(thisObj.val().replace(/([^-_0-9a-zA-Z]*)/g, ""));

                _keyup_msg(_get_data_type_msg(thisObj, "알파벳, 숫자, -, _만 입력해 주세요."), thisObj);

                input[0].selectionStart = input[0].selectionEnd = start;
            }
        } else if (thisObj.attr("data_type") === 'alpha_numeric_3') {
            let regx_alpha_numeric_3 = /^([-_,0-9a-zA-Z]*)$/;
            if (!regx_alpha_numeric_3.test(thisObj.val())) {
                let input = thisObj;
                let start = input[0].selectionStart;

                thisObj.val(thisObj.val().replace(/([^-_,0-9a-zA-Z]*)/g, ""));

                _keyup_msg(_get_data_type_msg(thisObj, "알파벳, 숫자, -, _, 콤마(,)만 입력해 주세요."), thisObj);

                input[0].selectionStart = input[0].selectionEnd = start;
            }
        } else if (thisObj.attr("data_type") === 'alpha_numeric_2_dot') {
            let regx_alpha_numeric_2_dot = /^([-_.0-9a-zA-Z]*)$/;
            if (!regx_alpha_numeric_2_dot.test(thisObj.val())) {
                let input = thisObj;
                let start = input[0].selectionStart;

                thisObj.val(thisObj.val().replace(/([^-_.0-9a-zA-Z]*)/g, ""));

                _keyup_msg(_get_data_type_msg(thisObj, "알파벳, 숫자, -, _, 점(.)만 입력해 주세요."), thisObj);

                input[0].selectionStart = input[0].selectionEnd = start;
            }
        } else if (thisObj.attr("data_type") === 'alpha_numeric_2_slash') {
            let regx_alpha_numeric_2_slash = /^([-_/0-9a-zA-Z]*)$/;
            if (!regx_alpha_numeric_2_slash.test(thisObj.val())) {
                let input = thisObj;
                let start = input[0].selectionStart;

                thisObj.val(thisObj.val().replace(/([^-_/0-9a-zA-Z]*)/g, ""));

                _keyup_msg(_get_data_type_msg(thisObj, "알파벳, 숫자, -, _, / 만 입력해 주세요."), thisObj);

                input[0].selectionStart = input[0].selectionEnd = start;
            }
        } else if (thisObj.attr("data_type") === 'uppercase') {
            //thisObj.val(thisObj.val().toUpperCase());
            let regx_uppercase = /^([A-Z]*)$/;
            if (!regx_uppercase.test(thisObj.val())) {
                let input = thisObj;
                let start = input[0].selectionStart;

                thisObj.val(thisObj.val().replace(/([^A-Z]*)/g, ""));

                _keyup_msg(_get_data_type_msg(thisObj, "대문자만 입력해 주세요."), thisObj);

                input[0].selectionStart = input[0].selectionEnd = start;
            }
        } else if (thisObj.attr("data_type") === 'uppercase_space') {
            //thisObj.val(thisObj.val().toUpperCase());
            let regx_uppercase_space = /^([A-Z\s]*)$/;
            if (!regx_uppercase_space.test(thisObj.val())) {
                let input = thisObj;
                let start = input[0].selectionStart;

                thisObj.val(thisObj.val().replace(/([^A-Z\s]*)/g, ""));

                _keyup_msg(_get_data_type_msg(thisObj, "대문자나 공백문자만 입력해 주세요."), thisObj);

                input[0].selectionStart = input[0].selectionEnd = start;
            }
        } else if (thisObj.attr("data_type") === 'uppercase_numeric') {
            //thisObj.val(thisObj.val().toUpperCase());
            let regx_uppercase_numeric = /^([0-9A-Z]*)$/;
            if (!regx_uppercase_numeric.test(thisObj.val())) {
                let input = thisObj;
                let start = input[0].selectionStart;

                thisObj.val(thisObj.val().replace(/([^0-9A-Z]*)/g, ""));

                _keyup_msg(_get_data_type_msg(thisObj, "대문자나 숫자만 입력해 주세요."), thisObj);

                input[0].selectionStart = input[0].selectionEnd = start;
            }
        } else if (thisObj.attr("data_type") === 'lowercase') {
            //thisObj.val(thisObj.val().toLowerCase());
            let regx_lowercase = /^([a-z]*)$/;
            if (!regx_lowercase.test(thisObj.val())) {
                let input = thisObj;
                let start = input[0].selectionStart;

                thisObj.val(thisObj.val().replace(/([^a-z]*)/g, ""));

                _keyup_msg(_get_data_type_msg(thisObj, "소문자만 입력해 주세요."), thisObj);

                input[0].selectionStart = input[0].selectionEnd = start;
            }
        } else if (thisObj.attr("data_type") === 'lowercase_numeric') {
            //thisObj.val(thisObj.val().toLowerCase());
            let regx_lowercase_numeric = /^([0-9a-z]*)$/;
            if (!regx_lowercase_numeric.test(thisObj.val())) {
                let input = thisObj;
                let start = input[0].selectionStart;

                thisObj.val(thisObj.val().replace(/([^0-9a-z]*)/g, ""));

                _keyup_msg(_get_data_type_msg(thisObj, "소문자나 숫자만 입력해 주세요."), thisObj);

                input[0].selectionStart = input[0].selectionEnd = start;
            }
        } else if (thisObj.attr("data_type") === 'lowercase_numeric_2') {
            let regx_lowercase_numeric_2 = /^([-_0-9a-z]*)$/;
            if (!regx_lowercase_numeric_2.test(thisObj.val())) {
                let input = thisObj;
                let start = input[0].selectionStart;

                thisObj.val(thisObj.val().replace(/([^-_0-9a-z]*)/g, ""));

                _keyup_msg(_get_data_type_msg(thisObj, "소문자, 숫자, -, _만 입력해 주세요."), thisObj);

                input[0].selectionStart = input[0].selectionEnd = start;
            }
        } else if (thisObj.attr("data_type") === 'lowercase_numeric_3') {
            let regx_lowercase_numeric_3 = /^([-_,0-9a-z]*)$/;
            if (!regx_lowercase_numeric_3.test(thisObj.val())) {
                let input = thisObj;
                let start = input[0].selectionStart;

                thisObj.val(thisObj.val().replace(/([^-_,0-9a-z]*)/g, ""));

                _keyup_msg(_get_data_type_msg(thisObj, "소문자, 숫자, -, _, 콤마(,)만 입력해 주세요."), thisObj);

                input[0].selectionStart = input[0].selectionEnd = start;
            }
        } else if (thisObj.attr("data_type") === 'lowercase_numeric_2_dot') {
            let regx_lowercase_numeric_2_dot = /^([-_.0-9a-z]*)$/;
            if (!regx_lowercase_numeric_2_dot.test(thisObj.val())) {
                let input = thisObj;
                let start = input[0].selectionStart;

                thisObj.val(thisObj.val().replace(/([^-_.0-9a-z]*)/g, ""));

                _keyup_msg(_get_data_type_msg(thisObj, "소문자, 숫자, -, _, 점(.)만 입력해 주세요."), thisObj);

                input[0].selectionStart = input[0].selectionEnd = start;
            }
        } else if (thisObj.attr("data_type") === 'lowercase_numeric_2_slash') {
            let regx_lowercase_numeric_2_slash = /^([-_/0-9a-z]*)$/;
            if (!regx_lowercase_numeric_2_slash.test(thisObj.val())) {
                let input = thisObj;
                let start = input[0].selectionStart;

                thisObj.val(thisObj.val().replace(/([^-_/0-9a-z]*)/g, ""));

                _keyup_msg(_get_data_type_msg(thisObj, "소문자, 숫자, -, _, / 만 입력해 주세요."), thisObj);

                input[0].selectionStart = input[0].selectionEnd = start;
            }
        } else if (thisObj.attr("data_type") === 'email_address') {
            let regx_email_address = /^([.0-9a-zA-Z_+-@]*)$/;
            if (!regx_email_address.test(thisObj.val())) {
                let input = thisObj;
                let start = input[0].selectionStart;

                thisObj.val(thisObj.val().replace(/([^.0-9a-zA-Z_+-@]*)/g, ""));

                _keyup_msg(_get_data_type_msg(thisObj, "이메일주소를 올바로 입력해 주세요."), thisObj);

                input[0].selectionStart = input[0].selectionEnd = start;
            }
        }
        // email_address_rfc 생략
        else if (thisObj.attr("data_type") === 'account_general') {
            let regx_account_general = /^([.0-9a-zA-Z_+-@]*)$/;
            if (!regx_account_general.test(thisObj.val())) {
                let input = thisObj;
                let start = input[0].selectionStart;

                thisObj.val(thisObj.val().replace(/([^.0-9a-zA-Z_+-@]*)/g, ""));

                _keyup_msg(_get_data_type_msg(thisObj, "계정을 올바로 입력해 주세요."), thisObj);

                input[0].selectionStart = input[0].selectionEnd = start;
            }
        } else if (thisObj.attr("data_type") === 'account_login_service') {
            let regx_account_login_service = /^([.0-9a-zA-Z_+-@]*)$/;
            if (!regx_account_login_service.test(thisObj.val())) {
                let input = thisObj;
                let start = input[0].selectionStart;

                thisObj.val(thisObj.val().replace(/([^.0-9a-zA-Z_+-@]*)/g, ""));

                _keyup_msg(_get_data_type_msg(thisObj, "외부 계정을 올바로 입력해 주세요."), thisObj);

                input[0].selectionStart = input[0].selectionEnd = start;
            }
        } else if (thisObj.attr("data_type") === 'account_email_or_login_service') {
            let regx_account_email_or_login_service = /^([.0-9a-zA-Z_+-@]*)$/;
            if (!regx_account_email_or_login_service.test(thisObj.val())) {
                let input = thisObj;
                let start = input[0].selectionStart;

                thisObj.val(thisObj.val().replace(/([^.0-9a-zA-Z_+-@]*)/g, ""));

                _keyup_msg(_get_data_type_msg(thisObj, "이메일계정 또는 외부 계정을 올바로 입력해 주세요."), thisObj);

                input[0].selectionStart = input[0].selectionEnd = start;
            }
        } else if (thisObj.attr("data_type") === 'domain') {
            let regx_domain = /^([.0-9a-zA-Z-@]*)$/;
            if (!regx_domain.test(thisObj.val())) {
                let input = thisObj;
                let start = input[0].selectionStart;

                thisObj.val(thisObj.val().replace(/([^.0-9a-zA-Z-@]*)/g, ""));

                _keyup_msg(_get_data_type_msg(thisObj, "도메인을 올바로 입력해 주세요."), thisObj);

                input[0].selectionStart = input[0].selectionEnd = start;
            }
        } else if (thisObj.attr("data_type") === 'ip_address') {
            let regx_ip_address = /^([.0-9]*)$/;
            if (!regx_ip_address.test(thisObj.val())) {
                let input = thisObj;
                let start = input[0].selectionStart;

                thisObj.val(thisObj.val().replace(/([^.0-9]*)/g, ""));

                _keyup_msg(_get_data_type_msg(thisObj, "IP주소를 올바로 입력해 주세요."), thisObj);

                input[0].selectionStart = input[0].selectionEnd = start;
            }
        } else if (thisObj.attr("data_type") === 'ip_address_filter') {
            let regx_ip_address_filter = /^([*.0-9]*)$/;
            if (!regx_ip_address_filter.test(thisObj.val())) {
                let input = thisObj;
                let start = input[0].selectionStart;

                thisObj.val(thisObj.val().replace(/([^.0-9]*)/g, ""));

                _keyup_msg(_get_data_type_msg(thisObj, "IP주소 또는 IP주소 필터값을 올바로 입력해 주세요."), thisObj);

                input[0].selectionStart = input[0].selectionEnd = start;
            }
        } else if (thisObj.attr("data_type") === 'filename') {
            let regx_filename = /^[^\\/:*?"<>|]*$/;
            if (!regx_filename.test(thisObj.val())) {
                let input = thisObj;
                let start = input[0].selectionStart;

                thisObj.val(thisObj.val().replace(/([\\/:*?"<>|]*)/g, ""));

                _keyup_msg(_get_data_type_msg(thisObj, "파일명으로 가능한 문자만 입력해 주세요.\n불허 문자: \\ / : * ? \" < > |"), thisObj);

                input[0].selectionStart = input[0].selectionEnd = start;
            }
        } else if (thisObj.attr("data_type") === 'strict_file_path') {
            let regx_strict_file_path = /^[/a-zA-Z_0-9.-]*$/;
            if (!regx_strict_file_path.test(thisObj.val())) {
                let input = thisObj;
                let start = input[0].selectionStart;

                thisObj.val(thisObj.val().replace(/([^/a-zA-Z_0-9.-]*)/g, ""));

                _keyup_msg(_get_data_type_msg(thisObj, "경로를 올바로 입력해 주세요.\n허용문자: 숫자,영문,-,_,/,."), thisObj);

                input[0].selectionStart = input[0].selectionEnd = start;
            }
        } else if (thisObj.attr("data_type") === 'simple_url') {
            let regx_simple_url = /^[0-9a-zA-Z~_/.:-]*$/;
            if (!regx_simple_url.test(thisObj.val())) {
                let input = thisObj;
                let start = input[0].selectionStart;

                thisObj.val(thisObj.val().replace(/([^0-9a-zA-Z~_/.:-]*)/g, ""));

                _keyup_msg(_get_data_type_msg(thisObj, "URL 주소를 올바로 입력해 주세요."), thisObj);

                input[0].selectionStart = input[0].selectionEnd = start;
            }
        } else if (thisObj.attr("data_type") === 'standard_url' || thisObj.attr("data_type") === 'general_url') {
            let regx_standard_url = /^[0-9a-zA-Z~@#%^&()-_+=|?/.:]*$/;
            if (!regx_standard_url.test(thisObj.val())) {
                let input = thisObj;
                let start = input[0].selectionStart;

                thisObj.val(thisObj.val().replace(/([^0-9a-zA-Z~@#%^&()-_+=|?/.:]*)/g, ""));

                _keyup_msg(_get_data_type_msg(thisObj, "URL 주소를 올바로 입력해 주세요."), thisObj);

                input[0].selectionStart = input[0].selectionEnd = start;
            }
        } else if (thisObj.attr("data_type") === 'hex_color') {
            let regx_hex_color = /^[#a-f0-9]*$/;
            if (!regx_hex_color.test(thisObj.val())) {
                let input = thisObj;
                let start = input[0].selectionStart;

                thisObj.val(thisObj.val().replace(/([^#a-f0-9]*)/g, ""));

                _keyup_msg(_get_data_type_msg(thisObj, "16진수 형식 색상 값을 올바로 입력해 주세요."), thisObj);

                input[0].selectionStart = input[0].selectionEnd = start;
            }
        } else if (thisObj.attr("data_type") === 'color_picker') {
            let regx_color_picker = /^[#a-f0-9]*$/;
            if (!regx_color_picker.test(thisObj.val())) {
                let input = thisObj;
                let start = input[0].selectionStart;

                thisObj.val(thisObj.val().replace(/([^#a-f0-9]*)/g, ""));

                _keyup_msg(_get_data_type_msg(thisObj, "16진수 형식 색상 값을 올바로 입력해 주세요."), thisObj);

                input[0].selectionStart = input[0].selectionEnd = start;
            }
        } else if (thisObj.attr("data_type") === 'html_tag') {
            //let regx_html_tag = /^.*$/;
            //if( !regx_html_tag.test(thisObj.val()) )
            //{
            //	let input = thisObj;
            //	let start = input[0].selectionStart;
            //
            //	thisObj.val( thisObj.val().replace(/([^.]*)/g,"") );
            //
            //	_keyup_msg( _get_data_type_msg(thisObj,"HTML 태그를 올바로 입력해 주세요."), thisObj);
            //
            //	input[0].selectionStart = input[0].selectionEnd = start;
            //}
        } else if (thisObj.attr("data_type") === 'phone_num') {
            let regx_phone_num = /^[0-9-]*$/;
            if (!regx_phone_num.test(thisObj.val())) {
                let input = thisObj;
                let start = input[0].selectionStart;

                thisObj.val(thisObj.val().replace(/([^0-9-]*)/g, ""));

                _keyup_msg(_get_data_type_msg(thisObj, "핸드폰번호를 올바로 입력해 주세요."), thisObj);

                input[0].selectionStart = input[0].selectionEnd = start;
            }
        } else if (thisObj.attr("data_type") === 'phone_num_dash') {
            let regx_phone_num = /^[0-9-]*$/;
            if (!regx_phone_num.test(thisObj.val())) {
                let input = thisObj;
                let start = input[0].selectionStart;

                thisObj.val(thisObj.val().replace(/([^0-9-]*)/g, ""));

                _keyup_msg(_get_data_type_msg(thisObj, "핸드폰번호(- 필수)를 올바로 입력해 주세요."), thisObj);

                input[0].selectionStart = input[0].selectionEnd = start;
            }
        } else if (thisObj.attr("data_type") === 'phone_num_no_dash') {
            let regx_phone_num = /^[0-9]*$/;
            if (!regx_phone_num.test(thisObj.val())) {
                let input = thisObj;
                let start = input[0].selectionStart;

                thisObj.val(thisObj.val().replace(/([^0-9]*)/g, ""));

                _keyup_msg(_get_data_type_msg(thisObj, "핸드폰번호(- 사용 불가)를 올바로 입력해 주세요."), thisObj);

                input[0].selectionStart = input[0].selectionEnd = start;
            }
        } else if (thisObj.attr("data_type") === 'phone_num_global') {
            let regx_phone_num_global = /^\+?[0-9-]*$/;
            if (!regx_phone_num_global.test(thisObj.val())) {
                let input = thisObj;
                let start = input[0].selectionStart;

                thisObj.val(thisObj.val().replace(/([^0-9-+]*)/g, ""));

                _keyup_msg(_get_data_type_msg(thisObj, "핸드폰번호(국제전화번호 포함)를 올바로 입력해 주세요."), thisObj);

                input[0].selectionStart = input[0].selectionEnd = start;
            }
        } else if (thisObj.attr("data_type") === 'telephone') {
            let regx_telephone = /^[0-9-]*$/;
            if (!regx_telephone.test(thisObj.val())) {
                let input = thisObj;
                let start = input[0].selectionStart;

                thisObj.val(thisObj.val().replace(/([^0-9-]*)/g, ""));

                _keyup_msg(_get_data_type_msg(thisObj, "일반 전화를 올바로 입력해 주세요."), thisObj);

                input[0].selectionStart = input[0].selectionEnd = start;
            }
        } else if (thisObj.attr("data_type") === 'telephone_dash') {
            let regx_telephone = /^[0-9-]*$/;
            if (!regx_telephone.test(thisObj.val())) {
                let input = thisObj;
                let start = input[0].selectionStart;

                thisObj.val(thisObj.val().replace(/([^0-9-]*)/g, ""));

                _keyup_msg(_get_data_type_msg(thisObj, "일반 전화(- 필수)를 올바로 입력해 주세요."), thisObj);

                input[0].selectionStart = input[0].selectionEnd = start;
            }
        } else if (thisObj.attr("data_type") === 'auth_num') {
            //let regx_auth_num = /^[0-9]{0,6}$/;
            let regx_auth_num = /^[0-9]*$/;
            if (!regx_auth_num.test(thisObj.val())) {
                let input = thisObj;
                let start = input[0].selectionStart;

                thisObj.val(thisObj.val().replace(/([^0-9]*)/g, ""));

                _keyup_msg(_get_data_type_msg(thisObj, "6자리 숫자를 올바로 입력해 주세요."), thisObj);

                input[0].selectionStart = input[0].selectionEnd = start;
            }
        } else if (thisObj.attr("data_type") === 'birthday6') {
            //let regx_birthday6 = /^[0-9]{0,6}$/;
            let regx_birthday6 = /^[0-9]*$/;
            if (!regx_birthday6.test(thisObj.val())) {
                let input = thisObj;
                let start = input[0].selectionStart;

                thisObj.val(thisObj.val().replace(/([^0-9])/g, ""));

                _keyup_msg(_get_data_type_msg(thisObj, "생년월일 형식(6자리 숫자)으로 입력해 주세요."), thisObj);

                input[0].selectionStart = input[0].selectionEnd = start;
            }
        } else if (thisObj.attr("data_type") === 'birthday8') {
            //let regx_birthday8 = /^[0-9]{0,8}$/;
            let regx_birthday8 = /^[0-9]*$/;
            if (!regx_birthday8.test(thisObj.val())) {
                let input = thisObj;
                let start = input[0].selectionStart;

                thisObj.val(thisObj.val().replace(/([^0-9])/g, ""));

                _keyup_msg(_get_data_type_msg(thisObj, "생년월일 형식(8자리 숫자)으로 입력해 주세요."), thisObj);

                input[0].selectionStart = input[0].selectionEnd = start;
            }
        } else if (thisObj.attr("data_type") === 'app_version') {
            let regx_app_version = /^[0-9.]*$/;
            if (!regx_app_version.test(thisObj.val())) {
                let input = thisObj;
                let start = input[0].selectionStart;

                thisObj.val(thisObj.val().replace(/([^0-9.]*)/g, ""));

                _keyup_msg(_get_data_type_msg(thisObj, "앱 버전을 올바로 입력해 주세요."), thisObj);

                input[0].selectionStart = input[0].selectionEnd = start;
            }
        } else if (thisObj.attr("data_type") === 'corporate_registration_number') {
            let regx_corporate_registration_number = /^[0-9]*$/;
            if (!regx_corporate_registration_number.test(thisObj.val())) {
                let input = thisObj;
                let start = input[0].selectionStart;

                thisObj.val(thisObj.val().replace(/([^0-9]*)/g, ""));

                _keyup_msg(_get_data_type_msg(thisObj, "사업자등록번호를 올바로 입력해 주세요."), thisObj);

                input[0].selectionStart = input[0].selectionEnd = start;
            }
        }
        // sequential_word 생략
        else if (thisObj.attr("data_type") === 'no_sequential_word') {
            let regx_no_sequential_word = /^[0-9a-zA-Z\\~`!@#$%^&*()-_+=|"'?/<>,.;:[\]{}]*$/;
            if (!regx_no_sequential_word.test(thisObj.val())) {
                let input = thisObj;
                let start = input[0].selectionStart;

                thisObj.val(thisObj.val().replace(/([^0-9a-zA-Z\\~`!@#$%^&*()-_+=|"'?/<>,.;:[\]{}])/g, ""));

                _keyup_msg(_get_data_type_msg(thisObj, "비밀번호를 올바로 입력해 주세요.\n(4자 이상의 연속된 숫자나 문자 불허)"), thisObj);

                input[0].selectionStart = input[0].selectionEnd = start;
            }
        } else if (thisObj.attr("data_type") === 'hangul') {
            // 천지인: http://apppie.blogspot.kr/2015/04/android-middle-dot-inputfilter.html
            // · : \u318D\u119E\u11A2\u2022\u2025a\u00B7\uFE55
            // 한글유니코드: http://liveupdate.tistory.com/149
            let regx_hangul = /^[ㄱ-ㅎㅏ-ㅣ가-힣\u1100-\u11FF\u318D\u119E\u11A2\u2022\u2025a\u00B7\uFE55]*$/;
            if (!regx_hangul.test(thisObj.val())) {
                let input = thisObj;
                let start = input[0].selectionStart;

                thisObj.val(thisObj.val().replace(/([^ㄱ-ㅎㅏ-ㅣ가-힣\u1100-\u11FF\u318D\u119E\u11A2\u2022\u2025a\u00B7\uFE55])/g, ""));

                _keyup_msg(_get_data_type_msg(thisObj, "한글만을 입력해 주세요."), thisObj);

                input[0].selectionStart = input[0].selectionEnd = start;
            }
        }
            // no_abnormal_hangul 생략
            // password_an6 생략
            // password_ans8 생략
            // password_ans10 생략
            // user_password 생략
        // worker_password 생략
        else if (thisObj.attr("data_type") === 'worker_id') {
            let regx_worker_id = /^([.0-9a-zA-Z_+-@]*)$/; // /^[a-z]+([a-z0-9]*)$/;
            if (!regx_worker_id.test(thisObj.val())) {
                let input = thisObj;
                let start = input[0].selectionStart;

                thisObj.val(thisObj.val().replace(/([^.0-9a-zA-Z_+-@]*)/g, ""));

                _keyup_msg(_get_data_type_msg(thisObj, "작업자 ID를 올바로 입력해 주세요.\n허용문자: 영문 대소문자 시작, 숫자, _, 점(.) 사용 가능, 5~30자, 또는 이메일계정 형식"), thisObj);

                input[0].selectionStart = input[0].selectionEnd = start;
            }
        } else if (thisObj.attr("data_type") === 'worker_id4') {
            let regx_worker_id4 = /^([.0-9a-zA-Z_+-@]*)$/; // /^[a-z]+([a-z0-9]*)$/;
            if (!regx_worker_id4.test(thisObj.val())) {
                let input = thisObj;
                let start = input[0].selectionStart;

                thisObj.val(thisObj.val().replace(/([^.0-9a-zA-Z_+-@]*)/g, ""));

                _keyup_msg(_get_data_type_msg(thisObj, "작업자 ID를 올바로 입력해 주세요.\n허용문자: 영문 대소문자 시작, 숫자, _, 점(.) 사용 가능, 4~30자, 또는 이메일계정 형식"), thisObj);

                input[0].selectionStart = input[0].selectionEnd = start;
            }
        } else if (thisObj.attr("data_type") === 'numeric_list') {
            let regx_numeric_list = /^([0-9,]*)$/;
            if (!regx_numeric_list.test(thisObj.val())) {
                let input = thisObj;
                let start = input[0].selectionStart;

                thisObj.val(thisObj.val().replace(/([^0-9,]*)/g, ""));

                _keyup_msg(_get_data_type_msg(thisObj, "숫자,숫자,숫자,... 형태로 입력해 주세요.(숫자만 입력도 가능)"), thisObj);

                input[0].selectionStart = input[0].selectionEnd = start;
            }
        } else if (thisObj.attr("data_type") === 'alpha_numeric_list') {
            let regx_alpha_numeric_list = /^([0-9a-zA-Z,]*)$/;
            if (!regx_alpha_numeric_list.test(thisObj.val())) {
                let input = thisObj;
                let start = input[0].selectionStart;

                thisObj.val(thisObj.val().replace(/([^0-9a-zA-Z,]*)/g, ""));

                _keyup_msg(_get_data_type_msg(thisObj, "알파벳or숫자,알파벳or숫자,알파벳or숫자,... 형태로 입력해 주세요.(알파벳or숫자만 입력도 가능)"), thisObj);

                input[0].selectionStart = input[0].selectionEnd = start;
            }
        } else if (thisObj.attr("data_type") === 'hangul_alpha_numeric') {
            let regx_hangul_alpha_numeric = /^([0-9a-zA-Zㄱ-ㅎㅏ-ㅣ가-힣\u1100-\u11FF\u318D\u119E\u11A2\u2022\u2025a\u00B7\uFE55]*)$/;
            if (!regx_hangul_alpha_numeric.test(thisObj.val())) {
                let input = thisObj;
                let start = input[0].selectionStart;

                thisObj.val(thisObj.val().replace(/([^0-9a-zA-Zㄱ-ㅎㅏ-ㅣ가-힣\u1100-\u11FF\u318D\u119E\u11A2\u2022\u2025a\u00B7\uFE55]*)/g, ""));

                _keyup_msg(_get_data_type_msg(thisObj, "한글, 알파벳, 숫자만 입력해 주세요."), thisObj);

                input[0].selectionStart = input[0].selectionEnd = start;
            }
        } else if (thisObj.attr("data_type") === 'user_name') {
            let regx_user_name = /^([a-zA-Z\sㄱ-ㅎㅏ-ㅣ가-힣\u1100-\u11FF\u318D\u119E\u11A2\u2022\u2025a\u00B7\uFE55]*)$/;
            if (!regx_user_name.test(thisObj.val())) {
                let input = thisObj;
                let start = input[0].selectionStart;

                thisObj.val(thisObj.val().replace(/([^a-zA-Z\sㄱ-ㅎㅏ-ㅣ가-힣\u1100-\u11FF\u318D\u119E\u11A2\u2022\u2025a\u00B7\uFE55]*)/g, ""));

                _keyup_msg(_get_data_type_msg(thisObj, "한글, 알파벳, 공백문자만 입력해 주세요."), thisObj);

                input[0].selectionStart = input[0].selectionEnd = start;
            }
        } else if (thisObj.attr("data_type") === 'numeric_range') {
            let regx_numeric_range = /^[0-9-]*$/;
            if (!regx_numeric_range.test(thisObj.val())) {
                let input = thisObj;
                let start = input[0].selectionStart;

                thisObj.val(thisObj.val().replace(/([^0-9-]*)/g, ""));

                _keyup_msg(_get_data_type_msg(thisObj, "숫자 범위를 올바로 입력해 주세요."), thisObj);

                input[0].selectionStart = input[0].selectionEnd = start;
            }
        } else if (thisObj.attr("data_type") === 'address_user_input') {
            let regx_address_user_input = /^[\x20-\x7Eㄱ-ㅎㅏ-ㅣ가-힣]*$/;
            if (!regx_address_user_input.test(thisObj.val())) {
                let input = thisObj;
                let start = input[0].selectionStart;

                thisObj.val(thisObj.val().replace(/([^\x20-\x7Eㄱ-ㅎㅏ-ㅣ가-힣]*)/g, ""));

                _keyup_msg(_get_data_type_msg(thisObj, "주소를 올바로 입력해 주세요."), thisObj);

                input[0].selectionStart = input[0].selectionEnd = start;
            }
        } else if (AMString.startsWith(thisObj.attr("data_type"), '{')) {
            let objDataType = JSON.parse(thisObj.attr("data_type"));
            if (typeof objDataType.keyup_checker === 'string' && objDataType.keyup_checker !== '') {
                let extRegExp = new RegExp(objDataType.keyup_checker);
                let extArray = extRegExp.exec(thisObj.val());
                if (extArray === null) {
                    let input = thisObj;
                    let start = input[0].selectionStart;

                    if (typeof objDataType.keyup_deleter === 'string' && objDataType.keyup_deleter !== '')
                        thisObj.val(thisObj.val().replace(new RegExp(objDataType.keyup_deleter, "g"), ""));

                    _keyup_msg(objDataType.error_msg, thisObj);

                    input[0].selectionStart = input[0].selectionEnd = start;
                }
            }
        }
    }

    // 입력박스의 데이터타입이 'date'인 경우 날짜선택 박스 띄움
    function initDatePicker(objInput) {
        if (objInput instanceof jQuery) {
            if (!objInput.is("input[data_type='date']"))
                objInput = $("input[data_type='date']");
        } else {
            objInput = $("input[data_type='date']");
        }

        objInput.each(function () {
            let datepicker_option = {
                'defaultDate': "0d",
                'changeMonth': true,
                'numberOfMonths': 1,
                'dateFormat': "yy-mm-dd",
                'showMonthAfterYear': true,
                'dayNamesMin': ['일', '월', '화', '수', '목', '금', '토'], // 요일의 한글 형식.
                'monthNamesShort': ['1월', '2월', '3월', '4월', '5월', '6월', '7월', '8월', '9월', '10월', '11월', '12월'],
                'changeYear': true,   // 년도 선택
                'yearRange': "1940:+30" // 선택 구간
                //'yearRange'			: "-5:+5",   //  현재 년도 기준 5년범위 증감
            };
            if ($(this).attr('minDate') !== "")
                datepicker_option = $.extend(datepicker_option, {'minDate': $(this).attr('minDate')});
            if ($(this).attr('maxDate') !== "")
                datepicker_option = $.extend(datepicker_option, {'maxDate': $(this).attr('maxDate')});

            $(this).datepicker(datepicker_option);
        });
    }

    // 입력박스의 데이터타입이 'time'인 경우 시간 선택 박스 띄움
    function initTimePicker(objInput) {
        if (objInput instanceof jQuery) {
            if (!objInput.is("input[data_type='time']"))
                objInput = $("input[data_type='time']");
        } else {
            objInput = $("input[data_type='time']");
        }

        objInput.each(function () {
            $(this).timepicker({
                //'minDate'		: 0,
                'timeOnlyTitle': 'Time',
                'timeText': '시간',
                'hourText': '시',
                'minuteText': '분',
                'secondText': '초',
                'currentText': '현재',
                'closeText': '닫기',
                'timeInput': true,
                'timeFormat': 'HH:mm:ss'
            });
            //$(this).clockpicker({
            //	'autoclose'	: false,
            //	'twelvehour': false,
            //	'donetext'	: '확인',
            //	'vibrate'	: false
            //});
        });
    }

    // 입력박스의 데이터타입이 'datetime'인 경우 날짜/시간 선택 박스 띄움
    function initDateTimePicker(objInput) {
        if (objInput instanceof jQuery) {
            if (!objInput.is("input[data_type='datetime']"))
                objInput = $("input[data_type='datetime']");
        } else {
            objInput = $("input[data_type='datetime']");
        }

        objInput.each(function () {
            let datetimepicker_option = {
                'monthNames': ['1월', '2월', '3월', '4월', '5월', '6월', '7월', '8월', '9월', '10월', '11월', '12월'],
                'monthNamesShort': ['1월', '2월', '3월', '4월', '5월', '6월', '7월', '8월', '9월', '10월', '11월', '12월'],
                'dayNames': ['일', '월', '화', '수', '목', '금', '토'],
                'dayNamesShort': ['일', '월', '화', '수', '목', '금', '토'],
                'dayNamesMin': ['일', '월', '화', '수', '목', '금', '토'],
                'weekHeader': 'Не',
                'dateFormat': 'yy-mm-dd',
                'firstDay': 0,
                //'minDate'			: 0,
                'isRTL': false,
                'showMonthAfterYear': true,
                'yearSuffix': '년',
                'prevText': '이전 달',
                'nextText': '다음 달',
                'timeText': '시간',
                'hourText': '시',
                'minuteText': '분',
                'secondText': '초',
                'currentText': '현재',
                'closeText': '닫기',
                'timeInput': true,
                'timeFormat': 'HH:mm:ss'
                //'keepOpen'		: true
            };
            if ($(this).attr('minDate') !== "")
                datetimepicker_option = $.extend(datetimepicker_option, {'minDate': $(this).attr('minDate')});
            if ($(this).attr('maxDate') !== "")
                datetimepicker_option = $.extend(datetimepicker_option, {'maxDate': $(this).attr('maxDate')});

            $(this).datetimepicker(datetimepicker_option);
            //$(this).datetimepicker({
            //	'defaultDate'			: "0d",
            //	'changeMonth'			: true,
            //	'numberOfMonths'		: 1,
            //	'dateFormat'			: "yy-mm-dd",
            //	'timeFormat'			: 'HH:mm',
            //	//'timeFormat'			: 'HH:mm:ss',
            //	'stepHour'				: 1,
            //	'stepMinute'			: 1,
            //	'stepSecond'			: 1,
            //	'showMonthAfterYear'	: true,
            //	'dayNamesMin'			: ['일', '월', '화', '수', '목', '금', '토'], // 요일의 한글 형식.
            //	'monthNamesShort'		: ['1월','2월','3월','4월','5월','6월','7월','8월','9월','10월','11월','12월'],
            //	'changeYear'			: true,   // 년도 선택
            //	'yearRange'				: "1940:+30" // 선택 구간
            //	//'yearRange'			: "-5:+5",   //  현재 년도 기준 5년범위 증감
            //});
        });
    }


    // 입력박스의 데이터타입이 'yearmonth'인 경우 연월 박스 띄움
    // admin.css에 다음 라운 추가 필요
    // <style> .month_year_datepicker .ui-datepicker-calendar { display:none; } </style>
    function initYearMonthPicker(objInput) {
        if (objInput instanceof jQuery) {
            if (!objInput.is("input[data_type='yearmonth']"))
                objInput = $("input[data_type='yearmonth']");
        } else {
            objInput = $("input[data_type='yearmonth']");
        }

        objInput.each(function () {
            let datepicker_option = {
                'changeMonth': true,
                'changeYear': true,   // 년도 선택
                'dateFormat': "yy-mm",
                'showMonthAfterYear': true,
                'monthNamesShort': ['1월', '2월', '3월', '4월', '5월', '6월', '7월', '8월', '9월', '10월', '11월', '12월'],
                'showButtonPanel': true,
                'currentText': "오늘",
                'closeText': "선택",
                'yearRange': "1940:+30", // 선택 구간
                //'yearRange'			: "-5:+5",   //  현재 년도 기준 5년범위 증감
                'onClose': function (dateText, inst) {

                    function isDonePressed() {
                        return ($('#ui-datepicker-div').html().indexOf('ui-datepicker-close ui-state-default ui-priority-primary ui-corner-all ui-state-hover') > -1);
                    }

                    if (isDonePressed()) {
                        let month = $("#ui-datepicker-div .ui-datepicker-month :selected").val();
                        let year = $("#ui-datepicker-div .ui-datepicker-year :selected").val();
                        $(this).datepicker('setDate', new Date(year, month, 1)).trigger('change');
                        $(this).focusout();
                        //$('.date-picker').focusout();
                    }
                    setTimeout(function () {
                        inst.dpDiv.removeClass('month_year_datepicker');
                        //$(this).datepicker( "destroy" );
                    }, 100);
                },
                'beforeShow': function (input, inst) {
                    inst.dpDiv.addClass('month_year_datepicker');
                    let datestr = $(this).val();
                    if (datestr.length > 0) {
                        let actDate = datestr.split('-');
                        let year = actDate[0];
                        let month = actDate[1] - 1;
                        $(this).datepicker('option', 'defaultDate', new Date(year, month));
                        $(this).datepicker('setDate', new Date(year, month));
                    }
                    //$('.ui-datepicker-calendar').hide();
                }
            };
            if ($(this).attr('minDate') !== "")
                datepicker_option = $.extend(datepicker_option, {'minDate': $(this).attr('minDate')});
            if ($(this).attr('maxDate') !== "")
                datepicker_option = $.extend(datepicker_option, {'maxDate': $(this).attr('maxDate')});

            $(this).datepicker(datepicker_option);
        });
    }

    // // 입력박스의 데이터타입이 'color_picker'인 경우 색상 선택 박스 띄움
    // function initColorPicker(objInput)
    // {
    // 	if( objInput instanceof jQuery )
    // 	{
    // 		if( !objInput.is("input[data_type='color_picker']") )
    // 			objInput = $("input[data_type='color_picker']");
    // 	}
    // 	else
    // 	{
    // 		objInput = $("input[data_type='color_picker']");
    // 	}
    //
    // 	let objColorPicker = {
    // 		'showInput'		: true,
    // 		'preferredFormat': "hex",
    // 		'showPalette'	: true,
    // 		//'showAlpha'	: true,
    //
    // 		// 콜백 함수
    // 		'move'			: function(color){},
    // 		'show'			: function(color){},
    // 		'beforeShow'	: function(color){},
    // 		'hide'			: function(color){},
    // 		'change'		: function(color,inputbox){},
    // 		'palette'		: [
    // 							[
    // 								"rgb(0, 0, 0)", "rgb(33, 33, 33)", "rgb(67, 67, 67)", "rgb(85, 85, 85)", "rgb(102, 102, 102)", "rgb(153, 153, 153)",
    // 								"rgb(204, 204, 204)", "rgb(210, 210, 210)", "rgb(217, 217, 217)", "rgb(229, 229, 229)", "rgb(255, 255, 255)"
    // 							],
    // 							[
    // 								"rgb(152, 0, 0)", "rgb(255, 0, 0)", "rgb(255, 153, 0)", "rgb(255, 255, 0)",
    // 								"rgb(0, 255, 0)", "rgb(0, 255, 255)", "rgb(74, 134, 232)", "rgb(0, 0, 255)", "rgb(153, 0, 255)", "rgb(255, 0, 255)"
    // 							],
    // 							[
    // 								"rgb(230, 184, 175)", "rgb(244, 204, 204)", "rgb(252, 229, 205)", "rgb(255, 242, 204)", "rgb(217, 234, 211)",
    // 								"rgb(208, 224, 227)", "rgb(201, 218, 248)", "rgb(207, 226, 243)", "rgb(217, 210, 233)", "rgb(234, 209, 220)",
    // 								"rgb(221, 126, 107)", "rgb(234, 153, 153)", "rgb(249, 203, 156)", "rgb(255, 229, 153)", "rgb(182, 215, 168)",
    // 								"rgb(162, 196, 201)", "rgb(164, 194, 244)", "rgb(159, 197, 232)", "rgb(180, 167, 214)", "rgb(213, 166, 189)",
    // 								"rgb(204, 65, 37)", "rgb(224, 102, 102)", "rgb(246, 178, 107)", "rgb(255, 217, 102)", "rgb(147, 196, 125)",
    // 								"rgb(118, 165, 175)", "rgb(109, 158, 235)", "rgb(111, 168, 220)", "rgb(142, 124, 195)", "rgb(194, 123, 160)",
    // 								"rgb(166, 28, 0)", "rgb(204, 0, 0)", "rgb(230, 145, 56)", "rgb(241, 194, 50)", "rgb(106, 168, 79)",
    // 								"rgb(69, 129, 142)", "rgb(60, 120, 216)", "rgb(61, 133, 198)", "rgb(103, 78, 167)", "rgb(166, 77, 121)",
    // 								"rgb(91, 15, 0)", "rgb(102, 0, 0)", "rgb(120, 63, 4)", "rgb(127, 96, 0)", "rgb(39, 78, 19)",
    // 								"rgb(12, 52, 61)", "rgb(28, 69, 135)", "rgb(7, 55, 99)", "rgb(32, 18, 77)", "rgb(76, 17, 48)"
    // 							]
    // 						]
    // 	};
    //
    // 	if( typeof window['AdminColorPicker']==='object' )
    // 	{
    // 		let objCustomInfo = AdminColorPicker.get_custom_info();
    // 		objColorPicker = $.extend({},objColorPicker,objCustomInfo);
    // 	}
    // 	objInput.each(function(){
    // 		$(this).spectrum(objColorPicker);
    // 		$(this).show();
    // 	});
    // 	$('.sp-cancel').html('취소');
    // 	$('.sp-choose').html('선택');
    // }

    ///////////////////////////////////////////////////////////////////////////////////

    // 노출할 public 메쏘드들
    return {
        'initialize': initialize,
        'initTextareaHeightController': initTextareaHeightController,
        'initCharacterLimiter': initCharacterLimiter,
        'initCaseChecker': initCaseChecker,
        'initKeyDownChecker': initKeyDownChecker,
        'initKeyUpChecker': initKeyUpChecker,
        'initDatePicker': initDatePicker,
        'initTimePicker': initTimePicker,
        'initDateTimePicker': initDateTimePicker,
        'initYearMonthPicker': initYearMonthPicker,
        // 'initColorPicker'				: initColorPicker
    };
})();
