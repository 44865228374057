import commonStyles from "../../common/css/Common.module.css";

const Paging = ({
  blockSize,
  totalPageCount,
  currentPageCount,
  onPageClick,
}) => {
  let currentBlock = Math.floor((currentPageCount - 1) / blockSize);
  let startPage = currentBlock * blockSize + 1;
  let endPage = startPage + blockSize - 1;
  if (endPage > totalPageCount) {
    endPage = totalPageCount;
  }

  return (
    <div className={commonStyles.cc_paging}>
      {currentBlock > 0 && (
        <>
          <ArrowFirst onClick={() => onPageClick(1)} />
          <ArrowPrev onClick={() => onPageClick(startPage - 1)} />
        </>
      )}
      <PageNumbers
        startPage={startPage}
        endPage={endPage}
        currentPageCount={currentPageCount}
        blockSize={blockSize}
        onPageClick={onPageClick}
      />
      {endPage !== totalPageCount && (
        <>
          <ArrowNext onClick={() => onPageClick(endPage + 1)} />
          <ArrowLast onClick={() => onPageClick(totalPageCount)} />
        </>
      )}
    </div>
  );
};

const ArrowFirst = ({ onClick }) => {
  return (
    <a
      href="#"
      onClick={(e) => {
        sessionStorage.removeItem("searchedParam");
        onClick();
        e.preventDefault()
      }}
      className={`${commonStyles.arrow} ${commonStyles.first}`}
    >
      <span className={commonStyles.sr_only}>처음 페이지 목록으로 이동</span>
    </a>
  );
};

const ArrowPrev = ({ onClick }) => {
  return (
    <a
      href="#"
      onClick={(e) => {
        sessionStorage.removeItem("searchedParam");
        onClick();
        e.preventDefault()
      }}
      className={`${commonStyles.arrow} ${commonStyles.prev}`}
    >
      <span className={commonStyles.sr_only}>이전 페이지 목록으로 이동</span>
    </a>
  );
};

const PageNumbers = ({ startPage, endPage, currentPageCount, onPageClick }) => {
  const PageNumber = ({ number, current = false }) => {
    return (
      <a
        key={number}
        onClick={(e) => {
          sessionStorage.removeItem("searchedParam");
          onPageClick(number);
          e.preventDefault();
        }}
        href="#"
        className={`${commonStyles.num} ${ current ? commonStyles.on : '' }`}
      >
        <span>{number}</span>
        <span className={commonStyles.sr_only}>번째 페이지 목록으로 이동</span>
      </a>
    );
  };

  return (
    <>
      {Array.from(
        { length: endPage - startPage + 1 },
        (v, i) => startPage + i
      ).map((idx) =>
        PageNumber({ number: idx, current: currentPageCount === idx })
      )}
    </>
  );
};

const ArrowNext = ({ onClick }) => {
  return (
    <a
      href="#"
      onClick={(e) => {
        sessionStorage.removeItem("searchedParam");
        onClick();
        e.preventDefault()
      }}
      className={`${commonStyles.arrow} ${commonStyles.next}`}
    >
      <span className={commonStyles.sr_only}>다음 페이지 목록으로 이동</span>
    </a>
  );
};

const ArrowLast = ({ onClick }) => {
  return (
    <a
      href="#"
      onClick={(e) => {
        sessionStorage.removeItem("searchedParam");
        onClick();
        e.preventDefault()
      }}
      className={`${commonStyles.arrow} ${commonStyles.last}`}
    >
      <span className={commonStyles.sr_only}>마지막 페이지 목록으로 이동</span>
    </a>
  );
};

export default Paging;
