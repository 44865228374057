import challengeStyles from "../../common/css/Challenge.module.css";

const TeamBoxList = ({children}) => {
    return (
        <div className={challengeStyles.team_box_list}>
            {children}
        </div>
    );
};

export default TeamBoxList;