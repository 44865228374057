import BbsWrap from "../challenge/BbsWrap";
import commonStyles from "../../common/css/Common.module.css";
import challengeStyles from "../../common/css/Challenge.module.css";
import myPageChallengeStyles from "./MyPageChallenge.module.css";
import {useEffect, useState} from "react";
import _ from "lodash";
import MyPageChallengeContent from "./MyPageChallengeContent";
import Paging from "../common/Paging";
import {useDialog} from "../../common/hook/DialogContext";
import {useNavigate} from "react-router-dom";
import Url from "../../common/Url";
import {useAuth} from "../../common/hook/AuthContext";
import {Loading} from "../../routes/basic/Unknown";
import { useTranslation } from "react-i18next";

const MyPageChallenge = () => {
    const [myChallengeTeams, setMyChallengeTeams] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPage, setTotalPage] = useState(1);
    const [refresh, setRefresh] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const { isLogin, loginCheck, setIsLogin } = useAuth();
    const {addAlert, addConfirm} = useDialog();
    const navigate = useNavigate();
    const {t} = useTranslation();

    const getMyChallengeTeams = () => {        
        setIsLoading(true);
        fetch(`/v1/challenge-team-apply/user?page=${currentPage - 1}&size=10`)
            .then((response) => {
                if (response.ok) {
                    setIsLoading(false);
                    return response.json();
                } else if (response.status === 401) {
                    return response.json().then(json => {
                        addAlert(json.message, () => navigate(Url.login));
                    })
                } else {
                    return response.json().then(json => {
                        addAlert(json.message);
                    })
                }
            })
            .then((json) => {
                if (json === undefined) return;
                setMyChallengeTeams(json.data);
                setTotalPage(json.totalPages);
            });
    }

    useEffect(() => {
        if (isLogin === false) {
            setIsLoading(true);
            addAlert(
                t("msg.error.login"),
                () => navigate(Url.login)
            );
        } else {
            getMyChallengeTeams();
        }
    },[currentPage, refresh]);

    return (
        !isLoading ?
            <>
                <BbsWrap addClass={myPageChallengeStyles.bbs_wrap}>
                    <div className={`${challengeStyles.top} ${challengeStyles.flex}`}>
                        <div className={challengeStyles.table_title}>{t("mypage.challenge.challenge")}</div>
                    </div>
                    <div className={`${challengeStyles.bbs_table} ${myPageChallengeStyles.bbs_table}`}>
                        <table>
                            <caption><strong>참여 대회 테이블 입니다.</strong>NO, 대회/구성원, 제출, 제출수, 최종 제출일이 명시되어 있습니다.</caption>

                            <colgroup>
                                <col style={{ width: "80px" }} />
                                <col />
                                <col style={{ width: "120px" }} />
                                <col style={{ width: "80px" }} />
                                <col style={{ width: "120px"}} />
                                <col style={{ width: "130px" }} />
                            </colgroup>
                            <thead>
                            <tr>
                                <th scope="col">
                                    <div>NO</div>
                                </th>
                                <th scope="col" className={challengeStyles.ta_left}>
                                    <div>{t("mypage.challenge.people")}</div>
                                </th>
                                <th scope="col">
                                    <div>{t("mypage.challenge.set")}</div>
                                </th>
                                <th scope="col">
                                    <div>{t("mypage.challenge.setCount")}</div>
                                </th>
                                <th scope="col">
                                    <div>{t("mypage.challenge.setDate")}</div>
                                </th>
                                <th scope="col">
                                    <div></div>
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            {!_.isEmpty(myChallengeTeams) ?
                                myChallengeTeams.map(myChallengeTeam => (
                                    <MyPageChallengeContent
                                        myChallengeTeam={myChallengeTeam}
                                        refresh={refresh}
                                        setRefresh={setRefresh}
                                    />
                                ))
                                :
                                <tr>
                                    <td colSpan="6" className={commonStyles.no_con}>
                                        <div>{t("common.noboard")}</div>
                                    </td>
                                </tr>
                            }

                            </tbody>
                        </table>
                    </div>
                </BbsWrap>
                <Paging
                    blockSize={10}
                    totalPageCount={totalPage}
                    currentPageCount={currentPage}
                    onPageClick={setCurrentPage}
                />
            </>
            :
            <Loading />
    );
};

export default MyPageChallenge;
