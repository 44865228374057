export const AMString=(function(){
	// "use strict";

	const KOREAN	= "korean";
	const ENGLISH	= "english";
	let m_language	= KOREAN;

	function set_language(language)
	{
		m_language = language;
	}

	function get_language()
	{
		return m_language;
	}

	///////////////////////////////////////////////////////////////////////////////////////////////

	// 숫자 검사
	function is_digit_x(number_value)
	{
		if( number_value.length!==1 )
			return false;

		if( number_value.charAt(0)==='0' || number_value.charAt(0)==='1' || number_value.charAt(0)==='2' || number_value.charAt(0)==='3' || number_value.charAt(0)==='4' ||
			number_value.charAt(0)==='5' || number_value.charAt(0)==='6' || number_value.charAt(0)==='7' || number_value.charAt(0)==='8' || number_value.charAt(0)==='9' )
			return true;
		else
			return false;
	}

	// '2008-08' 형태인지 검사한다.
	IsYearMonthFormat.error_msg = "";
	function IsYearMonthFormat(date_str)
	{
		// 초기화
		IsYearMonthFormat.error_msg = "";

		if(date_str==="")
			return false;

		let regular_expression = /^([0-9]{4})-(01|02|03|04|05|06|07|08|09|10|11|12)$/;
		if( !regular_expression.test(date_str) )
		{
			IsYearMonthFormat.error_msg = "'2014-12' 형식으로 입력해 주세요.";
			return false;
		}
		return true;
	}

	// 날짜 값이 유효한지도 검사한다.
	function checkdate(date_str)
	{
		let arr_item = date_str.split('-');
		let year  = parseInt(arr_item[0],10);
		let month = parseInt(arr_item[1],10);
		let day   = parseInt(arr_item[2],10);
		return ( 0 < year && year < 32768 && 0 < month && month < 13 && 0 < day && day <= (new Date(year,month,0)).getDate() );
	}

	// '2008-08-01' 형태인지 검사한다.
	IsDateFormat.error_msg = "";
	function IsDateFormat(date_str,strict/*디폴트false*/)
	{
		// 초기화
		IsDateFormat.error_msg = "";

		if(date_str==="")
			return false;

		let regular_expression = /^([0-9]{4})-((0?([1-9]{1}))|10|11|12)-((0?([1-9]{1}))|((1|2)([0-9]{1}))|3(0|1))$/;
		if( strict===true )
			regular_expression = /^([0-9]{4})-(01|02|03|04|05|06|07|08|09|10|11|12)-((0([1-9]{1}))|((1|2)([0-9]{1}))|3(0|1))$/;
		if( !regular_expression.test(date_str) )
		{
			IsDateFormat.error_msg = "'2014-12-31' 형식으로 입력해 주세요.";
			return false;
		}

		// 날짜 값이 유효한지도 검사한다.
		if( !checkdate(date_str) )
		{
			IsDateFormat.error_msg = "입력된 날짜는 존재할 수 없는 날짜입니다. 올바른 날짜를 입력해 주세요.";
			return false;
		}

		return true;
	}

	// '23:42:25' 형태인지 검사한다.
	IsTimeFormat.error_msg = "";
	function IsTimeFormat(time_str,strict/*디폴트false*/)
	{
		// 초기화
		IsTimeFormat.error_msg = "";

		if( strict===true )
		{
			if( !IsFullTimeFormat(time_str) )
			{
				IsTimeFormat.error_msg = IsFullTimeFormat.error_msg;
				return false;
			}
			return true;
		}
		else
		{
			let result =(IsFullTimeFormat(time_str) || IsShortTimeFormat(time_str));
			if( !result )
			{
				IsTimeFormat.error_msg = "'23:42:25' 또는 '23:42' 형식으로 입력해 주세요.";
				return false;
			}
			return true;
		}
	}

	// '23:42:25' 형태인지 검사한다.
	IsFullTimeFormat.error_msg = "";
	function IsFullTimeFormat(time_str)
	{
		// 초기화
		IsFullTimeFormat.error_msg = "";

		if(time_str==="")
			return false;

		let regular_expression = /^(((0|1)([0-9]{1}))|2(0|1|2|3)):((0|1|2|3|4|5)([0-9]{1})):((0|1|2|3|4|5)([0-9]{1}))$/;
		if( !regular_expression.test(time_str) )
		{
			IsFullTimeFormat.error_msg = "'23:42:25' 형식으로 입력해 주세요.";
			return false;
		}
		return true;
	}

	// '23:42' 형태인지 검사한다.
	IsShortTimeFormat.error_msg = "";
	function IsShortTimeFormat(time_str)
	{
		// 초기화
		IsShortTimeFormat.error_msg = "";

		if(time_str==="")
			return false;

		let regular_expression = /^(((0|1)([0-9]{1}))|2(0|1|2|3)):((0|1|2|3|4|5)([0-9]{1}))$/;
		if( !regular_expression.test(time_str) )
		{
			IsShortTimeFormat.error_msg = "'23:42' 형식으로 입력해 주세요.";
			return false;
		}
		return true;
	}

	// '2008-08-01 23:12:34' 형태인지 검사한다.
	IsDateTimeFormat.error_msg = "";
	function IsDateTimeFormat(datetime_str,strict/*디폴트false*/)
	{
		// 초기화
		IsDateTimeFormat.error_msg = "";

		if( strict===true )
		{
			if( !IsFullDateTimeFormat(datetime_str) )
			{
				IsDateTimeFormat.error_msg = IsFullDateTimeFormat.error_msg;
				return false;
			}
			return true;
		}
		else
		{
			let result = (IsFullDateTimeFormat(datetime_str) || IsShortDateTimeFormat(datetime_str));
			if( !result )
			{
				// 날짜 값이 유효한지도 검사한다.
				let arr_item = datetime_str.split(' ');
				let date_str = arr_item[0];
				let regular_expression = /^([0-9]{4})-((0?([1-9]{1}))|10|11|12)-((0?([1-9]{1}))|((1|2)([0-9]{1}))|3(0|1))$/;
				if( regular_expression.test(date_str) && !checkdate(date_str) )
					IsDateTimeFormat.error_msg = "입력된 날짜는 존재할 수 없는 날짜입니다. 올바른 날짜를 입력해 주세요.";
				else
					IsDateTimeFormat.error_msg = "'2014-12-31 23:42:25' 또는 '2014-12-31 23:42' 형식으로 입력해 주세요.";

				return false;
			}
			return true;
		}
	}

	// '2008-08-01 23:12:34' 형태인지 검사한다.
	IsFullDateTimeFormat.error_msg = "";
	function IsFullDateTimeFormat(datetime_str)
	{
		// 초기화
		IsFullDateTimeFormat.error_msg = "";

		if(datetime_str==="")
			return false;

		let regular_expression = /^([0-9]{4})-(01|02|03|04|05|06|07|08|09|10|11|12)-((0([1-9]{1}))|((1|2)([0-9]{1}))|3(0|1))\s(((0|1)([0-9]{1}))|2(0|1|2|3)):((0|1|2|3|4|5)([0-9]{1})):((0|1|2|3|4|5)([0-9]{1}))$/;
		if( !regular_expression.test(datetime_str) )
		{
			IsFullDateTimeFormat.error_msg = "'2014-12-31 23:42:25' 형식으로 입력해 주세요.";
			return false;
		}

		// 날짜 값이 유효한지도 검사한다.
		let arr_item = datetime_str.split(' ');
		if( !checkdate(arr_item[0]) )
		{
			IsFullDateTimeFormat.error_msg = "입력된 날짜는 존재할 수 없는 날짜입니다. 올바른 날짜를 입력해 주세요.";
			return false;
		}

		return true;
	}

	// '2008-08-01 23:12' 형태인지 검사한다.
	IsShortDateTimeFormat.error_msg = "";
	function IsShortDateTimeFormat(datetime_str)
	{
		// 초기화
		IsShortDateTimeFormat.error_msg = "";

		if(datetime_str==="")
			return false;

		let regular_expression = /^([0-9]{4})-(01|02|03|04|05|06|07|08|09|10|11|12)-((0([1-9]{1}))|((1|2)([0-9]{1}))|3(0|1))\s(((0|1)([0-9]{1}))|2(0|1|2|3)):((0|1|2|3|4|5)([0-9]{1}))$/;
		if( !regular_expression.test(datetime_str) )
		{
			IsShortDateTimeFormat.error_msg = "'2014-12-31 23:42' 형식으로 입력해 주세요.";
			return false;
		}

		// 날짜 값이 유효한지도 검사한다.
		let arr_item = datetime_str.split(' ');
		if( !checkdate(arr_item[0]) )
		{
			IsShortDateTimeFormat.error_msg = "입력된 날짜는 존재할 수 없는 날짜입니다. 올바른 날짜를 입력해 주세요.";
			return false;
		}

		return true;
	}

	// 양의 정수인가?
	is_correct_integer_positive_format.error_msg = "";
	function is_correct_integer_positive_format(number, remove_comma/*false*/)
	{
		if( remove_comma===true )
			number = number.replace(/[,]/g,"");

		// 초기화
		is_correct_integer_positive_format.error_msg = "";

		if(number==="")
			return false;

		let regular_expression = /^\+?([0-9]*)([1-9]{1})([0-9]*)$/;
		if( !regular_expression.test(number) )
		{
			is_correct_integer_positive_format.error_msg = "양의 정수를 입력해 주세요.";
			return false;
		}
		return true;
	}

	// 0보다 크거나 같은 정수인가?
	is_correct_integer_zero_positive_format.error_msg = "";
	function is_correct_integer_zero_positive_format(number, remove_comma/*false*/)
	{
		if( remove_comma===true )
			number = number.replace(/[,]/g,"");

		// 초기화
		is_correct_integer_zero_positive_format.error_msg = "";

		if(number==="")
			return false;

		let regular_expression = /^\+?[0-9]+$/;
		if( !regular_expression.test(number) )
		{
			is_correct_integer_zero_positive_format.error_msg = "0보다 크거나 같은 정수를 입력해 주세요.";
			return false;
		}
		return true;
	}

	// 정수인가?
	is_correct_integer_format.error_msg = "";
	function is_correct_integer_format(number, remove_comma/*false*/)
	{
		if( remove_comma===true )
			number = number.replace(/[,]/g,"");

		// 초기화
		is_correct_integer_format.error_msg = "";

		if(number==="")
			return false;

		let regular_expression = /^[+-]?[0-9]+$/;
		if( !regular_expression.test(number) )
		{
			is_correct_integer_format.error_msg = "정수(음수 포함)를 입력해 주세요.";
			return false;
		}
		return true;
	}

	// 0보다 크거나 같은 실수(부동소수점)인가?
	// 정수도 포함된다.
	is_correct_float_positive_format.error_msg = "";
	function is_correct_float_positive_format(number, remove_comma/*false*/)
	{
		if( remove_comma===true )
			number = number.replace(/[,]/g,"");

		// 초기화
		is_correct_float_positive_format.error_msg = "";

		if(number==="")
			return false;

		let regular_expression = /^\+?([0-9]*)([1-9]{1})([0-9]*)(.[0-9]+)?$/;
		if( !regular_expression.test(number) )
		{
			is_correct_float_positive_format.error_msg = "양의 실수를 입력해 주세요.";
			return false;
		}
		return true;
	}

	// 양의 실수(부동소수점)인가?
	// 정수도 포함된다.
	is_correct_float_zero_positive_format.error_msg = "";
	function is_correct_float_zero_positive_format(number, remove_comma/*false*/)
	{
		if( remove_comma===true )
			number = number.replace(/[,]/g,"");

		// 초기화
		is_correct_float_zero_positive_format.error_msg = "";

		if(number==="")
			return false;

		let regular_expression = /^\+?[0-9]+(.[0-9]+)?$/;
		if( !regular_expression.test(number) )
		{
			is_correct_float_zero_positive_format.error_msg = "0보다 크거나 같은 실수를 입력해 주세요.";
			return false;
		}
		return true;
	}

	// 실수(부동소수점)인가?
	// 정수도 포함된다.
	is_correct_float_format.error_msg = "";
	function is_correct_float_format(number, remove_comma/*false*/)
	{
		if( remove_comma===true )
			number = number.replace(/[,]/g,"");

		// 초기화
		is_correct_float_format.error_msg = "";

		if(number==="")
			return false;

		let regular_expression = /^[+-]?[0-9]+(.[0-9]+)?$/;
		if( !regular_expression.test(number) )
		{
			is_correct_float_format.error_msg = "실수(음수 포함)를 입력해 주세요.";
			return false;
		}
		return true;
	}

	// 아스키코드만으로 되어 있는가?
	// 한글 불허
	is_correct_ascii_format.error_msg = "";
	function is_correct_ascii_format(target_str)
	{
		// 초기화
		is_correct_ascii_format.error_msg = "";

		if(target_str==="")
			return false;

		let regular_expression = /^([\x20-\x7E]+)$/;
		if( !regular_expression.test(target_str) )
		{
			is_correct_ascii_format.error_msg = "아스키코드(한글 불허)만 입력해 주세요.";
			return false;
		}
		return true;
	}

	// 숫자만으로 되어 있는가?
	is_correct_numeric_format.error_msg = "";
	function is_correct_numeric_format(target_str)
	{
		// 초기화
		is_correct_numeric_format.error_msg = "";

		if(target_str==="")
			return false;

		let regular_expression = /^([0-9]+)$/;
		if( !regular_expression.test(target_str) )
		{
			is_correct_numeric_format.error_msg = "숫자만 입력해 주세요.";
			return false;
		}
		return true;
	}

	// 숫자, - 만으로 되어 있는가?
	is_correct_numeric_dash_format.error_msg = "";
	function is_correct_numeric_dash_format(target_str)
	{
		// 초기화
		is_correct_numeric_dash_format.error_msg = "";

		if(target_str==="")
			return false;

		let regular_expression = /^([0-9-]+)$/;
		if( !regular_expression.test(target_str) )
		{
			is_correct_numeric_dash_format.error_msg = "숫자, -만 입력해 주세요.";
			return false;
		}
		return true;
	}

	// 알파벳과 숫자만으로 되어 있는가?
	// allowable_additional_chars: -,_ 등 추가
	is_alpha_numeric_chars.error_msg = "";
	function is_alpha_numeric_chars(target_str,allowable_additional_chars)
	{
		// 초기화
		is_alpha_numeric_chars.error_msg = "";

		if( target_str==='' )
			return true;

		if( typeof allowable_additional_chars==='undefined' )
			allowable_additional_chars = '';

		let format_string = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz" + allowable_additional_chars;
		for(let i=0; i< target_str.length;i++)
		{
			let output = format_string.indexOf(target_str.charAt(i),0);	//해당문자열에 특수문자열이 있는지 확인
			if (output < 0) //특수문자가 없다면 -1을 반환 -1이외의값에 alert 수행후 함수 종료
			{
				if( allowable_additional_chars==='' )
					is_alpha_numeric_chars.error_msg = "알파벳 또는 숫자만 입력해 주세요.";
				else if( allowable_additional_chars==='-_' )
					is_alpha_numeric_chars.error_msg = "알파벳, 숫자, -, _만 입력해 주세요.";
				else if( allowable_additional_chars==='-_,' )
					is_alpha_numeric_chars.error_msg = "알파벳, 숫자, -, _, 콤마(,)만 입력해 주세요.";
				else
					is_alpha_numeric_chars.error_msg = "알파벳, 숫자, "+allowable_additional_chars+" 기호만 입력해 주세요.";
				return false;
			}
		}

		return true;
	}

	// 허용 문자: 영문
	is_correct_alphabet_format.error_msg = "";
	function is_correct_alphabet_format(alphabet,minimum_str_length)
	{
		// 초기화
		is_correct_alphabet_format.error_msg = "";

		if(alphabet==="")
			return false;

		if( typeof minimum_str_length==='number' )
		{
			if( alphabet.length < minimum_str_length )
			{
				is_correct_alphabet_format.error_msg = "최소 "+minimum_str_length+"자 이상 입력해 주세요.";
				return false;
			}
		}

		let regular_expression = /^([a-zA-Z]+)$/;
		if( !regular_expression.test(alphabet) )
		{
			is_correct_alphabet_format.error_msg = "알파벳만 입력해 주세요.";
			return false;
		}
		return true;
	}

	// 허용 문자: 영문,숫자
	// To Do:
	// 동적 정규식 표현:
	//   http://stackoverflow.com/questions/5090103/javascript-regexp-dynamic-generation-from-variables
	//   http://stackoverflow.com/questions/17885855/use-dynamic-variable-string-as-regex-pattern-in-javascript
	is_correct_alpha_numeric_format.error_msg = "";
	function is_correct_alpha_numeric_format(alpha_numeric,minimum_str_length,maximum_str_length)
	{
		// 초기화
		is_correct_alpha_numeric_format.error_msg = "";

		if(alpha_numeric==="")
			return false;

		if( typeof minimum_str_length==='number' )
		{
			if( alpha_numeric.length < minimum_str_length )
			{
				is_correct_alpha_numeric_format.error_msg = "최소 "+minimum_str_length+"자 이상 입력해 주세요.";
				return false;
			}
		}
		if( typeof maximum_str_length==='number' )
		{
			if( alpha_numeric.length > maximum_str_length )
			{
				is_correct_alpha_numeric_format.error_msg = "최대 "+maximum_str_length+"자 이하로 입력해 주세요.";
				return false;
			}
		}

		let regular_expression = /^([0-9a-zA-Z]+)$/;
		if( !regular_expression.test(alpha_numeric) )
		{
			is_correct_alpha_numeric_format.error_msg = "알파벳 또는 숫자만 입력해 주세요.";
			return false;
		}
		return true;
	}


	// 23,24,25 형태(23 형태도 가능)
	is_correct_numeric_list_format.error_msg = "";
	function is_correct_numeric_list_format(str)
	{
		// 초기화
		is_correct_numeric_list_format.error_msg = "";

		if(str==="")
			return false;

		let regular_expression = /^([0-9]+)(,[0-9]+)*$/;
		if( !regular_expression.test(str) )
		{
			is_correct_numeric_list_format.error_msg = "숫자,숫자,숫자,... 형태로 입력해 주세요.(숫자만 입력도 가능)";
			return false;
		}
		return true;
	}

	// 23,24,25 형태(23 형태도 가능)
	is_correct_alpha_numeric_list_format.error_msg = "";
	function is_correct_alpha_numeric_list_format(str)
	{
		// 초기화
		is_correct_alpha_numeric_list_format.error_msg = "";

		if(str==="")
			return false;

		let regular_expression = /^([0-9a-zA-Z]+)(,[0-9a-zA-Z]+)*$/;
		if( !regular_expression.test(str) )
		{
			is_correct_alpha_numeric_list_format.error_msg = "알파벳or숫자,알파벳or숫자,알파벳or숫자,... 형태로 입력해 주세요.(알파벳or숫자만 입력도 가능)";
			return false;
		}
		return true;
	}


	// 한글로만 구성되어 있는가?
	is_correct_hangul_format.error_msg = "";
	function is_correct_hangul_format(hangul)
	{
		// 초기화
		is_correct_hangul_format.error_msg = "";

		if(hangul==="")
			return false;

		let regular_expression = /^([ㄱ-ㅎㅏ-ㅣ가-힣]+)$/;
		if( !regular_expression.test(hangul) )
		{
			is_correct_hangul_format.error_msg = "한글만 입력할 수 있습니다.";
			return false;
		}
		return true;
	}

	// 허용 문자: 한글,영문,숫자
	is_correct_hangul_alpha_numeric_format.error_msg = "";
	function is_correct_hangul_alpha_numeric_format(hangul_alpha_numeric,minimum_str_length,maximum_str_length)
	{
		// 초기화
		is_correct_hangul_alpha_numeric_format.error_msg = "";

		if(hangul_alpha_numeric==="")
			return false;

		if( typeof minimum_str_length==='number' )
		{
			if( hangul_alpha_numeric.length < minimum_str_length )
			{
				is_correct_hangul_alpha_numeric_format.error_msg = "최소 "+minimum_str_length+"자 이상 입력해 주세요.";
				return false;
			}
		}
		if( typeof maximum_str_length==='number' )
		{
			if( hangul_alpha_numeric.length > maximum_str_length )
			{
				is_correct_hangul_alpha_numeric_format.error_msg = "최대 "+maximum_str_length+"자 이하로 입력해 주세요.";
				return false;
			}
		}

		let regular_expression = /^([0-9a-zA-Zㄱ-ㅎㅏ-ㅣ가-힣]+)$/;
		if( !regular_expression.test(hangul_alpha_numeric) )
		{
			is_correct_hangul_alpha_numeric_format.error_msg = "한글, 알파벳, 숫자만 입력해 주세요.";
			return false;
		}
		return true;
	}


	// 허용 문자: 대문자
	is_correct_uppercase_format.error_msg = "";
	function is_correct_uppercase_format(str,minimum_str_length)
	{
		// 초기화
		is_correct_uppercase_format.error_msg = "";

		if(str==="")
			return false;

		if( typeof minimum_str_length==='number' )
		{
			if( str.length < minimum_str_length )
			{
				is_correct_uppercase_format.error_msg = "최소 "+minimum_str_length+"자 이상 입력해 주세요.";
				return false;
			}
		}

		let regular_expression = /^([A-Z]+)$/;
		if( !regular_expression.test(str) )
		{
			is_correct_uppercase_format.error_msg = "대문자만 입력해 주세요.";
			return false;
		}
		return true;
	}

	// 허용 문자: 대문자,공백문자
	// To Do:
	// 동적 정규식 표현:
	//   http://stackoverflow.com/questions/5090103/javascript-regexp-dynamic-generation-from-variables
	//   http://stackoverflow.com/questions/17885855/use-dynamic-variable-string-as-regex-pattern-in-javascript
	is_correct_uppercase_space_format.error_msg = "";
	function is_correct_uppercase_space_format(str,minimum_str_length,maximum_str_length)
	{
		// 초기화
		is_correct_uppercase_space_format.error_msg = "";

		if(str==="")
			return false;

		if( typeof minimum_str_length==='number' )
		{
			if( str.length < minimum_str_length )
			{
				is_correct_uppercase_space_format.error_msg = "최소 "+minimum_str_length+"자 이상 입력해 주세요.";
				return false;
			}
		}
		if( typeof maximum_str_length==='number' )
		{
			if( str.length > maximum_str_length )
			{
				is_correct_uppercase_space_format.error_msg = "최대 "+maximum_str_length+"자 이하로 입력해 주세요.";
				return false;
			}
		}

		let regular_expression = /^([A-Z\s]+)$/;
		if( !regular_expression.test(str) )
		{
			is_correct_uppercase_space_format.error_msg = "대문자 또는 공백문자만 입력해 주세요.";
			return false;
		}
		return true;
	}

	// 허용 문자: 대문자,숫자
	// To Do:
	// 동적 정규식 표현:
	//   http://stackoverflow.com/questions/5090103/javascript-regexp-dynamic-generation-from-variables
	//   http://stackoverflow.com/questions/17885855/use-dynamic-variable-string-as-regex-pattern-in-javascript
	is_correct_uppercase_numeric_format.error_msg = "";
	function is_correct_uppercase_numeric_format(str,minimum_str_length,maximum_str_length)
	{
		// 초기화
		is_correct_uppercase_numeric_format.error_msg = "";

		if(str==="")
			return false;

		if( typeof minimum_str_length==='number' )
		{
			if( str.length < minimum_str_length )
			{
				is_correct_uppercase_numeric_format.error_msg = "최소 "+minimum_str_length+"자 이상 입력해 주세요.";
				return false;
			}
		}
		if( typeof maximum_str_length==='number' )
		{
			if( str.length > maximum_str_length )
			{
				is_correct_uppercase_numeric_format.error_msg = "최대 "+maximum_str_length+"자 이하로 입력해 주세요.";
				return false;
			}
		}

		let regular_expression = /^([0-9A-Z]+)$/;
		if( !regular_expression.test(str) )
		{
			is_correct_uppercase_numeric_format.error_msg = "대문자 또는 숫자만 입력해 주세요.";
			return false;
		}
		return true;
	}

	// 알파벳과 숫자만으로 되어 있는가?
	// allowable_additional_chars: -,_ 등 추가
	is_lowercase_numeric_chars.error_msg = "";
	function is_lowercase_numeric_chars(target_str,allowable_additional_chars)
	{
		// 초기화
		is_lowercase_numeric_chars.error_msg = "";

		if( target_str==='' )
			return true;

		if( typeof allowable_additional_chars==='undefined' )
			allowable_additional_chars = '';

		let format_string = "0123456789abcdefghijklmnopqrstuvwxyz" + allowable_additional_chars;
		for(let i=0; i< target_str.length;i++)
		{
			let output = format_string.indexOf(target_str.charAt(i),0);	//해당문자열에 특수문자열이 있는지 확인
			if (output < 0) //특수문자가 없다면 -1을 반환 -1이외의값에 alert 수행후 함수 종료
			{
				if( allowable_additional_chars==='' )
					is_lowercase_numeric_chars.error_msg = "소문자 또는 숫자만 입력해 주세요.";
				else if( allowable_additional_chars==='-_' )
					is_lowercase_numeric_chars.error_msg = "소문자, 숫자, -, _만 입력해 주세요.";
				else if( allowable_additional_chars==='-_,' )
					is_lowercase_numeric_chars.error_msg = "소문자, 숫자, -, _, 콤마(,)만 입력해 주세요.";
				else
					is_lowercase_numeric_chars.error_msg = "소문자, 숫자, "+allowable_additional_chars+" 기호만 입력해 주세요.";
				return false;
			}
		}

		return true;
	}

	// 허용 문자: 소문자
	is_correct_lowercase_format.error_msg = "";
	function is_correct_lowercase_format(str,minimum_str_length)
	{
		// 초기화
		is_correct_lowercase_format.error_msg = "";

		if(str==="")
			return false;

		if( typeof minimum_str_length==='number' )
		{
			if( str.length < minimum_str_length )
			{
				is_correct_lowercase_format.error_msg = "최소 "+minimum_str_length+"자 이상 입력해 주세요.";
				return false;
			}
		}

		let regular_expression = /^([a-z]+)$/;
		if( !regular_expression.test(str) )
		{
			is_correct_lowercase_format.error_msg = "소문자만 입력해 주세요.";
			return false;
		}
		return true;
	}

	// 허용 문자: 소문자,숫자
	// To Do:
	// 동적 정규식 표현:
	//   http://stackoverflow.com/questions/5090103/javascript-regexp-dynamic-generation-from-variables
	//   http://stackoverflow.com/questions/17885855/use-dynamic-variable-string-as-regex-pattern-in-javascript
	is_correct_lowercase_numeric_format.error_msg = "";
	function is_correct_lowercase_numeric_format(str,minimum_str_length,maximum_str_length)
	{
		// 초기화
		is_correct_lowercase_numeric_format.error_msg = "";

		if(str==="")
			return false;

		if( typeof minimum_str_length==='number' )
		{
			if( str.length < minimum_str_length )
			{
				is_correct_lowercase_numeric_format.error_msg = "최소 "+minimum_str_length+"자 이상 입력해 주세요.";
				return false;
			}
		}
		if( typeof maximum_str_length==='number' )
		{
			if( str.length > maximum_str_length )
			{
				is_correct_lowercase_numeric_format.error_msg = "최대 "+maximum_str_length+"자 이하로 입력해 주세요.";
				return false;
			}
		}

		let regular_expression = /^([0-9a-z]+)$/;
		if( !regular_expression.test(str) )
		{
			is_correct_lowercase_numeric_format.error_msg = "소문자 또는 숫자만 입력해 주세요.";
			return false;
		}
		return true;
	}

	// 이메일 포맷인가?
	// 허용 문자: 영문,숫자,_,-,+,.,@
	// http://emailregex.com/

	const L_EMAIL_ADDRESS_MSG	= {
		"korean"	: "이메일주소 형식을 올바로 입력해 주세요.",
		"english"	: "Please enter the correct email address format."
	};

	is_correct_email_address_format.error_msg = "";
	function is_correct_email_address_format(email_address, strict)
	{
		// 초기화
		is_correct_email_address_format.error_msg = "";

		if(email_address==="")
		{
			is_correct_email_address_format.error_msg = "이메일주소 전달 인자 값이 없습니다.";
			return false;
		}

		let regular_expression = /^(([^<>()[\]\\.,;:\s@"]+(.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}])|(([a-zA-Z-0-9]+.)+[a-zA-Z]{2,}))$/;
		if( !regular_expression.test(email_address) )
		{
			is_correct_email_address_format.error_msg = L_EMAIL_ADDRESS_MSG[m_language];
			return false;
		}

		if( typeof strict==='undefined' )
			strict = true;
		if( strict )
		{
			regular_expression = /^([.0-9a-zA-Z_+-]+)@(([0-9a-z-]+.)+[0-9a-z]{2,})$/;
			if( !regular_expression.test(email_address) )
			{
				is_correct_email_address_format.error_msg = L_EMAIL_ADDRESS_MSG[m_language];
				return false;
			}
		}

		return true;
	}

	is_correct_name_email_address_format.error_msg = "";
	function is_correct_name_email_address_format(name_email_address)
	{
		// 초기화
		is_correct_name_email_address_format.error_msg = "";

		if(name_email_address==="")
		{
			is_correct_name_email_address_format.error_msg = "\"이름\"<이메일주소> 전달 인자 값이 없습니다.";
			return false;
		}

		let regular_expression = /^"(.*)"<([.0-9a-zA-Z_+-]+)@(([0-9a-z-]+.)+[0-9a-z]{2,})>$/;
		if( !regular_expression.test(name_email_address) )
		{
			is_correct_name_email_address_format.error_msg = "\"이름\"<이메일주소> 형식을 올바로 입력해 주세요.";
			return false;
		}

		return true;
	}

	// 계정(일반) 형식인가?
	// 허용 문자: 영문,숫자,_,-,.
	is_correct_account_general_format.error_msg = "";
	function is_correct_account_general_format(account_general)
	{
		// 초기화
		is_correct_account_general_format.error_msg = "";

		if(account_general==="")
			return false;

		let regular_expression = /^([.0-9a-z_-]+)$/;
		if( !regular_expression.test(account_general) )
		{
			is_correct_account_general_format.error_msg = "계정 형식(숫자, 소문자, _, -, . 허용)을 입력해 주세요.";
			return false;
		}
		return true;
	}

	// 외부 계정 형식인가?
	// 허용 문자: 영문,숫자,_,-,+,.,@
	// 형태: 계정@@업체코드
	is_correct_account_login_service_format.error_msg = "";
	function is_correct_account_login_service_format(account_login_service)
	{
		// 초기화
		is_correct_account_login_service_format.error_msg = "";

		if(account_login_service==="")
			return false;

		let regular_expression = /^([.0-9a-zA-Z_+-@]{2,})@@([0-9a-z]{2,})$/;
		if( !regular_expression.test(account_login_service) )
		{
			is_correct_account_login_service_format.error_msg = "외부 계정 형식을 입력해 주세요.";
			return false;
		}
		return true;
	}

	// 이메일 또는 외부 계정 형식인가?
	// 허용 문자: 영문,숫자,_,-,+,.,@
	// 형태: 이메일 또는 계정@@업체코드
	is_correct_account_email_or_login_service_format.error_msg = "";
	function is_correct_account_email_or_login_service_format(account_id)
	{
		// 초기화
		is_correct_account_email_or_login_service_format.error_msg = "";

		if(account_id==="")
			return false;

		let regular_expression = /(^([.0-9a-zA-Z_+-]+)@(([0-9a-z-]+.)+[0-9a-z]{2,}))|(^([.0-9a-zA-Z_+-@]{2,})@@([0-9a-z]{2,}))$/;
		if( !regular_expression.test(account_id) )
		{
			is_correct_account_email_or_login_service_format.error_msg = "이메일 또는 외부 계정 형식을 입력해 주세요.";
			return false;
		}
		return true;
	}

	// 도메인 포맷인가?
	// 허용 문자: 영문,숫자,_,-,+,.,@
	is_correct_domain_format.error_msg = "";
	function is_correct_domain_format(domain)
	{
		// 초기화
		is_correct_domain_format.error_msg = "";

		if(domain==="")
			return false;

		let regular_expression = /^(([0-9a-zA-Z-]+.)+[0-9a-zA-Z]{2,})$/;
		if( !regular_expression.test(domain) )
		{
			is_correct_domain_format.error_msg = "도메인 형식을 입력해 주세요.";
			return false;
		}
		return true;
	}

	// IP주소 포맷인가?
	is_correct_ip_address_format.error_msg = "";
	function is_correct_ip_address_format(ip_address)
	{
		// 초기화
		is_correct_ip_address_format.error_msg = "";

		if(ip_address==="")
			return false;

		let regular_expression = /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?).){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
		if( !regular_expression.test(ip_address) )
		{
			is_correct_ip_address_format.error_msg = "IP 주소 형식을 입력해 주세요.";
			return false;
		}
		return true;
	}

	// IP주소 포맷인가?
	is_correct_ip_address_filter_format.error_msg = "";
	function is_correct_ip_address_filter_format(ip_address)
	{
		// 초기화
		is_correct_ip_address_filter_format.error_msg = "";

		if(ip_address==="")
			return false;

		let regular_expression = /^(?:(?:\*|25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?).){3}(?:\*|25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
		if( !regular_expression.test(ip_address) )
		{
			is_correct_ip_address_filter_format.error_msg = "IP 주소 또는 IP 주소 필터 값을 올바로 입력해 주세요.";
			return false;
		}
		return true;
	}

	// 올바른 파일명인지 검사한다.
	// target_str: 검사하고자 하는 파일명
	// unavailable_chars: 추가로 막고자 하는 문자들 ( , ' 공백 & )
	// 허용 불가 문자: \, /, :, *, ?, ", <, >, |
	is_available_file_name.error_msg = "";
	function is_available_file_name(target_str, unavailable_chars)
	{
		// 초기화
		is_available_file_name.error_msg = "";

		if (target_str==="")
			return false;

		if( typeof unavailable_chars==='string' && unavailable_chars.length > 0 )
		{
			for(let i=0 ; i < unavailable_chars.length ; i++)
			{
				if (target_str.indexOf(unavailable_chars.charAt(i),0) >= 0)
				{
					is_available_file_name.error_msg = "올바른 파일명을 입력해 주세요.";
					return false;
				}
			}
		}

		let regular_expression = /^[^\\/:*?"<>|]+$/;
		if( !regular_expression.test(target_str) )
		{
			is_available_file_name.error_msg = "올바른 파일명을 입력해 주세요.";
			return false;
		}
		return true;
	}

	// 올바른 파일경로인지 검사한다.
	// target_str: 검사하고자 하는 파일명
	// unavailable_chars: 추가로 막고자 하는 문자들 ( , ' 공백 & )
	// 허용 불가 문자: \, /, :, *, ?, ", <, >, |
	is_available_strict_file_path.error_msg = "";
	function is_available_strict_file_path(target_str)
	{
		// 초기화
		is_available_strict_file_path.error_msg = "";

		if (target_str==="")
			return false;

		let regular_expression = /^[/]?([a-zA-Z_0-9.-]+[/]?)*$/;
		if( !regular_expression.test(target_str) )
		{
			is_available_strict_file_path.error_msg = "올바른 경로를 입력해 주세요.";
			return false;
		}
		return true;
	}

	// 단순한 URL 형식인가?
	is_correct_simple_url_format.error_msg = "";
	function is_correct_simple_url_format(simple_url)
	{
		// 초기화
		is_correct_simple_url_format.error_msg = "";

		if(simple_url==="")
			return false;

		let regular_expression = /^(https?:\/\/)?([\da-z.-]+).([a-z.]{2,6})([/\w_.-]*)*\/?$/;
		if( !regular_expression.test(simple_url) )
		{
			is_correct_simple_url_format.error_msg = "단순 URL 형식을 입력해 주세요.";
			return false;
		}
		return true;
	}

	// 표준 URL 형식인가?
	is_correct_standard_url_format.error_msg = "";
	function is_correct_standard_url_format(standard_url)
	{
		// 초기화
		is_correct_standard_url_format.error_msg = "";

		if(standard_url==="")
			return false;

		let regular_expression = /(((http|ftp|https):\/{2})+(([0-9a-z_-]+.)+([a-z]{2,})(:[0-9]+)?((\/([~0-9a-zA-Z#+%@./_-]+))?(\?[0-9a-zA-Z,+%@/&[\];=_-]+)?)?))\b$/i; // /imuS
		if( !regular_expression.test(standard_url) )
		{
			is_correct_standard_url_format.error_msg = "표준 URL 형식을 입력해 주세요.";
			return false;
		}
		return true;
	}

	// 표준 HTTPS URL 형식인가?
	is_correct_secure_standard_url_format.error_msg = "";
	function is_correct_secure_standard_url_format(standard_url)
	{
		// 초기화
		is_correct_secure_standard_url_format.error_msg = "";

		if(standard_url==="")
			return false;

		let regular_expression = /((https:\/{2})+(([0-9a-z_-]+.)+([a-z]{2,})(:[0-9]+)?((\/([~0-9a-zA-Z#+%@./_-]+))?(\?[0-9a-zA-Z,+%@/&[\];=_-]+)?)?))\b$/i; // /imuS
		if( !regular_expression.test(standard_url) )
		{
			is_correct_secure_standard_url_format.error_msg = "표준 HTTPS URL 형식을 입력해 주세요.";
			return false;
		}
		return true;
	}

	// 표준 URL 형식인가?
	is_correct_general_url_format.error_msg = "";
	function is_correct_general_url_format(general_url)
	{
		// 초기화
		is_correct_general_url_format.error_msg = "";

		if(general_url==="")
			return false;

		let regular_expression = /(((http|ftp|https):\/{2})+(([0-9a-z_-]+.)+([a-z]{2,})(:[0-9]+)?((\/([~0-9a-zA-Z#+%@./_-]+))?(\?[0-9a-zA-Z,+%@/&[\];=_-]+)?)?))\b$/i; // /imuS
		if( !regular_expression.test(general_url) )
		{
			is_correct_general_url_format.error_msg = "표준 URL 형식을 입력해 주세요.";
			return false;
		}
		return true;
	}

	// 16진수 색상 형식인가?
	is_correct_hex_color_format.error_msg = "";
	function is_correct_hex_color_format(hex_color)
	{
		// 초기화
		is_correct_hex_color_format.error_msg = "";

		if(hex_color==="")
			return false;

		let regular_expression = /^#?([a-f0-9]{6}|[a-f0-9]{3})$/;
		if( !regular_expression.test(hex_color) )
		{
			is_correct_hex_color_format.error_msg = "'#33aa33' 또는 '#3a3' 형식으로 16진수 색상을 입력해 주세요.";
			return false;
		}
		return true;
	}

	// 16진수 색상 형식인가?
	is_correct_color_picker_format.error_msg = "";
	function is_correct_color_picker_format(color_picker)
	{
		// 초기화
		is_correct_color_picker_format.error_msg = "";

		if(color_picker==="")
			return false;

		let regular_expression = /^#?([a-f0-9]{6}|[a-f0-9]{3})$/;
		if( !regular_expression.test(color_picker) )
		{
			is_correct_color_picker_format.error_msg = "'#33aa33' 또는 '#3a3' 형식으로 16진수 색상을 입력해 주세요.";
			return false;
		}
		return true;
	}

	// HTML 태그 형식인가?
	is_correct_html_tag_format.error_msg = "";
	function is_correct_html_tag_format(html_tag)
	{
		// 초기화
		is_correct_html_tag_format.error_msg = "";

		if(html_tag==="")
			return false;

		let regular_expression = /^<([a-z]+)([^<]+)*(?:>(.*)<\/\1>|\s+\/>)$/;
		if( !regular_expression.test(html_tag) )
		{
			is_correct_html_tag_format.error_msg = "HTML 태그 형식을 입력해 주세요.";
			return false;
		}
		return true;
	}

	// target_str에 특수문자가 포함되어 있는지 검사한다.
	// 특수문자 중에서 exception_chars에 포함된 문자는 제외된다.
	// 사용 예)
	//   if( !is_normal_chars("gadje@paran.com","@") )
	//       alert(is_normal_chars.error_msg);
	//       alert("특수문자 "+special_char+"를 사용하실 수 없습니다.");
	//
	is_normal_chars.special_char= "";
	is_normal_chars.error_msg	= "";
	function is_normal_chars(target_str,exception_chars)
	{
		// 초기화
		is_normal_chars.special_char= "";
		is_normal_chars.error_msg	= "";

		if (target_str==="")
		{
			is_normal_chars.special_char= "";
			is_normal_chars.error_msg	= "전달된 문자열이 빈값입니다.";
			return false;
		}

		// 1. 파일명 불가 특수문자
		let arr_special_1 = ["\\","/",":","*","?","\"","<",">","|"];
		// 2. 기타 특수문자
		let arr_special_2 = ["~","!","@","#","$","%","^","&","(",")","=","+","`","'",";"];

		// 1. 파일명 불가 특수문자 검사
		for( let i=0 ; i < arr_special_1.length ; i++)
		{
			let special_1_index = target_str.indexOf(arr_special_1[i],0);
			if( special_1_index !== -1 ) // 특수문자가 있다면
			{
				// 만일 예외 특수문자가 있고 여기에 해당된다면 그냥 지나친다.
				if( exception_chars!=="" && exception_chars.indexOf(arr_special_1[i],0) !== -1 )
					continue;

				is_normal_chars.special_char = arr_special_1[i];
				is_normal_chars.error_msg	= "특수문자 "+arr_special_1[i]+"를 사용하실 수 없습니다.";
				return false;
			}
		}
		// 2. 기타 특수문자 검사
		for( let i=0 ; i < arr_special_2.length ; i++)
		{
			let special_2_index = target_str.indexOf(arr_special_2[i],0);
			if( special_2_index !== -1 ) // 특수문자가 있다면
			{
				// 만일 예외 특수문자가 있고 여기에 해당된다면 그냥 지나친다.
				if( exception_chars!=="" && exception_chars.indexOf(arr_special_2[i],0) !== -1 )
					continue;

				is_normal_chars.special_char = arr_special_2[i];
				is_normal_chars.error_msg	= "특수문자 "+arr_special_2[i]+"를 사용하실 수 없습니다.";
				return false;
			}
		}

		return true;
	}

	// 폰번호 체크 (숫자)
	// 01040127336, 010-4012-7336 모두 허용
	is_correct_phone_num_format.error_msg = "";
	function is_correct_phone_num_format(phone_num, allow_global_format/*false*/, allow_foreign_phone_num/*false*/, dash_mandatory/*false*/, dash_disallow/*false*/)
	{
		// 초기화
		is_correct_phone_num_format.error_msg = "";
		if(phone_num==="")
			return false;

		if( typeof allow_global_format!=='boolean' )
			allow_global_format = false;

		if( typeof allow_foreign_phone_num!=='boolean' )
			allow_foreign_phone_num = false;

		if( typeof dash_mandatory!=='boolean' )
			dash_mandatory = false;

		if( typeof dash_disallow!=='boolean' )
			dash_disallow = false;

		// 국내 번호 형식(- 필수)
		if( dash_mandatory || dash_disallow )
		{
			if( __is_local_format_of_korean_phone_num(phone_num,dash_mandatory,dash_disallow) )
				return true;

			is_correct_phone_num_format.error_msg = __is_local_format_of_korean_phone_num.error_msg;
			return false;
		}

		// 국내 번호 형식
		if( __is_local_format_of_korean_phone_num(phone_num,dash_mandatory,dash_disallow) )
			return true;

		// 글로벌 번호 형식
		if( allow_global_format )
		{
			if( __is_global_format_of_korean_phone_num(phone_num) )
				return true;

			if( __is_global_format_of_phone_num(phone_num) )
				return true;
		}

		// 외국 번호 형식
		if( allow_foreign_phone_num )
		{
			if( __is_local_format_of_foreign_phone_num(phone_num) )
				return true;
		}

		is_correct_phone_num_format.error_msg = "올바른 핸드폰번호 형식을 입력해 주세요.";
		return false;
	}

	// 국가별 코드를 고려한 폰번호 형식 검사
	is_correct_global_phone_num_format.error_msg = "";
	function is_correct_global_phone_num_format(msg_country_code,phone_num)
	{
		// 초기화
		is_correct_global_phone_num_format.error_msg = "";
		if(phone_num==="")
			return false;

		if( msg_country_code==="KR" || msg_country_code==="" ) // MsgCountryCode::KOREA
		{
			if( __is_local_format_of_korean_phone_num(phone_num) )
				return true;

			is_correct_global_phone_num_format.error_msg = "올바른 핸드폰번호 형식을 입력해 주세요.";
			return false;
		}
		else
		{
			if( __is_local_format_of_foreign_phone_num(phone_num) )
				return true;

			is_correct_global_phone_num_format.error_msg = "올바른 폰번호 형식을 입력해 주세요.";
			return false;
		}
	}

	// 폰번호 체크 (숫자)
	// 01040127336, 010-4012-7336 모두 허용
	// dash_mandatory : - 필수 여부(디폴트 false)
	// dash_disallow  : - 사용불가 여부(디폴트 false)
	function __is_local_format_of_korean_phone_num(phone_num, dash_mandatory/*false*/, dash_disallow/*false*/)
	{
		// 초기화
		__is_local_format_of_korean_phone_num.error_msg = "";
		if(phone_num==="")
			return false;

		// 디폴트 초기화
		if( typeof dash_mandatory!=="boolean" )
			dash_mandatory = false;

		if( typeof dash_disallow!=="boolean" )
			dash_disallow = false;

		// - 필수 검사
		if( dash_mandatory )
		{
			let regular_expression = /^01([0|1|6|7|8|9]{1})-([0-9]{3,4})-([0-9]{4})$/;
			if( !regular_expression.test(phone_num) )
			{
				__is_local_format_of_korean_phone_num.error_msg = "올바른 핸드폰번호 형식(- 필수)을 입력해 주세요.";
				return false;
			}
		}
		// - 사용 불허
		else if( dash_disallow )
		{
			let regular_expression = /^01([0|1|6|7|8|9]{1})([0-9]{7,8})$/;
			if( !regular_expression.test(phone_num) )
			{
				__is_local_format_of_korean_phone_num.error_msg = "올바른 핸드폰번호 형식(- 사용불가)을 입력해 주세요.";
				return false;
			}
		}
		else
		{
			let regular_expression = /^01([0|1|6|7|8|9]{1})-?([0-9]{3,4})-?([0-9]{4})$/;
			if( !regular_expression.test(phone_num) )
			{
				__is_local_format_of_korean_phone_num.error_msg = "올바른 핸드폰번호 형식을 입력해 주세요.";
				return false;
			}
		}

		return true;
	}

	// +821072019595
	function __is_global_format_of_korean_phone_num(phone_num)
	{
		// 초기화
		__is_global_format_of_korean_phone_num.error_msg = "";
		if(phone_num==="")
			return false;

		let regular_expression = /^\+?821([0|1|6|7|8|9]{1})-?([0-9]{3,4})-?([0-9]{4})$/;
		if( !regular_expression.test(phone_num) )
		{
			__is_global_format_of_korean_phone_num.error_msg = "올바른 핸드폰번호 형식을 입력해 주세요.";
			return false;
		}
		return true;
	}

	// 미국: 1~
	// 한국: 82~
	// 자메이카: 1876~
	// +821072019595
	// 참고: http://codingdojang.com/scode/354
	//       https://github.com/googlei18n/libphonenumber
	function __is_global_format_of_phone_num(phone_num)
	{
		// 초기화
		__is_global_format_of_phone_num.error_msg = "";
		if(phone_num==="")
			return false;

		let regular_expression = /^\+?([1-9]{1})([0-9]{1,14})$/;
		if( !regular_expression.test(phone_num) )
		{
			__is_global_format_of_phone_num.error_msg = "올바른 국제전화번호 형식을 입력해 주세요.";
			return false;
		}
		return true;
	}

	// 외국 전화번호
	function __is_local_format_of_foreign_phone_num(phone_num)
	{
		// 초기화
		__is_local_format_of_foreign_phone_num.error_msg = "";
		if(phone_num==="")
			return false;

		let regular_expression = /^([0-9]{2,14})$/;
		if( !regular_expression.test(phone_num) )
		{
			__is_local_format_of_foreign_phone_num.error_msg = "올바른 전화번호 형식을 입력해 주세요.";
			return false;
		}
		return true;
	}


	// 일반전화번호 체크 (숫자)
	// 021231234, 02-123-1234, 1522-1457 모두 허용
	is_correct_telephone_format.error_msg = "";
	function is_correct_telephone_format(telephone, dash_mandatory)
	{
		// 초기화
		is_correct_telephone_format.error_msg = "";

		if(telephone==="")
			return false;

		if( dash_mandatory===true )
		{
			let regular_expression = /^0\d{1,3}-\d{3,4}-\d{4}$/;
			if( regular_expression.test(telephone) )
				return true;

			regular_expression = /^\d{3,4}-\d{4}$/;
			if( regular_expression.test(telephone) )
				return true;

			is_correct_telephone_format.error_msg = "전화번호 형식(- 필수)을 입력해 주세요.";
			return false;
		}
		else
		{
			let regular_expression = /^0\d{1,3}-?\d{3,4}-?\d{4}$/;
			if( regular_expression.test(telephone) )
				return true;

			regular_expression = /^\d{3,4}-?\d{4}$/;
			if( regular_expression.test(telephone) )
				return true;

			is_correct_telephone_format.error_msg = "전화번호 형식을 입력해 주세요.";
			return false;
		}

		// return true;
	}

	// 010xxxxyyyy -> 010-xxxx-yyyy
	// 010xxxyyyy  -> 010-xxx-yyyy
	function get_split_phone_num(phone_num)
	{
		// 비정상 데이터이면 그대로 반환
		if( !is_correct_phone_num_format(phone_num) )
			return phone_num;

		if( phone_num.length===10 )
		{
			let phone_num_1 = phone_num.substr(0,3);
			let phone_num_2 = phone_num.substr(3,3);
			let phone_num_3 = phone_num.substr(6,4);
			return phone_num_1+"-"+phone_num_2+"-"+phone_num_3;
		}
		else // if( phone_num.length===11 )
		{
			let phone_num_1 = phone_num.substr(0,3);
			let phone_num_2 = phone_num.substr(3,4);
			let phone_num_3 = phone_num.substr(7);
			return phone_num_1+"-"+phone_num_2+"-"+phone_num_3;
		}
	}

	// 인증번호 체크 (숫자)
	is_correct_auth_num_format.error_msg = "";
	function is_correct_auth_num_format(auth_num)
	{
		// 초기화
		is_correct_auth_num_format.error_msg = "";

		if(auth_num==="" || auth_num.length!==6)
			return false;

		let regular_expression = /^[0-9]{6}$/;
		if( !regular_expression.test(auth_num) )
		{
			is_correct_auth_num_format.error_msg = "인증번호(6자리 숫자)를 올바로 입력해 주세요.";
			return false;
		}
		return true;
	}

	// 생년월일 체크 (숫자)
	// 예) 710604
	is_correct_birthday_format_6.error_msg = "";
	function is_correct_birthday_format_6(str)
	{
		// 초기화
		is_correct_birthday_format_6.error_msg = "";

		if(str==="")
			return false;

		let regular_expression = /^([0-9]{2})(01|02|03|04|05|06|07|08|09|10|11|12)((0([1-9]{1}))|((1|2)([0-9]{1}))|3(0|1))$/;
		if( !regular_expression.test(str) )
		{
			is_correct_birthday_format_6.error_msg = "생년월일(6자리 숫자)을 올바로 입력해 주세요.";
			return false;
		}
		return true;
	}

	// 생년월일 체크 (숫자)
	// 예) 19710604
	is_correct_birthday_format_8.error_msg = "";
	function is_correct_birthday_format_8(str)
	{
		// 초기화
		is_correct_birthday_format_8.error_msg = "";

		if(str==="")
			return false;

		let regular_expression = /^(1|2)([0-9]{3})(01|02|03|04|05|06|07|08|09|10|11|12)((0([1-9]{1}))|((1|2)([0-9]{1}))|3(0|1))$/;
		if( !regular_expression.test(str) )
		{
			is_correct_birthday_format_8.error_msg = "생년월일(8자리 숫자)을 올바로 입력해 주세요.";
			return false;
		}

		// 날짜 값이 유효한지도 검사한다.
		let birthday = get_split_date(str);
		if( !checkdate(birthday) )
		{
			is_correct_birthday_format_8.error_msg = "입력된 생년월일은 존재할 수 없는 날짜입니다. 올바른 날짜를 입력해 주세요.";
			return false;
		}

		return true;
	}

	// 앱 버전 체크
	// 예) 2.0.40
	is_correct_app_version_format.error_msg = "";
	function is_correct_app_version_format(str)
	{
		// 초기화
		is_correct_app_version_format.error_msg = "";

		if(str==="")
			return false;

		let regular_expression = /^([0-9]{1,}).([0-9]{1,}).([0-9]{1,})$/;
		if( !regular_expression.test(str) )
		{
			is_correct_app_version_format.error_msg = "앱 버전을 올바로 입력해 주세요.";
			return false;
		}

		return true;
	}

	// 주민등록번호
	// 예) 701109-xxxxxxx
	// http://blog.naver.com/PostView.nhn?blogId=javaking75&logNo=220083169851
	is_correct_social_security_number.error_msg = "";
	function is_correct_social_security_number(str)
	{
		// 초기화
		is_correct_social_security_number.error_msg = "";

		if(str==="")
			return false;

		// /^(?:[0-9]{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[1,2][0-9]|3[0,1]))-[1-4][0-9]{6}$/
		let regular_expression = /^([0-9]{2})(01|02|03|04|05|06|07|08|09|10|11|12)((0([1-9]{1}))|((1|2)([0-9]{1}))|3(0|1))-?[1-4][0-9]{6}$/;
		if( !regular_expression.test(str) )
		{
			is_correct_social_security_number.error_msg = "주민등록번호를 올바로 입력해 주세요.";
			return false;
		}
		return true;
	}

	// 주민등록번호
	// 701109xxxxxxx => 701109-xxxxxxx
	// 주민번호가 올바르다고 가정한다.
	function get_split_social_security_number(str)
	{
		// - 기호가 있으면 그대로 반환
		if( str.indexOf('-',0)>0 )
		{
			return str;
		}
		else
		{
			let first_part  = str.substr(0,6);
			let second_part = str.substr(6,7);
			return first_part+"-"+second_part;
		}
	}

	// 사업자등록번호
	// 예) 121-81-81537
	is_correct_corporate_registration_number.error_msg = "";
	function is_correct_corporate_registration_number(str)
	{
		// 초기화
		is_correct_corporate_registration_number.error_msg = "";

		if(str==="")
			return false;

		let regular_expression = /^([0-9]{3})-?([0-9]{2})-?([0-9]{5})$/;
		if( !regular_expression.test(str) )
		{
			is_correct_corporate_registration_number.error_msg = "사업자등록번호를 올바로 입력해 주세요.";
			return false;
		}

		str = str.replace("-","");

		const checkBizID = function(bizID){
			// bizID는 숫자만 10자리로 해서 문자열로 넘긴다.
			let checkID = [1, 3, 7, 1, 3, 7, 1, 3, 5, 1];
			let i, chkSum=0, c2, remander;
			bizID = bizID.replace(/-/gi,'');

			for (i=0; i<=7; i++) chkSum += checkID[i] * bizID.charAt(i);
			c2 = "0" + (checkID[8] * bizID.charAt(8));
			c2 = c2.substring(c2.length - 2, c2.length);
			chkSum += Math.floor(c2.charAt(0)) + Math.floor(c2.charAt(1));
			remander = (10 - (chkSum % 10)) % 10 ;

			return (Math.floor(bizID.charAt(9))===remander);
		};

		if( !checkBizID(str) )
		{
			is_correct_corporate_registration_number.error_msg = "사업자등록번호를 올바로 입력해 주세요.";
			return false;
		}
		return true;
	}

	// 사업자등록번호
	// 1218181537 => 121-81-81537
	// 사업자등록번호가 올바르다고 가정한다.
	function get_split_corporate_registration_number(str)
	{
		// - 기호가 있으면 그대로 반환
		if( str.indexOf('-',0)>0 )
		{
			return str;
		}
		else
		{
			let first_part  = str.substr(0,3);
			let second_part = str.substr(3,2);
			let third_part  = str.substr(5,5);
			return first_part+"-"+second_part+"-"+third_part;
		}
	}

	// 법인번호
	// 예) 110111-006243
	is_correct_corporation_number.error_msg = "";
	function is_correct_corporation_number(str)
	{
		// 초기화
		is_correct_corporation_number.error_msg = "";

		if(str==="")
			return false;

		let regular_expression = /^([0-9]{6})-?([0-9]{6})$/;
		if( !regular_expression.test(str) )
		{
			is_correct_corporation_number.error_msg = "법인등록번호를 올바로 입력해 주세요.";
			return false;
		}

		str = str.replace("-","");

		const checkCorpID = function(sRegNo){
			// let re = /-/g;
			sRegNo = sRegNo.replace('-', '');
			if (sRegNo.length !== 13) {
				return false;
			}
			let arr_regno = sRegNo.split("");
			let arr_wt = [1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2];
			let iSum_regno = 0;
			let iCheck_digit = 0;
			for (let i = 0; i < 12; i++) {
				// eslint-disable-next-line no-eval
				iSum_regno += eval(arr_regno[i]) * eval(arr_wt[i]);
			}
			iCheck_digit = 10 - (iSum_regno % 10);
			iCheck_digit = iCheck_digit % 10;
			if (iCheck_digit !== arr_regno[12]) {
				return false;
			}
			return true;
		};

		if( !checkCorpID(str) )
		{
			is_correct_corporation_number.error_msg = "법인등록번호를 올바로 입력해 주세요.";
			return false;
		}
		return true;
	}

	// 허용 문자: 한글,영문,공백문자
	is_correct_user_name_format.error_msg = "";
	function is_correct_user_name_format(user_name, perfect_hangul_type, minimum_str_length, maximum_str_length)
	{
		// 초기화
		is_correct_user_name_format.error_msg = "";

		if(user_name==="")
			return false;

		if( typeof minimum_str_length==='number' )
		{
			if( user_name.length < minimum_str_length )
			{
				is_correct_user_name_format.error_msg = "최소 "+minimum_str_length+"자 이상 입력해 주세요.";
				return false;
			}
		}
		if( typeof maximum_str_length==='number' )
		{
			if( user_name.length > maximum_str_length )
			{
				is_correct_user_name_format.error_msg = "최대 "+maximum_str_length+"자 이하로 입력해 주세요.";
				return false;
			}
		}

		let regular_expression = /^([a-zA-Z\sㄱ-ㅎㅏ-ㅣ가-힣]+)$/;
		if( !regular_expression.test(user_name) )
		{
			is_correct_user_name_format.error_msg = "한글, 알파벳, 공백문자만 입력해 주세요.";
			return false;
		}

		if( perfect_hangul_type===true )
		{
			if( !is_perfect_hangul_type(user_name) )
			{
				is_correct_user_name_format.error_msg = is_perfect_hangul_type.error_msg; // 문자 'ㄱ'를 사용하실 수 없습니다.
				return false;
			}
		}

		return true;
	}


	// 허용 문자: 한글, 알파벳, 숫자, -, 공백문자
	is_correct_hangul_alpha_numeric_dash_space_format.error_msg = "";
	function is_correct_hangul_alpha_numeric_dash_space_format(str_val, perfect_hangul_type, minimum_str_length, maximum_str_length)
	{
		// 초기화
		is_correct_hangul_alpha_numeric_dash_space_format.error_msg = "";

		if(str_val==="")
			return false;

		if( typeof minimum_str_length==='number' )
		{
			if( str_val.length < minimum_str_length )
			{
				is_correct_hangul_alpha_numeric_dash_space_format.error_msg = "최소 "+minimum_str_length+"자 이상 입력해 주세요.";
				return false;
			}
		}
		if( typeof maximum_str_length==='number' )
		{
			if( str_val.length > maximum_str_length )
			{
				is_correct_hangul_alpha_numeric_dash_space_format.error_msg = "최대 "+maximum_str_length+"자 이하로 입력해 주세요.";
				return false;
			}
		}

		let regular_expression = /^([0-9-a-zA-Z\sㄱ-ㅎㅏ-ㅣ가-힣]+)$/;
		if( !regular_expression.test(str_val) )
		{
			is_correct_hangul_alpha_numeric_dash_space_format.error_msg = "한글, 알파벳, 숫자, -, 공백문자만 입력해 주세요.";
			return false;
		}

		if( perfect_hangul_type===true )
		{
			if( !is_perfect_hangul_type(str_val) )
			{
				is_correct_hangul_alpha_numeric_dash_space_format.error_msg = is_perfect_hangul_type.error_msg; // 문자 'ㄱ'를 사용하실 수 없습니다.
				return false;
			}
		}

		return true;
	}

	// 숫자 범위 체크
	// 예) 2-40
	is_correct_numeric_range_format.error_msg = "";
	function is_correct_numeric_range_format(str)
	{
		// 초기화
		is_correct_numeric_range_format.error_msg = "";

		if(str==="")
			return false;

		let regular_expression = /^([0-9]{1,})-([0-9]{1,})$/;
		if( !regular_expression.test(str) )
		{
			is_correct_numeric_range_format.error_msg = "숫자 범위(xx-yy)를 올바로 입력해 주세요.";
			return false;
		}

		return true;
	}

	// 아스키+한글
	is_correct_address_user_input_format.error_msg = "";
	function is_correct_address_user_input_format(target_str)
	{
		// 초기화
		is_correct_address_user_input_format.error_msg = "";

		if(target_str==="")
			return false;

		let regular_expression = /^([\x20-\x7Eㄱ-ㅎㅏ-ㅣ가-힣]+)$/;
		if( !regular_expression.test(target_str) )
		{
			is_correct_address_user_input_format.error_msg = "주소를 올바로 입력해 주세요.\n허용문자: 한글, 영문, 숫자, 일반 기호, 공백";
			return false;
		}
		return true;
	}


	// 20130501 → 2013-05-01
	function get_split_date(source_date)
	{
		// 연속 8자리 숫자 확인
		let regular_expression = /^([0-9]{8})$/;
		if( !regular_expression.test(source_date) )
			return source_date;

		let year  = source_date.substr(0,4);
		let month = source_date.substr(4,2);
		let day   = source_date.substr(6,2);
		return year+"-"+month+"-"+day;
	}

	///////////////////////////////////////////////////////////////////////////////////////////////

	// target_str에 자음,모음 문자가 포함되어 있는지 검사한다.
	// 사용 예)
	//   if( !is_perfect_hangul_type("한글 문자열") )
	//       alert(is_perfect_hangul_type.error_msg);
	is_perfect_hangul_type.special_char = "";
	is_perfect_hangul_type.error_msg	= "";
	function is_perfect_hangul_type(target_str)
	{
		// 초기화
		is_perfect_hangul_type.special_char = "";
		is_perfect_hangul_type.error_msg	= "";

		if (target_str==="")
		{
			is_perfect_hangul_type.special_char = "";
			is_perfect_hangul_type.error_msg	= "전달된 문자열이 빈값입니다.";
			return false;
		}

		// 불가 문자: 자음, 모음
		let arr_special = ["ㄱ","ㄴ","ㄷ","ㄹ","ㅁ","ㅂ","ㅅ","ㅇ","ㅈ","ㅊ","ㅋ","ㅌ","ㅍ","ㅎ","ㄲ","ㄸ","ㅃ","ㅆ","ㅉ","ᆞ","ㅏ","ㅑ","ㅗ","ㅛ","ㅜ","ㅠ","ㅡ","ㅣ","ㅢ","ㅚ","ㅟ","ㅙ"];

		// 불가 특수문자 검사
		for( let i=0 ; i < arr_special.length ; i++)
		{
			let special_index = target_str.indexOf(arr_special[i],0);
			if( special_index !== -1 ) // 특수문자가 있다면
			{
				is_perfect_hangul_type.special_char = arr_special[i];
				is_perfect_hangul_type.error_msg	= "문자 '"+arr_special[i]+"'를 사용하실 수 없습니다.";
				return false;
			}
		}

		return true;
	}


	// 올바른 hex 포맷인가?
	function is_correct_hex_format(hex,str_length)
	{
		// 초기화
		is_correct_hex_format.error_msg = "";

		if(hex==="")
			return false;

		if( typeof str_length==='number' )
		{
			if( hex.length !== str_length )
			{
				is_correct_hex_format.error_msg = str_length+"자 길이로 입력해 주세요.";
				return false;
			}
		}

		let regular_expression = /^([0-9a-fA-F]+)$/;
		if( !regular_expression.test(hex) )
		{
			is_correct_hex_format.error_msg = "0~9,a~f,A~F 글자만 입력해 주세요.";
			return false;
		}
		return true;
	}

	///////////////////////////////////////////////////////////////////////////////////////////////

	// subjectString 문자열이 searchString 로 시작하는가?
	function startsWith(subjectString, searchString/*, position*/)
	{
		if( typeof subjectString!=='string' )
			return false;

		//position = position || 0;
		let position = 0;
		return (subjectString.substr(position, searchString.length) === searchString);
	}

	// subjectString 문자열이 searchString 로 끝나는가?
	function endsWith(subjectString, searchString/*, position*/)
	{
		if( typeof subjectString!=='string' )
			return false;

		//if( typeof position !=== 'number' || !isFinite(position) || Math.floor(position) !=== position || position > subjectString.length )
		//	position = subjectString.length;
		let position = subjectString.length;

		position -= searchString.length;
		let lastIndex = subjectString.indexOf(searchString, position);
		return (lastIndex !== -1 && lastIndex === position);
	}

	function get_js_text(str)
	{
		if( typeof str==='undefined' )
			return '';

		return String(str).replace(/\\/g, '\\\\').replace(/\r/g, '\\r').replace(/\n/g, '\\n').replace(/'/g, '\\\'').replace(/"/g, '\\"'); // &quot; 미사용
	}

	// 참조: https://gist.github.com/xiel/5688446
	// 대체 함수: https://gist.github.com/ricardoriogo/4226921
	function number_format(number, decimals, decPoint, thousandsSep)
	{
		decimals = decimals || 0;
		number = parseFloat(number);

		if(!decPoint || !thousandsSep)
		{
			decPoint = '.';
			thousandsSep = ',';
		}

		let roundedNumber = Math.round( Math.abs( number ) * ('1e' + decimals) ) + '';
		let numbersString = decimals ? roundedNumber.slice(0, decimals * -1) : roundedNumber;
		let decimalsString = decimals ? roundedNumber.slice(decimals * -1) : '';
		let formattedNumber = "";

		while(numbersString.length > 3)
		{
			// formattedNumber += thousandsSep + numbersString.slice(-3);
			formattedNumber = thousandsSep + numbersString.slice(-3) + formattedNumber;
			numbersString = numbersString.slice(0,-3);
		}

		return (number < 0 ? '-' : '') + numbersString + formattedNumber + (decimalsString ? (decPoint + decimalsString) : '');
	}

	// number_format 함수는 소수점 이하 숫자를 지정된 개수만큼만 표시하지만
	// 이 함수는 소수점 이하 숫자를 그대로 유지한다.
	// 예) 12345.6789 -> 12,345.6789
	function float_format(Num)
	{
		Num += '';
		Num = Num.replace(/,/gi, '');
		if( Num==='' )
			return '';

		let x = Num.split('.');
		let x1 = x[0];
		let x2 = x.length > 1 ? '.' + x[1] : '';
		let rgx = /(\d+)(\d{3})/;
		while (rgx.test(x1))
			x1 = x1.replace(rgx, '$1'.concat(',').concat('$2'));
		return x1 + x2;
	}

	function filesize_formatted(filesize,decimals)
	{
		if( filesize < 1024 )
			return number_format(filesize)+'B';

		if( typeof decimals==='undefined' )
			decimals = 1;

		let i = -1;
		let byteUnits = ['KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
		do
		{
			filesize = filesize / 1024;
			i++;
		}
		while (filesize > 1024);
		return number_format(filesize,decimals,'.', ',') + byteUnits[i];
	}

	// 00:42:09 -> 2529
	function time_seconds(time_formatted)
	{
		if( time_formatted==="" )
			return 0;

		let arrTime = time_formatted.split(":");
		for( let i=0 ; i < arrTime.length ; i++)
		{
			if( isNaN(parseInt(arrTime[i],10)) )
				return 0;

			arrTime[i] = parseInt(arrTime[i],10);
		}

		if( arrTime.length===1 )
			return arrTime[0];
		else if( arrTime.length===2 )
			return arrTime[0]*60+arrTime[1];
		else // if( arrTime.length===3 )
			return arrTime[0]*3600+arrTime[1]*60+arrTime[2];
	}

	// 참고: https://gist.github.com/monkeycycle/1398263
	// php의 Str::time_formatted() 함수와는 두 번째 인자 역할이 약간 다르다.
	// hour_format :
	//          23:43     1:23:43   12:23:43
	//   0 :    23:43     1:23:43   12:23:43
	//   1 :  0:23:43     1:23:43   12:23:43
	//   2 : 00:23:43    01:23:43   12:23:43
	function time_formatted(seconds, hour_format)
	{
		if( hour_format!==0 && hour_format!==1 && hour_format!==2 )
			hour_format = 0;

		seconds = parseInt(seconds,10);
		let hr  = Math.floor(seconds / 3600);
		let min = Math.floor((seconds-(hr*3600))/60);
		let sec = Math.floor(seconds - (hr*3600) - (min*60));

		if(min < 10)
			min = "0" + min;
		if(sec < 10)
			sec = "0" + sec;

		if( hour_format===0 )
		{
			if(hr > 0)
				return hr + ':' + min + ':' + sec;
			else
				return min + ':' + sec;
		}
		else if( hour_format===1 )
		{
			return hr + ':' + min + ':' + sec;
		}
		else if( hour_format===2 )
		{
			if(hr===0)
				hr = "00";
			else if(hr  < 10)
				hr = "0" + hr;

			return hr + ':' + min + ':' + sec;
		}
	}

	// 100000 -> 1일 3시간 46분 40초
	function time_formatted_str(seconds, full_string/*true*/)
	{
		seconds = parseInt(seconds,10);
		let day = Math.floor(seconds / 86400);
		let hr  = Math.floor((seconds-(day*86400)) / 3600);
		let min = Math.floor((seconds-(day*86400)-(hr*3600))/60);
		let sec = Math.floor(seconds - (day*86400) - (hr*3600) - (min*60));

		let arrFormattedStr = [];

		if( day > 0 )
			arrFormattedStr.push(day+"일");

		if( hr > 0 )
			arrFormattedStr.push(hr+"시간");

		if(min > 0)
			arrFormattedStr.push(min+"분");

		if( typeof full_string!=="boolean" )
			full_string = true;

		if( full_string )
		{
			if(sec > 0)
				arrFormattedStr.push(sec+"초");
		}

		return arrFormattedStr.join(" ");
	}

	// 111302000 -> 일억일천일백삼십만이천원
	// https://sub0709.blogspot.com/2019/09/javascript.html
	// function num2han(num)
	// {
	// 	num = parseInt((num + '').replace(/[^0-9]/g, ''), 10) + '';  // 숫자/문자/돈 을 숫자만 있는 문자열로 변환
	// 	if(num === '0')
	// 		return '영';

	// 	let number		= ['영', '일', '이', '삼', '사', '오', '육', '칠', '팔', '구'];
	// 	let unit		= ['', '만', '억', '조'];
	// 	let smallUnit	= ['천', '백', '십', ''];
	// 	let result		= [];  //변환된 값을 저장할 배열
	// 	let unitCnt = Math.ceil(num.length / 4);  //단위 갯수. 숫자 10000은 일단위와 만단위 2개이다.

	// 	num = num.padStart(unitCnt * 4, '0')  //4자리 값이 되도록 0을 채운다
	// 	let regexp = /[\w\W]{4}/g;  //4자리 단위로 숫자 분리
	// 	let array = num.match(regexp);

	// 	// 여기로 들어오는 값은 무조건 네자리이다. 1234 -> 일천이백삼십사
	// 	const _makeHan = function(text){
	// 		let str = '';
	// 		for(let i = 0; i < text.length; i++)
	// 		{
	// 			let num = text[i];
	// 			if(num === '0')  //0은 읽지 않는다
	// 				continue;

	// 			str += number[num] + smallUnit[i];
	// 		}
	// 		return str;
	// 	};

	// 	//낮은 자릿수에서 높은 자릿수 순으로 값을 만든다(그래야 자릿수 계산이 편하다)
	// 	for(let i = array.length - 1, unitCnt = 0; i >= 0; i--, unitCnt++)
	// 	{
	// 		let hanValue = _makeHan(array[i]);  //한글로 변환된 숫자
	// 		if(hanValue === '')  //값이 없을땐 해당 단위의 값이 모두 0이란 뜻.
	// 			continue;

	// 		result.unshift(hanValue + unit[unitCnt]);  //unshift는 항상 배열의 앞에 넣는다.
	// 	}

	// 	return result.join('');
	// }

	// https://hsol.tistory.com/895
	// function num2han(num,won)
	// {
	// 	// if( typeof won!===true )
	// 	// 	won = false;

	// 	num = parseInt((num + '').replace(/[^0-9]/g, ''), 10) + '';  // 숫자/문자/돈 을 숫자만 있는 문자열로 변환
	// 	if( num === '' )
	// 		return '';

	// 	if( num === '0' )
	// 		return '영';

	// 	let hanA = new Array("","일","이","삼","사","오","육","칠","팔","구","십");
	// 	let danA = new Array("","십","백","천","","십","백","천","","십","백","천","","십","백","천");
	// 	let result = "";
	// 	for(let i=0; i<num.length; i++)
	// 	{
	// 		let str = "";
	// 		let han = hanA[num.charAt(num.length-(i+1))];
	// 		if(han !== "")
	// 			str += han+danA[i];
	// 		if(i === 4)
	// 			str += "만";
	// 		if(i === 8)
	// 			str += "억";
	// 		if(i === 12)
	// 			str += "조";
	// 		result = str + result;
	// 	}

	// 	if( won )
	// 		result += "원";

	// 	return result ;
	// }

	// 4,232 -> 4,232
	// 4,232,233 -> 423만 2,233
	// 42,323,222 -> 4,232만 3,222
	function num2han(number)
	{
		let inputNumber  = number < 0 ? false : number;
		let unitWords    = ['', '만', '억', '조', '경'];
		let splitUnit    = 10000;
		let splitCount   = unitWords.length;
		let resultArray  = [];
		let resultString = '';

		for (let i = 0; i < splitCount; i++)
		{
			let unitResult = (inputNumber % Math.pow(splitUnit, i + 1)) / Math.pow(splitUnit, i);
			unitResult = Math.floor(unitResult);
			if (unitResult > 0){
				resultArray[i] = unitResult;
			}
		}

		for (let i = 0; i < resultArray.length; i++)
		{
			if(!resultArray[i]) continue;
			let number_val = resultArray[i].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
			resultString = String(number_val) + unitWords[i] + ' ' + resultString;
		}

		return resultString;
	}

	// 0000001234567890 → 0000 0012 3456 7890
	function add_delimiter_to_coupon_num(coupon_num,delimiter)
	{
		return coupon_num.substr(0,4)+delimiter+coupon_num.substr(4,4)+delimiter+coupon_num.substr(8,4)+delimiter+coupon_num.substr(12,4);
	}

	function get_append_arg_for_reload()
	{
		return Math.random();
	}

	// https://gist.github.com/gordonbrander/2230317#gistcomment-1618310
	function generateUUID()
	{
		const chr4 = function(){
			return Math.random().toString(16).slice(-4);
		};

		return chr4() + chr4() +
			'-' + chr4() +
			'-' + chr4() +
			'-' + chr4() +
			'-' + chr4() + chr4() + chr4();
	}


	///////////////////////////////////////////////////////////////////////////////////////////////

	// 노출할 public 메쏘드들
	return {
		// 메시지 언어
		'KOREAN'		: KOREAN,
		'ENGLISH'		: ENGLISH,
		'set_language'	: set_language,
		'get_language'	: get_language,
		// 형식 확인
		'is_digit_x'								: is_digit_x,
		'IsYearMonthFormat'							: IsYearMonthFormat,
		'checkdate'									: checkdate,
		'IsDateFormat'								: IsDateFormat,
		'IsTimeFormat'								: IsTimeFormat,
		'IsFullTimeFormat'							: IsFullTimeFormat,
		'IsShortTimeFormat'							: IsShortTimeFormat,
		'IsDateTimeFormat'							: IsDateTimeFormat,
		'IsFullDateTimeFormat'						: IsFullDateTimeFormat,
		'IsShortDateTimeFormat'						: IsShortDateTimeFormat,
		'is_correct_integer_positive_format'		: is_correct_integer_positive_format,
		'is_correct_integer_zero_positive_format'	: is_correct_integer_zero_positive_format,
		'is_correct_integer_format'					: is_correct_integer_format,
		'is_correct_float_positive_format'			: is_correct_float_positive_format,
		'is_correct_float_zero_positive_format'		: is_correct_float_zero_positive_format,
		'is_correct_float_format'					: is_correct_float_format,
		'is_correct_ascii_format'					: is_correct_ascii_format,
		'is_correct_numeric_format'					: is_correct_numeric_format,
		'is_correct_numeric_dash_format'			: is_correct_numeric_dash_format,
		'is_alpha_numeric_chars'					: is_alpha_numeric_chars,
		'is_correct_alphabet_format'				: is_correct_alphabet_format,
		'is_correct_alpha_numeric_format'			: is_correct_alpha_numeric_format,
		'is_correct_numeric_list_format'			: is_correct_numeric_list_format,
		'is_correct_alpha_numeric_list_format'		: is_correct_alpha_numeric_list_format,
		'is_correct_hangul_format'					: is_correct_hangul_format,
		'is_correct_hangul_alpha_numeric_format'	: is_correct_hangul_alpha_numeric_format,
		'is_correct_uppercase_format'				: is_correct_uppercase_format,
		'is_correct_uppercase_space_format'			: is_correct_uppercase_space_format,
		'is_correct_uppercase_numeric_format'		: is_correct_uppercase_numeric_format,
		'is_lowercase_numeric_chars'				: is_lowercase_numeric_chars,
		'is_correct_lowercase_format'				: is_correct_lowercase_format,
		'is_correct_lowercase_numeric_format'		: is_correct_lowercase_numeric_format,
		'is_correct_email_address_format'			: is_correct_email_address_format,
		'is_correct_name_email_address_format'		: is_correct_name_email_address_format,
		'is_correct_account_general_format'			: is_correct_account_general_format,
		'is_correct_account_login_service_format'	: is_correct_account_login_service_format,
		'is_correct_account_email_or_login_service_format'	: is_correct_account_email_or_login_service_format,
		'is_correct_domain_format'					: is_correct_domain_format,
		'is_correct_ip_address_format'				: is_correct_ip_address_format,
		'is_correct_ip_address_filter_format'		: is_correct_ip_address_filter_format,
		'is_available_file_name'					: is_available_file_name,
		'is_available_strict_file_path'				: is_available_strict_file_path,
		'is_correct_simple_url_format'				: is_correct_simple_url_format,
		'is_correct_standard_url_format'			: is_correct_standard_url_format,
		'is_correct_secure_standard_url_format'		: is_correct_secure_standard_url_format,
		'is_correct_general_url_format'				: is_correct_general_url_format,
		'is_correct_hex_color_format'				: is_correct_hex_color_format,
		'is_correct_color_picker_format'			: is_correct_color_picker_format,
		'is_correct_html_tag_format'				: is_correct_html_tag_format,
		'is_normal_chars'							: is_normal_chars,
		'is_correct_phone_num_format'				: is_correct_phone_num_format,
		'is_correct_global_phone_num_format'		: is_correct_global_phone_num_format,
		'is_correct_telephone_format'				: is_correct_telephone_format,
		'get_split_phone_num'						: get_split_phone_num,
		'is_correct_auth_num_format'				: is_correct_auth_num_format,
		'is_correct_birthday_format_6'				: is_correct_birthday_format_6,
		'is_correct_birthday_format_8'				: is_correct_birthday_format_8,
		'is_correct_app_version_format'				: is_correct_app_version_format,
		'is_correct_social_security_number'			: is_correct_social_security_number,
		'get_split_social_security_number'			: get_split_social_security_number,
		'is_correct_corporate_registration_number'	: is_correct_corporate_registration_number,
		'get_split_corporate_registration_number'	: get_split_corporate_registration_number,
		'is_correct_corporation_number'				: is_correct_corporation_number,
		'is_correct_user_name_format'				: is_correct_user_name_format,
		'is_correct_hangul_alpha_numeric_dash_space_format'	: is_correct_hangul_alpha_numeric_dash_space_format,
		'is_correct_numeric_range_format'			: is_correct_numeric_range_format,
		'is_correct_address_user_input_format'		: is_correct_address_user_input_format,

		'get_split_date'							: get_split_date,
		'is_perfect_hangul_type'					: is_perfect_hangul_type,
		'is_correct_hex_format'						: is_correct_hex_format,

		// 형식 지정
		'startsWith'								: startsWith,
		'endsWith'									: endsWith,
		'get_js_text'								: get_js_text,
		'number_format'								: number_format,
		'float_format'								: float_format,
		'filesize_formatted'						: filesize_formatted,
		'time_seconds'								: time_seconds,
		'time_formatted'							: time_formatted,
		'time_formatted_str'						: time_formatted_str,
		'num2han'									: num2han,
		'add_delimiter_to_coupon_num'				: add_delimiter_to_coupon_num,
		'get_append_arg_for_reload'					: get_append_arg_for_reload,
		'generateUUID'								: generateUUID
	};
})();
