import Container, {AccountContainer} from "../../component/common/Container";
import Content from "../../component/common/Content";
import SrOnly from "../../component/common/SrOnly";
import AccountWrap from "../../component/account/AccountWrap";
import AccountBox from "../../component/account/AccountBox";
import AccountTitle from "../../component/account/AccountTitle";
import AccountDesc from "../../component/account/AccountDesc";
import EnterItem from "../../component/common/EnterItem";
import accountStyles from "../../common/css/Account.module.css";
import commonStyles from "../../common/css/Common.module.css";
import Enter from "../../component/common/Enter";
import CcBtn from "../../component/common/CcBtn";
import AccountBtnWrap from "../../component/account/AccountBtnWrap";
import ResultWrap from "./ResultWrap";
import ResultBox from "./ResultBox";
import {useEffect, useState} from "react";
import {validateEmail, validateName} from "../../Utils/ValidateUtils";
import {FormInputHelper} from "../../common/php_lib/form-input-helper";
import useKeepDoubleClick from "../../common/hook/useKeepDoubleClick";
import {useDialog} from "../../common/hook/DialogContext";
import { useTranslation } from "react-i18next";

const AccountIdFind = () => {
    const [requestData, setRequestData] = useState({
        userName: '',
        email: '',
        verificationCode: ''
    });
    const [userId, setUserId] = useState("");
    const [find, setFind] = useState(false);
    const [clicked, preventDoubleClick] = useKeepDoubleClick();
    const {addAlert, addConfirm} = useDialog();
    const {t} = useTranslation();

    const inputChange = (event) => {
        const { name, value } = event.target;
        setRequestData(prevState => ({
            ...prevState,
            [name]: value
        }));
    }
    const idChange = (id) => {
        setUserId(id);
    };

    const validate = () => {
        if (!validateName(requestData.userName, addAlert, t)) {
            return false;
        }
        if (!validateEmail(requestData.email, addAlert, t)) {
            return false;
        }
        return true;
    }

    const findId = () => {
        fetch("/v1/users/find-id", {
            method: "post",
            headers: {
                "Content-Type": "application/json",
            },
            credentials: "include",
            body: JSON.stringify({
                userBasicInfo: {
                    userName: requestData.userName,
                    email: requestData.email,
                },
                verificationCode: requestData.verificationCode,
            }),
        }).then((response) => {
            clicked.current = false;
            return response.json();
        }).then((json) => {
            if(json.userId === undefined) {
                addAlert(json.message);
            } else {
                idChange(json.userId);
                setFind(true);
            }
        });
    };

    const sendVerificationCode = () => {
        fetch("/v1/users/find-id/verification-code", {
            method: "post",
            headers: {
                "Content-Type": "application/json",
            },
            credentials: "include",
            body: JSON.stringify({
                userName: requestData.userName,
                email: requestData.email,
            }),
        }).then((response) => {
            clicked.current = false;
            if (response.ok) {
                addAlert(t("msg.info.sms"));
            } else if (response.status === 400) {
                response.json().then(json => addAlert(json.message));
            }
        });
    };

    useEffect(() => {
        FormInputHelper.initKeyUpChecker();
        FormInputHelper.initKeyDownChecker();
        FormInputHelper.initCharacterLimiter();
    }, []);

    return (
        <Container>
            <Content>

                <SrOnly tag={"h2"}>아이디 찾기 페이지</SrOnly>

                <AccountWrap>
                    <AccountBox>
                        <AccountTitle>{t("login.findId")}</AccountTitle>
                        <AccountDesc>{t("login.findIdDesc")}</AccountDesc>
                        <div className="enter_wrap">
                            <EnterItem addClass={accountStyles.enter_item}>
                                <Enter
                                    addClass={accountStyles.enter}
                                    id={"user_name"}
                                    type={"text"}
                                    title={t("sign.name")}
                                    placeholder={t("sign.name")}
                                    label={"이름 입력"}
                                    inputChange={inputChange}
                                    name={"userName"}
                                    value={requestData.userName}
                                    maxLength={30}
                                    dataType={"user_name"}
                                />
                            </EnterItem>
                            <EnterItem addClass={accountStyles.enter_item}>
                                <Enter
                                    addClass={accountStyles.enter}
                                    id={"user_email"}
                                    type={"text"}
                                    title={t("sign.email")}
                                    placeholder={t("sign.email")}
                                    label={"이메일 입력"}
                                    inputChange={inputChange}
                                    name={"email"}
                                    value={requestData.email}
                                    maxLength={50}
                                    dataType={"email_address"}
                                />
                            </EnterItem>
                            <EnterItem addClass={accountStyles.enter_item}>
                                <CcBtn
                                    addClass={`${accountStyles.cc_btn} ${commonStyles.bg_gray}`}
                                    title={t("common.sms")}
                                    onClick={() => preventDoubleClick(sendVerificationCode, validate)}
                                >
                                    <span>{t("common.sms")}</span>
                                </CcBtn>
                            </EnterItem>
                            <EnterItem addClass={accountStyles.enter_item}>
                                <Enter
                                    addClass={accountStyles.enter}
                                    id={"certify_num"}
                                    type={"tel"}
                                    title={t("common.smsConfirm")}
                                    placeholder={t("common.smsConfirm")}
                                    label={"인증번호 입력"}
                                    inputChange={inputChange}
                                    name={"verificationCode"}
                                    value={requestData.verificationCode}
                                    maxLength={6}
                                    dataType={"numeric"}
                                />
                            </EnterItem>
                        </div>

                        <AccountBtnWrap>
                            <a
                                href="#"
                                onClick={
                                    (e) => {
                                        e.preventDefault();
                                        preventDoubleClick(findId);
                                    }
                                }
                                className={`${commonStyles.cc_btn} ${accountStyles.cc_btn}`}
                                title={t("common.submit")}
                            >
                                <span>{t("common.submit")}</span>
                            </a>
                        </AccountBtnWrap>

                        {find && (
                            <ResultWrap>
                                <ResultBox>
                                    <p>{t("login.findIdFirst")}<span>{userId}</span>{t("login.findIdLast")}</p>
                                </ResultBox>
                            </ResultWrap>
                        )}
                    </AccountBox>
                </AccountWrap>

            </Content>
        </Container>
    );
};

export default AccountIdFind;