import React, {useEffect, useRef} from "react";
import {useLocation} from "react-router-dom";
import {gsap} from  "gsap";
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import commonStyles from '../../common/css/Common.module.css';

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(ScrollToPlugin);

function ScrollTop() {
    const {pathname} = useLocation();
    const scrollRef = useRef(null);


    useEffect(() => {
        gsap.set(scrollRef.current, {yPercent: 80});

        gsap.to(scrollRef.current, {
            duration: 0.5,
            autoAlpha: 1,
            yPercent: 0,
            ease: 'back.out(1.5)',
            scrollTrigger: {
                trigger: 'body',
                start: 'top -200px',
                end: 'top -200px',
                toggleActions: 'play none reverse none'
            }
        });
    }, [pathname]);

    function moveScrollTop() {
        gsap.to(window, {duration: 0.8, scrollTo: 0, ease: 'back.inOut(1.5)'});
    }

    return (
        <button ref={scrollRef} type="button" id="scroll_top_btn" className={`${commonStyles.scroll_btn} ${commonStyles.scroll_top_btn}`} onClick={moveScrollTop}>
            <span></span><em className={commonStyles.sr_only}>화면 맨위로 이동</em>
        </button>

    );
}

export default ScrollTop;

