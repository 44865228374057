import challengeStyles from "../../../common/css/Challenge.module.css";

const TeamInfo = ({children}) => {
    return (
        <div className={challengeStyles.team_info}>
            {children}
        </div>
    );
};

export default TeamInfo;