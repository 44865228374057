import ChallengeTeamEnterBox from "./ChallengeTeamEnterBox";
import {ApplicationConsent, ApplicationInput, ApplicationSelection} from "./ChallengeTeamCreateApplicationItem";
import challengeStyles from "../../common/css/Challenge.module.css";
import commonStyles from "../../common/css/Common.module.css";
import applicationStyles from "./ChallengeTeamCreateApplicationStyle.module.css";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import EnterItem from "../common/EnterItem";
import RadioItem from "../common/RadioItem";
import useKeepDoubleClick from "../../common/hook/useKeepDoubleClick";
import {useDialog} from "../../common/hook/DialogContext";
import { useTranslation } from "react-i18next";

const ChallengeTeamCreateApplication = ({uid, challengeRefresh, setChallengeRefresh}) => {
    const [items, setItems] = useState([]);
    const [name, setName] = useState('');
    const [intro, setIntro] = useState('');
    const [teamCondition, setTeamCondition] = useState('EVERY');
    const [values, setValues] = useState([]);
    const [clicked, preventDoubleClick] = useKeepDoubleClick();
    const {addAlert, addConfirm} = useDialog();
    const navigate = useNavigate();
    const {t} = useTranslation();
    
    const conditionValues = [
        t("challenge.menu.team.reqAll"), t("challenge.menu.team.reqMd"), t("challenge.menu.team.reqDev"), t("challenge.menu.team.reqmdNdev")
    ];

    const conditionHandle = (e) => {
        setTeamCondition(e.target.value);
        if(e.target.value === teamCondition) {
            e.target.checked = true;
        }
    };

    const getChallengeApplicationItems = () => {
        fetch(`/v1/challenge-team-application/${uid}`)
            .then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    return response.json().then(json => {
                        addAlert(json.message,
                            () => navigate(`/challenge/${uid}/team`)
                        );
                    })
                }
            })
            .then((json) => {
                setItems(json);
            });
    }

    useEffect(() => {
        getChallengeApplicationItems();
    }, []);

    useEffect(() => {
        const initialState = [];
        items.forEach((item, idx) => {
            initialState[idx] = {
                itemUid: item.itemUid,
                value:
                    item.itemType === "INPUT" ?
                        ''
                        :
                        item.itemType === "SELECTION" ?
                            0
                            :
                            false
            };
        });
        setValues(initialState);
    }, [items]);

    const insertData = () => {
        addConfirm(
            t("msg.info.requestTeam"),
            () => {
                fetch("/v1/challenge-team", {
                    headers: {
                        "Content-Type": "application/json",
                    },
                    method: "post",
                    credentials: "include",
                    redirect: "follow",
                    body: JSON.stringify({
                        challengeUid: uid,
                        name: name,
                        intro: intro,
                        teamCondition: teamCondition,
                        saveTeamApplicationItemValueRequests: values,
                    }),
                }).then((response) => {
                    clicked.current = false;
                    if (response.ok) {
                        addAlert(
                            t("msg.info.finishSubmit"),
                            () => navigate(`/challenge/${uid}/team`)
                        );
                        setChallengeRefresh(!challengeRefresh);
                    } else if (response.status === 400 || response.status === 409) {
                        response.json().then(json => {
                            addAlert(json.message);
                        })
                    } else if (response.status === 401) {
                        response.json().then(json => {
                            addAlert(
                                json.message,
                                () => navigate(`/challenge/${uid}/team`)
                            );

                        })
                    }else {
                        throw new Error(t("msg.error.failSubmit"));
                    }
                }).catch(e => {
                    addAlert(e.message);
                });
            },
            () => {
                clicked.current = false;
            }
        )
    };

    const validation = () => {
        if (name.trim() === "") {
            addAlert(t("msg.valid.emptyTeamName"));
            return;
        }

        if (intro.trim() === "") {
            addAlert(t("msg.valid.emptyTeamIntro"));
            return;
        }

        if (teamCondition.trim() === "") {
            addAlert(t("msg.valid.emptyTeamRule"));
            return;
        }

        for (let i = 0; i < values.length; i++) {
            if (typeof values[i].value === "string") {
                if (values[i].value.trim() === "") {
                    addAlert(t("msg.valid.emptyTeamInfo"));
                    return;
                }
            } else if (typeof values[i].value === "boolean") {
                if (!values[i].value) {
                    addAlert(t("msg.valid.emptyTeamAgree"));
                    return;
                }
            } else {
                if (!values[i].value || values[i].value === 0) {
                    addAlert(t("msg.valid.emptyTeamSelect"));
                    return;
                }
            }
        }

        return true;
    };
    const submit = () => {
        preventDoubleClick(insertData, validation);
    };

    return (
        <ChallengeTeamEnterBox>
            <div className={challengeStyles.enter_wrap}>
                <EnterItem addClass={`${challengeStyles.enter_item} ${applicationStyles.enter_item}`}>
                    <label htmlFor="team_name" className={challengeStyles.item_title}>{t("msg.valid.emptyTeamName")}</label>
                    <div className={`${commonStyles.enter} ${challengeStyles.enter}`}>
                        <input
                            id={"team_name"}
                            type={"text"}
                            onChange={(e) => setName(e.target.value)}
                            value={name}
                            maxLength={60}
                        />
                    </div>
                </EnterItem>
                <EnterItem addClass={`${challengeStyles.enter_item} ${applicationStyles.enter_item}`}>
                    <label htmlFor="team_intro" className={challengeStyles.item_title}>{t("msg.valid.emptyTeamIntro")}</label>
                    {/* <div className={`${commonStyles.enter} ${challengeStyles.enter}`}>
                        <input
                            id={"team_intro"}
                            type={"text"}
                            onChange={(e) => setIntro(e.target.value)}
                            value={intro}
                            maxLength={60}
                        />
                    </div> */}
                    <textarea
                        id={"team_intro"}
                        value={intro}
                        onChange={(e) => setIntro(e.target.value)}
                        style={{maxHeight:"150px"}}
                    />
                </EnterItem>
                {/* 241126 대회 관련 주석처리 */}
                {/* <EnterItem addClass={`${challengeStyles.enter_item} ${applicationStyles.enter_item}`}>
                    <div className={challengeStyles.item_title}>{t("msg.valid.emptyTeamRule")}</div>
                    <div className={`${commonStyles.enter} ${challengeStyles.enter}`}>
                        <div className={challengeStyles.option_list}>
                            {conditionValues.map((conditionValue, idx) => (
                                <RadioItem key={`conditionValue${idx}`} addClass={challengeStyles.radio_item}>
                                    <label>
                                        <input
                                            id={`team_option`}
                                            name={`team_option`}
                                            type="radio"
                                            value={conditionValue}
                                            onClick={(e) => conditionHandle(e)}
                                            checked={conditionValue === teamCondition}
                                        />
                                        <span>{conditionValue}</span>
                                    </label>
                                </RadioItem>
                            ))}
                        </div>
                    </div>
                </EnterItem> */}


                {items.map((item, idx) => (
                    <>
                        {item.itemType === "INPUT" && (
                            <ApplicationInput
                                key={item.itemUid}
                                item={item}
                                values={values}
                                setValues={setValues}
                                valueIdx={idx}
                            />
                        )}

                        {item.itemType === "SELECTION" && (
                            <ApplicationSelection
                                key={item.itemUid}
                                item={item}
                                values={values}
                                setValues={setValues}
                                valueIdx={idx}
                            />
                        )}

                        {item.itemType === "CONSENT" && (
                            <ApplicationConsent
                                key={item.itemUid}
                                item={item}
                                values={values}
                                setValues={setValues}
                                valueIdx={idx}
                            />
                        )}
                    </>
                ))}
            </div>

            <div className={challengeStyles.bottom_btn_wrap}>
                <button type="button" className={`${commonStyles.cc_btn} ${challengeStyles.cc_btn} ${commonStyles.bg_white}`} onClick={() => navigate(-1)}>
                    <span>{t("common.pre")}</span>
                </button>
                <button type="button" className={`${commonStyles.cc_btn} ${challengeStyles.cc_btn}`} onClick={submit}>
                    <span>{t("common.request")}</span>
                </button>
            </div>
        </ChallengeTeamEnterBox>
    )
}

export default ChallengeTeamCreateApplication;