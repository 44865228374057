import commonStyles from "../../common/css/Common.module.css";

const Enter = ({
                   addClass = ""
                   , id = ""
                   , type = ""
                   , title = ""
                   , placeholder = ""
                   , children
                   , label = ""
                   , inputChange
                   , name = ""
                   , value = ""
                   , maxLength = 200
                   , dataType = ""
                   , readOnly = false
               }) => {
    return (
        <div className={`${commonStyles.enter} ${addClass}`}>
            <label htmlFor={id} className={commonStyles.sr_only}>{label}</label>
            <input id={id} type={type} title={title} placeholder={placeholder} onChange={inputChange} onBlur={inputChange}
                   value={value} name={name} data_type={dataType} maxLength={maxLength} readOnly={readOnly}/>
            {children}
        </div>
    );
};

export default Enter;
