import ChallengeInner from "./ChallengeInner";
import ChallengeInfo from "./ChallengeInfo";
import ChallengeData from "./ChallengeData";
import challengeStyles from "../../common/css/Challenge.module.css";
import ChallengeTeam from "./ChallengeTeam";
import ChallengeBoard from "./board/ChallengeBoard";
import ChallengeTeamRanking from "./ChallengeTeamRanking";
import ChallengeSubmit from "./ChallengeSubmit";


const ChallengeBottom = ({tabMenu, innerMenu, uid, challenge, teamUid, challengeRefresh, setChallengeRefresh}) => {
    return (
        <div className={challengeStyles.bottom}>
            <ChallengeInner addClass={ (tabMenu === "team" && (innerMenu === "application" || innerMenu === "detail"))
                                       || (tabMenu === "board" && (innerMenu === "create" || innerMenu === "detail" || innerMenu === "update"))
                                        ? challengeStyles.small : ''}>
                {tabMenu === "info" && (
                    <ChallengeInfo
                        tabMenu={tabMenu}
                        innerMenu={innerMenu}
                        challenge={challenge}
                        uid={uid}
                    />
                )}

                {tabMenu === "data" && (
                    <ChallengeData
                        tabMenu={tabMenu}
                        innerMenu={innerMenu}
                        uid={uid}
                    />
                )}

                {tabMenu === "team" && (
                    <ChallengeTeam
                        innerMenu={innerMenu}
                        uid={uid}
                        challenge={challenge}
                        challengeRefresh={challengeRefresh}
                        setChallengeRefresh={setChallengeRefresh}
                    />
                )}

                {tabMenu === "board" && (
                    <ChallengeBoard
                        innerMenu={innerMenu}
                        uid={uid}
                        challenge={challenge}
                    />
                )}

                {tabMenu === "ranking" && (
                    <ChallengeTeamRanking
                        innerMenu={innerMenu}
                        uid={uid}
                        challenge={challenge}
                    />
                )}

                {tabMenu === "submit" && (
                    <ChallengeSubmit
                        innerMenu={innerMenu}
                        uid={uid}
                        challenge={challenge}
                        teamUid={teamUid}
                    />
                )}
            </ChallengeInner>
        </div>
    );
};

export default ChallengeBottom;