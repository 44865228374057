import commonStyles from "../../common/css/Common.module.css";
import challengeStyles from "../../common/css/Challenge.module.css";
import ChallengeInner from "./ChallengeInner";
import ChallengeTopTabs from "./ChallengeTopTabs";
import {Link} from "react-router-dom";
import {useAuth} from "../../common/hook/AuthContext";
import { useTranslation } from "react-i18next";

const ChallengeTop = ({ tabMenu, uid, challenge, teamUid }) => {
    const {isLogin} = useAuth();
    const {t} = useTranslation();

    const scheduleDetailHtml = (scheduleDetail) => {
        return {__html: scheduleDetail};
    }

    return (
        <div className={challengeStyles.top}>
            <ChallengeInner>
                <div className={challengeStyles.inner_top}>
                    <div className={challengeStyles.left}>
                        <div className={`${challengeStyles.status} ${challengeStyles.underway}`}>
                            <span>
                                {
                                    challenge.openState === "OPENED" ?
                                        t("main.ing")
                                        :
                                        challenge.openState === "CLOSED" ?
                                            t("main.finish")
                                            :
                                            t("main.pre")
                                }
                            </span>
                        </div>
                        <div className={challengeStyles.challenge_title}>
                            {challenge.name}
                        </div>
                        <div className={challengeStyles.challenge_desc}>{challenge.type} | {challenge.intro}</div>
                    </div>
                    <div className={challengeStyles.right}>
                        <div className={challengeStyles.challenge_thumb}>
                            <img src={`/v1/image/${challenge.thumbnailUid}`} alt="" />
                        </div>
                    </div>
                </div>
                <div className={challengeStyles.inner_bottom}>
                    <div className={challengeStyles.left}>
                        <div className={challengeStyles.challenge_info}>
                            <div className={challengeStyles.info_item}>
                                <div className={challengeStyles.name}>{t("challenge.main.main")}</div>
                                <div>{challenge.host}</div>
                            </div>
                            <div className={challengeStyles.info_item}>
                                <div className={challengeStyles.name}>{t("challenge.main.reward")}</div>
                                <div>{challenge.reward}</div>
                            </div>
                            <div className={challengeStyles.info_item}>
                                <div className={challengeStyles.name}>{t("challenge.main.schedule")}</div>
                                <div>{challenge.schedule}</div>
                            </div>
                            <div className={challengeStyles.info_item}>
                                <div className={challengeStyles.name}>{t("challenge.main.team")}</div>
                                <div>{challenge.participationTeamCnt}</div>
                            </div>
                            {challenge.scheduleDetail && (
                                <div className={challengeStyles.hidden_detail}>
                                    <div dangerouslySetInnerHTML={scheduleDetailHtml(challenge.scheduleDetail)}>
                                        {/*<ul>*/}
                                        {/*    <li>*/}
                                        {/*        <div className={challengeStyles.date}>04.01</div>*/}
                                        {/*        <div className={challengeStyles.txt}>대회시작</div>*/}
                                        {/*    </li>*/}
                                        {/*</ul>*/}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    {challenge.openState !== "CLOSED" && !challenge.challengeTeamMember && isLogin ?
                        <div className={challengeStyles.right}>
                            <Link
                                to={`/challenge/${uid}/team`}
                                className={`${commonStyles.cc_btn} ${challengeStyles.cc_btn}`}
                            >
                                <span>{t("challenge.main.join")}</span>
                            </Link>
                        </div>
                        :
                        <div className={challengeStyles.right} />
                    }

                </div>

                <ChallengeTopTabs tabMenu={tabMenu} uid={uid} challenge={challenge} teamUid={teamUid} />
            </ChallengeInner>
        </div>
    );
};

export default ChallengeTop;