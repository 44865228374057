import ChallengeDataAgreement from "./ChallengeDataAgreement";
import ChallengeDataExplanation from "./ChallengeDataExplanation";
import ChallengeDataFile from "./ChallengeDataFile";
import ChallengeBottomTabs from "./ChallengeBottomTabs";
import {useEffect, useState} from "react";
import {useDialog} from "../../common/hook/DialogContext";
import {isEmptyObj} from "../../Utils/CommonUtils";
import {useNavigate} from "react-router-dom";

const ChallengeData = ({tabMenu, innerMenu, uid}) => {
    const [challengeData, setChallengeData] = useState({
        agreement: "",
        explanation: "",
        fileDetail: ""
    });
    const {addAlert, addConfirm} = useDialog();
    const navigate = useNavigate();

    const getChallengeDataInfo = () => {
        fetch(`/v1/challenge/${uid}/data`)
            .then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    return response.json().then(json => {
                        addAlert(
                            json.message,
                            () => navigate(`/challenge/${uid}/info/overview`)
                        );
                    })
                }
            })
            .then((json) => {
                if (isEmptyObj(json))
                    return;
                setChallengeData(json);
            }).catch(e => console.log(e.message));
    }

    useEffect(() => {
        getChallengeDataInfo();
    }, []);

    return (
        <div>
            <ChallengeBottomTabs tabMenu={tabMenu} innerMenu={innerMenu} uid={uid} />

            {innerMenu === "agreement" && (
                <ChallengeDataAgreement agreement={challengeData.agreement}/>
            )}

            {innerMenu === "explanation" && (
                <ChallengeDataExplanation explanation={challengeData.explanation} />
            )}

            {innerMenu === "file" && (
                <ChallengeDataFile fileDetail={challengeData.fileDetail} />
            )}
        </div>
    );
};

export default ChallengeData;