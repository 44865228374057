import challengeStyles from "../../common/css/Challenge.module.css";
import applicationStyles from "./ChallengeTeamCreateApplicationStyle.module.css";
import EnterItem from "../common/EnterItem";
import Enter from "../common/Enter";
import commonStyles from "../../common/css/Common.module.css";
import RadioItem from "../common/RadioItem";
import accountStyles from "../../common/css/Account.module.css";
import ConsentItem from "../account/ConsentItem";
import ConsentWrap from "../account/ConsentWrap";
import {useEffect, useState} from "react";
import _ from "lodash";
import { useTranslation } from "react-i18next";

export const ApplicationInput = ({item, values, setValues, valueIdx}) => {
    const {t} = useTranslation();

    const inputChange = (e) => {
        setValues(values.map((value, index) => (
            index === valueIdx ? {...value, value: e.target.value} : value
        )));
    };

    return (
        !_.isEmpty(values) && (
                <EnterItem addClass={`${challengeStyles.enter_item} ${applicationStyles.enter_item}`}>
                    <label htmlFor={`team_item_${item.itemUid}`} className={challengeStyles.item_title}>{item.question}</label>
                    <div className={`${commonStyles.enter} ${challengeStyles.enter}`}>
                        {/* <input
                            id={`team_item_${item.itemUid}`}
                            type={"text"}
                            onChange={(e) => inputChange(e)}
                            value={values[valueIdx].value}
                            maxLength={60}
                        /> */}
                        <textarea
                            id={`team_item_${item.itemUid}`}
                            onChange={(e) => inputChange(e)}
                            value={values[valueIdx].value}
                            style={{maxHeight:"150px"}}
                        />
                    </div>
                </EnterItem>
        )
    );
};

export const ApplicationSelection = ({item, values, setValues, valueIdx}) => {
    const radioHandle = (e) => {
        setValues(values.map((value, index) => (
            index === valueIdx ? {...value, value: e.target.value} : value
        )));
        if(e.target.value == values[valueIdx].value) {
            e.target.checked = true;
        }
    };

    return (
        !_.isEmpty(values) && (
                <EnterItem addClass={`${challengeStyles.enter_item} ${applicationStyles.enter_item}`}>
                    <div className={challengeStyles.item_title}>{item.question}</div>
                    <div className={`${commonStyles.enter} ${challengeStyles.enter}`}>
                        <div className={challengeStyles.option_list}>
                            {item.options.map(option => (
                                <RadioItem key={option.optionUid} addClass={challengeStyles.radio_item}>
                                    <label>
                                        <input
                                            id={`team_option_${item.itemUid}-${option.optionUid}`}
                                            name={`team_option_${item.itemUid}`}
                                            type="radio"
                                            value={option.optionUid}
                                            onClick={(e) => radioHandle(e)}
                                            checked={option.optionUid == values[valueIdx].value}
                                        />
                                        <span>{option.content}</span>
                                    </label>
                                </RadioItem>
                            ))}
                        </div>
                    </div>
                </EnterItem>
        )
    );
};

export const ApplicationConsent = ({item, values, setValues, valueIdx}) => {
    const [checked, setChecked] = useState(false);
    const {t} = useTranslation();

    useEffect(() => {
        setValues(values.map((value, index) => (
            index === valueIdx ? {...value, value: checked} : value
        )));
    }, [checked]);

    return (
        !_.isEmpty(values) && (
            <EnterItem addClass={`${challengeStyles.enter_item} ${applicationStyles.enter_item}`}>
                <ConsentWrap addClass={`${challengeStyles.consent_wrap} ${applicationStyles.consent_wrap}`}>
                    <ConsentItem addClass={`${challengeStyles.consent_item} ${applicationStyles.consent_item}`}>
                        <div className={`${accountStyles.consent_box} ${challengeStyles.consent_box}`} tabIndex="1">
                            {item.question}
                        </div>
                        <EnterItem addClass={challengeStyles.enter_item}>
                            <div className={`${commonStyles.enter} ${challengeStyles.enter}`}>
                                <div className={`${commonStyles.check_item} ${challengeStyles.check_item}`}>
                                    <label>
                                        <input
                                            type="checkbox"
                                            checked={checked}
                                            onChange={({ target: { checked } }) => setChecked(checked)}
                                        />
                                        <span><em className={commonStyles.essential}>[{t("common.need")}]</em> {t("challenge.menu.team.allAgree")}</span>
                                    </label>
                                </div>
                            </div>
                        </EnterItem>
                    </ConsentItem>
                </ConsentWrap>
            </EnterItem>
        )
    );
};