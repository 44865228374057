import challengeStyles from "../../common/css/Challenge.module.css";
import {useNavigate} from "react-router-dom";

const TabItem = ({children, isOn = false, url}) => {
    const navigate = useNavigate();

    return (
        <button type="button" onClick={() => navigate(url)} className={`${challengeStyles.tab_item} ${isOn ? challengeStyles.on : ''}`}>{children}</button>
    );
};

export default TabItem;