import commonStyles from "../../common/css/Common.module.css";
import challengeStyles from "../../common/css/Challenge.module.css";

const MyPageEnter = ({
                         id = ""
                         , type = ""
                         , title = ""
                         , label = ""
                         , inputChange
                         , name = ""
                         , value = ""
                         , readOnly = false
                         , maxLength = 200
                         , dataType=""
                     }) => {
    return (
        <>
            <label htmlFor={id} className={`${commonStyles.item_title} ${challengeStyles.item_title}`}>{label}</label>
            <div className={`${commonStyles.enter} ${challengeStyles.enter}`}>
                <input id={id} type={type} title={title} onChange={inputChange} onBlur={inputChange}
                       value={value} name={name} data_type={dataType} maxLength={maxLength} readOnly={readOnly} />
            </div>
        </>
    );
};

export default MyPageEnter;