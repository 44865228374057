import BbsWrap from "./BbsWrap";
import {Link} from "react-router-dom";
import challengeStyles from "../../common/css/Challenge.module.css";
import {useEffect, useState} from "react";
import commonStyles from "../../common/css/Common.module.css";
import TeamBoxList from "./TeamBoxList";
import TeamBox from "./teamBox/TeamBox";
import Paging from "../common/Paging";
import TeamName from "./teamBox/TeamName";
import TeamInfo from "./teamBox/TeamInfo";
import {ItemIntro, ItemMember} from "./teamBox/InfoItem";
import TeamOption from "./teamBox/TeamOption";
import {useAuth} from "../../common/hook/AuthContext";
import {useDialog} from "../../common/hook/DialogContext";
import { useTranslation } from "react-i18next";

const ChallengeTeamList = ({uid, isCreate, openState}) => {
    const { isLogin } = useAuth();
    const [teams, setTeams] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPage, setTotalPage] = useState(1);
    const {addAlert, addConfirm} = useDialog();
    const {t} = useTranslation();

    const getTeams = () => {
        fetch(`/v1/challenge-team/challenge/${uid}?page=${currentPage - 1}&size=5`)
            .then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    return response.json().then(json => {
                        addAlert(json.message);
                    })
                }
            })
            .then((json) => {
                if (json === undefined) return;
                setTeams(json.data);
                setTotalPage(json.totalPages);
            });
    }

    useEffect(() => {
        getTeams();
    },[currentPage]);

    return (
        <>
            <BbsWrap>
                <div className={`${challengeStyles.top} ${challengeStyles.flex}`}>
                    <div className={challengeStyles.table_title}>{t("challenge.menu.team.team")}</div>
                    {isLogin ?
                        isCreate && openState !== "CLOSED" ?
                            <Link
                                to={`/challenge/${uid}/team/create-agreement`}
                                className={`${commonStyles.cc_btn} ${challengeStyles.cc_btn} ${commonStyles.bg_white_blue}`}
                            >
                                <span>{t("challenge.menu.team.create")}</span>
                            </Link>
                            :
                            <a
                                href={`#`}
                                onClick={
                                    (e) => {
                                        e.preventDefault();
                                        addAlert(t("msg.valid.limitTeamCreate"))
                                    }
                                }
                                className={`${commonStyles.cc_btn} ${challengeStyles.cc_btn} ${commonStyles.bg_white_blue}`}
                            >
                                <span>{t("challenge.menu.team.create")}</span>
                            </a>
                        :
                        <a
                            href={`#`}
                            onClick={
                                (e) => {
                                    e.preventDefault();
                                    addAlert(t("msg.error.login"))
                                }
                            }
                            className={`${commonStyles.cc_btn} ${challengeStyles.cc_btn} ${commonStyles.bg_white_blue}`}
                        >
                            <span>{t("challenge.menu.team.create")}</span>
                        </a>
                    }
                </div>
                <TeamBoxList>
                    {teams.map(team => (
                        <TeamBox key={team.uid}>
                            <TeamName><Link to={`/challenge/${uid}/team/${team.uid}`}>{team.name}</Link></TeamName>
                            <TeamInfo>
                                <ItemMember>
                                    [{t("challenge.menu.team.head")}] {team.leaderName}{team.memberNames.map(memberName => (`, ${memberName}`))}
                                </ItemMember>
                                <ItemIntro>
                                    <div>{team.intro}</div>
                                </ItemIntro>
                            </TeamInfo>
                            <TeamOption className={challengeStyles.team_option}>
                                {/* <span>{t("sign.docY")} {team.clinicianCnt}{t("common.memberCnt")}</span> */}
                                <span>{`${t("challenge.menu.team.need")} : ${team.clinicianCnt}${t("common.memberCnt")}`}</span>
                                {/* <span>{team.teamCondition}</span> */}
                            </TeamOption>
                        </TeamBox>
                    ))}
                </TeamBoxList>
            </BbsWrap>
            <Paging
                blockSize={10}
                totalPageCount={totalPage}
                currentPageCount={currentPage}
                onPageClick={setCurrentPage}
            />
        </>
    )
};

export default ChallengeTeamList;