import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationKo from "./ko/translation.json"
import translationEn from "./en/translation.json"


const resources = {
    KO: {translation: translationKo},
    EN: {translation: translationEn},
};

i18n.use(initReactI18next)//.use(LanguageDetector)
.init({
    resources,
    // lng: "KO",
    lng: "EN",
    fallbackLng: "EN",
    debug: false,
    interpolation: {
        escapeBalue: false,
    },
});

export default i18n;