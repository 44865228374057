import ChallengeBottomTabs from "./ChallengeBottomTabs";
import challengeStyles from "../../common/css/Challenge.module.css";
import _ from "lodash";
import BbsWrap from "./BbsWrap";
import {useEffect, useState} from "react";
import ChallengeTeamRankingContent from "./ChallengeTeamRankingContent";
import Paging from "../common/Paging";
import {useAuth} from "../../common/hook/AuthContext";
import ChallengeChart from "./ChallengeChart";
import { useTranslation } from "react-i18next";

const ChallengeTeamRanking = ({innerMenu, uid, challenge}) => {
    const { isLogin } = useAuth();
    const [teamRanking, setTeamRanking] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPage, setTotalPage] = useState(1);
    const {t} = useTranslation();

    const getPublicRanking = () => {
        fetch(`/v1/challenge/${uid}/public-ranking?page=${currentPage - 1}&size=10`)
            .then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    return response.json().then(json => {
                        alert(json.message);
                    })
                }
            })
            .then((json) => {
                if (json === undefined) return;
                setTeamRanking(json.ranking);
                setTotalPage(json.totalPage);
            });
    }

    const getFinalRanking = () => {
        // fetch(`/v1/challenge-team/challenge/${innerMenu}-ranking/${uid}?page=${currentPage - 1}&size=10`)
        //     .then((response) => {
        //         if (response.ok) {
        //             return response.json();
        //         } else {
        //             return response.json().then(json => {
        //                 alert(json.message);
        //             })
        //         }
        //     })
        //     .then((json) => {
        //         if (json === undefined) return;
        //         setTeamRanking(json.data);
        //         setTotalPage(json.totalPages);
        //     });
        setTeamRanking([]);
        setTotalPage(0);
    }

    useEffect(() => {
        if (innerMenu === "public") {
            getPublicRanking();
        } else if (innerMenu === "final") {
            getFinalRanking();
        }
    },[currentPage, innerMenu]);

    return(
        <>
            <ChallengeBottomTabs
                tabMenu={"ranking"}
                innerMenu={innerMenu}
                uid={uid}
            />

            <BbsWrap>
                {
                    innerMenu !== "chart" ?
                        <div className={`${challengeStyles.bbs_table} ${challengeStyles.type_list}`}>
                            <table>
                                <caption><strong>{innerMenu === "public" ? "공개" : "최종"}순위 테이블 입니다.</strong>NO, 팀, 구성원, 점수, 제출수, 최종 제출일이 명시되어 있습니다.</caption>
                                <colgroup>
                                    <col style={{ width: "80px" }} />
                                    <col style={{ width: "25%" }} />
                                    <col />
                                    <col style={{ width: "80px"}} />
                                    <col style={{ width: "120px" }} />
                                    <col style={{ width: "120px" }} />
                                </colgroup>
                                <thead>
                                <tr>
                                    <th scope="col">
                                        <div>NO</div>
                                    </th>
                                    <th scope="col" className="ta_left">
                                        <div>{t("challenge.menu.chart.team")}</div>
                                    </th>
                                    <th scope="col" className="ta_left">
                                        <div>{t("challenge.menu.chart.people")}</div>
                                    </th>
                                    <th scope="col">
                                        <div>{t("challenge.menu.chart.score")}</div>
                                    </th>
                                    <th scope="col">
                                        <div>{t("challenge.menu.chart.setCount")}</div>
                                    </th>
                                    <th scope="col">
                                        <div>{t("challenge.menu.chart.setDate")}</div>
                                    </th>
                                </tr>
                                </thead>
                                <tbody>

                                {
                                    !_.isEmpty(teamRanking) ?
                                        teamRanking.map(rankInfo => (
                                            <ChallengeTeamRankingContent
                                                key={rankInfo.uid}
                                                rankInfo={rankInfo}
                                            />
                                        ))
                                        :
                                        <tr>
                                            <td colSpan="6" className="no_con">
                                                <div>{t("challenge.menu.chart.noChart")}</div>
                                            </td>
                                        </tr>
                                }
                                </tbody>
                            </table>
                            <Paging
                                blockSize={10}
                                totalPageCount={totalPage}
                                currentPageCount={currentPage}
                                onPageClick={setCurrentPage}
                            />
                        </div>
                        :
                        <ChallengeChart
                            uid={uid}
                            innerMenu={innerMenu}
                        />
                }

            </BbsWrap>

        </>

    )
}

export default ChallengeTeamRanking;