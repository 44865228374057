
// 변수의 데이터 타입 확인
export const getDataType = (data) => {
    return Object.prototype.toString.call(data).slice(8,-1);
}

/**
 * 대상이 빈 값인지 체크하여 결과값을 리턴한다. 
 * @param obj		: 체크 대상 변수
 */
export const isEmptyObj = (obj) => {
    const dataType = getDataType(obj);
    if(dataType === "Undefined" || dataType === "Null") {
        return true;
    } else if(dataType === "String") {
        if(obj === null || obj.trim().length < 1)
            return true;
    } else if(dataType === "Number") {
        if(obj === null || obj === 0)
            return true;
    } else if(dataType === "Object") {
        if(obj === null || Object.keys(obj).length < 1)
            return true;
    } else if (dataType === "Array") {
        if(obj === null || obj.length < 1)
            return true;
    }
    return false ;
}

/**
 * 문자열이 빈 문자열인지 체크하여 기본 문자열로 리턴한다. 
 * @param str			: 체크할 문자열
 * @param defaultStr	: 문자열이 비어있을경우 리턴할 기본 문자열
 */	
export const nvl = (str, defaultStr) => {
    
    if(getDataType(str) === "Undefined" || str === null || str === "")
        str = defaultStr ;
    
    return str ;
}

