import React from "react";
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import "./common/css/style.css"
import Url from "./common/Url";
import Wrapper from "./component/common/Wrapper";
import Main from "./routes/Main";
import SignUp from "./routes/account/SignUp";
import Login from "./routes/account/Login";
import Challenge from "./routes/challenge/Challenge";
import AccountIdFind from "./routes/account/AccountIdFind";
import AccountPasswordFind from "./routes/account/AccountPasswordFind";
import AccountPasswordReset from "./routes/account/AccountPasswordReset";
import MyPageRoute from "./routes/mypage/MyPageRoute";
import {DialogProvider} from "./common/hook/DialogContext";
import {AuthProvider} from "./common/hook/AuthContext";


function App() {
  return (
      <Router>
      <DialogProvider>
      <AuthProvider>
          <Wrapper>
              <Routes>
                  <Route path={Url.home} element={<Main />} /> {/* : 메인 페이지 */}
                  <Route path={Url.signUp} element={<SignUp />} /> {/* : 회원가입 */}
                  <Route path={Url.login} element={<Login />} /> {/* : 로그인 */}
                  <Route path={Url.findId} element={<AccountIdFind />} /> {/*  : 아이디 찾기 */}
                  <Route path={Url.passwordFind} element={<AccountPasswordFind />} /> {/*  : 비밀번호 찾기 */}
                  <Route path={Url.passwordReset} element={<AccountPasswordReset />} /> {/*  : 비밀번호 재설정 */}

                  <Route path={Url.challengeInfoOverview} element={<Challenge tabMenu={"info"} innerMenu={"overview"} />} /> {/* 대회 : 대회 안내 - 개요 */}
                  <Route path={Url.challengeInfoRule} element={<Challenge tabMenu={"info"} innerMenu={"rule"} />} /> {/* 대회 : 대회 안내 - 평가 구칙 */}
                  <Route path={Url.challengeInfoSchedule} element={<Challenge tabMenu={"info"} innerMenu={"schedule"} />} /> {/* 대회 : 대회 안내 - 일정 상세 */}
                  <Route path={Url.challengeInfoReward} element={<Challenge tabMenu={"info"} innerMenu={"reward"} />} /> {/* 대회 : 대회 안내 - 상금 */}
                  <Route path={Url.challengeDataAgreement} element={<Challenge tabMenu={"data"} innerMenu={"agreement"} />} /> {/* 대회 : 데이터 - 이용 동의서 */}
                  <Route path={Url.challengeDataExplanation} element={<Challenge tabMenu={"data"} innerMenu={"explanation"} />} /> {/* 대회 : 데이터 - 데이터 설명 */}
                  <Route path={Url.challengeDataFile} element={<Challenge tabMenu={"data"} innerMenu={"file"} />} /> {/* 대회 : 데이터 - 파일 */}
                  <Route path={Url.challengeTeamList} element={<Challenge tabMenu={"team"} innerMenu={"list"} />} /> {/* 대회 : 팀 - 목록 */}
                  <Route path={Url.challengeTeamCreateAgreement} element={<Challenge tabMenu={"team"} innerMenu={"createAgreement"} />} /> {/* 대회 : 팀 - 만들기 - 동의 */}
                  <Route path={Url.challengeTeamCreateApplication} element={<Challenge tabMenu={"team"} innerMenu={"createApplication"} />} /> {/* 대회 : 팀 - 만들기 - 신청 */}
                  <Route path={Url.challengeTeamDetail} element={<Challenge tabMenu={"team"} innerMenu={"detail"} />} /> {/* 대회 : 팀 - 상세 */}
                  <Route path={Url.challengePublicRanking} element={<Challenge tabMenu={"ranking"} innerMenu={"public"} />} /> {/* 대회 : 순위 - 공개 순위 */}
                  <Route path={Url.challengeFinalRanking} element={<Challenge tabMenu={"ranking"} innerMenu={"final"} />} /> {/* 대회 : 순위 - 최종 순위 */}
                  <Route path={Url.challengeRankingChart} element={<Challenge tabMenu={"ranking"} innerMenu={"chart"} />} /> {/* 대회 : 순위 - 최종 순위 */}
                  <Route path={Url.challengeBoardList} element={<Challenge tabMenu={"board"} innerMenu={"list"} />} /> {/* 대회 : 게시판 */}
                  <Route path={Url.challengeBoardDetail} element={<Challenge tabMenu={"board"} innerMenu={"detail"} />} /> {/* 대회 : 게시판 - 상세 */}
                  <Route path={Url.challengeBoardCreate} element={<Challenge tabMenu={"board"} innerMenu={"create"} />} /> {/* 대회 : 게시판 - 생성 */}
                  <Route path={Url.challengeBoardUpdate} element={<Challenge tabMenu={"board"} innerMenu={"update"} />} /> {/* 대회 : 게시판 - 수정 */}
                  <Route path={Url.challengeSubmit} element={<Challenge tabMenu={"submit"} innerMenu={""} />} /> {/* 대회 : 제출 */}

                  <Route path={Url.myPageInfo} element={<MyPageRoute tabMenu={"info"} />} /> {/* 마이페이지 : 내 정보 */}
                  <Route path={Url.myPageChallenge} element={<MyPageRoute tabMenu={"challenge"} />} /> {/* 마이페이지 : 참여 대회 */}
                  <Route path={Url.myPageBoard} element={<MyPageRoute tabMenu={"board"} />} /> {/* 마이페이지 : 내 게시물 */}

              </Routes>
          </Wrapper>
      </AuthProvider>
      </DialogProvider>
      </Router>
  );
}

export default App;

export function isEmpty(list) {
  return list === undefined || list.length === 0 || list === {};
}
