import challengeStyles from "../../common/css/Challenge.module.css";
import TabItem from "./TabItem";
import Url from "../../common/Url";
import { useTranslation } from "react-i18next";

const ChallengeBottomTabs = ({tabMenu, innerMenu, uid}) => {
    const baseUrl = `/challenge/${uid}`;
    const {t} = useTranslation();

    return (
        <>
            {tabMenu === "info" && (
                <div className={challengeStyles.bottom_tabs}>
                    <TabItem isOn={innerMenu === "overview"} url={baseUrl + `/info/overview`}><span>{t("challenge.menu.info.desc")}</span></TabItem>
                    <TabItem isOn={innerMenu === "rule"} url={baseUrl + `/info/rule`}><span>{t("challenge.menu.info.rule")}</span></TabItem>
                    <TabItem isOn={innerMenu === "schedule"} url={baseUrl + `/info/schedule`}><span>{t("challenge.menu.info.schedule")}</span></TabItem>
                    <TabItem isOn={innerMenu === "reward"} url={baseUrl + `/info/reward`}><span>{t("challenge.menu.info.reward")}</span></TabItem>
                </div>
            )}

            {tabMenu === "data" && (
                <div className={challengeStyles.bottom_tabs}>
                    <TabItem isOn={innerMenu === "agreement"} url={baseUrl + `/data/agreement`}><span>{t("challenge.menu.data.agree")}</span></TabItem>
                    <TabItem isOn={innerMenu === "explanation"} url={baseUrl + `/data/explanation`}><span>{t("challenge.menu.data.desc")}</span></TabItem>
                    <TabItem isOn={innerMenu === "file"} url={baseUrl + `/data/file`}><span>{t("challenge.menu.data.file")}</span></TabItem>
                </div>
            )}

            {tabMenu === "ranking" && (
                <div className={challengeStyles.bottom_tabs}>
                    <TabItem isOn={innerMenu === "public"} url={baseUrl + `/public-ranking`}><span>{t("challenge.menu.chart.public")}</span></TabItem>
                    <TabItem isOn={innerMenu === "final"} url={baseUrl + `/final-ranking`}><span>{t("challenge.menu.chart.final")}</span></TabItem>
                    <TabItem isOn={innerMenu === "chart"} url={baseUrl + `/chart`}><span>TOP20 CHART</span></TabItem>
                </div>
            )}
        </>
    );
};

export default ChallengeBottomTabs;