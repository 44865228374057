const Url = {
    home: "/",
    login: "/login",
    signUp: "/account/signup",
    findId: "/account/find-id",
    passwordReset: "/account/password-reset",
    passwordFind: "/account/password-find",
    challengeInfoOverview: "/challenge/:challengeUid/info/overview",
    challengeInfoRule: "/challenge/:challengeUid/info/rule",
    challengeInfoSchedule: "/challenge/:challengeUid/info/schedule",
    challengeInfoReward: "/challenge/:challengeUid/info/reward",
    challengeDataAgreement: "/challenge/:challengeUid/data/agreement",
    challengeDataExplanation: "/challenge/:challengeUid/data/explanation",
    challengeDataFile: "/challenge/:challengeUid/data/file",
    challengeTeamList: "/challenge/:challengeUid/team",
    challengeTeamCreateAgreement: "/challenge/:challengeUid/team/create-agreement",
    challengeTeamCreateApplication: "/challenge/:challengeUid/team/create-Application",
    challengeTeamDetail: "/challenge/:challengeUid/team/:teamUid",
    challengePublicRanking: "/challenge/:challengeUid/public-ranking",
    challengeFinalRanking: "/challenge/:challengeUid/final-ranking",
    challengeRankingChart: "/challenge/:challengeUid/chart",
    challengeBoardList: "/challenge/:challengeUid/board",
    challengeBoardCreate: "/challenge/:challengeUid/board/create",
    challengeBoardDetail: "/challenge/:challengeUid/board/:boardUid",
    challengeBoardUpdate: "/challenge/:challengeUid/board/:boardUid/update",
    challengeSubmit: "/challenge/:challengeUid/submit",
    myPageInfo: "/mypage/info",
    myPageChallenge: "/mypage/challenge",
    myPageBoard: "/mypage/board",
}

export default Url;
